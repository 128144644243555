import { useEffect, useState } from "react"
import CustomTable from "../../widgets/table";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { deleteAnalysisConfig, getAnalysisList } from "./apiController";
import { Col, Row } from "reactstrap";
import { Pagination } from 'antd';
import ConfigureAnalysis from "./ConfigureAnalysis";
import { BarChartOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AnalysisReport from "./AnalysisReport";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import ModalComponent from "../common/Modal/modal";
import AskQuestions from "../dataCollection/AskQuestions";
import { htmlToJSON, TableData } from "../Editor/utils/ContentType";
import SectionModal from "../Editor/utils/SectionModal";
import PushToEditor from "../Editor/utils/PushToEditor";

export default function DataAnalysisTable(props) {
    const [tableData, SettableData] = useState<any>([]);
    const [popupType, SetPopupType] = useState<any>("");
    const [pageSize, SetPageSize] = useState(10);
    const [pageNum, SetPageNum] = useState(1);
    const [totalCount, SetCount] = useState(0);
    const [selectedItem, SetSelectedItem] = useState<any>({});
    const [actionPush, setactionPush] = useState('');
    const dispatch = useDispatch();
    const { data } = props;
    const navigate = useNavigate();

    useEffect(() => {
        getAnalysis(pageNum);
    }, [])


    /**
 * function call to Push code to editor
 */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    /**
     * function to get the data from HTML in JSON
     * @param data 
     */
    const getTableJson = (data) => {
        const table = document.querySelector('.custom-table');
        let response = htmlToJSON(table,'.custom-table')
        response.then(tableData => {
            console.log("*************",tableData)

            let pushResponse;
            tableData.map(item => {
                console.log(item)
                pushResponse = PushToEditor(item, data.section.value)
            })            
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }
        })
        setactionPush("")
    };

    /**
     * get analysis list
     */
    const getAnalysis = async (pagenum) => {
        try {
            dispatch(setLoading(true));
            let analysisListResponse = await getAnalysisList(props?.group.id, pageSize, pagenum);
            SettableData(analysisListResponse.data)
            TableData(analysisListResponse.data)
            if (!totalCount) {
                SetCount(analysisListResponse?.total_count);
            }
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
        }
    }

    /**
  * columns
  */
    const columns = [
        {
            id: "name",
            label: "Analysis Name",
            accessor: "name",
            sort: "asc",
        },
        {
            id: "created_on",
            label: "Created On",
            accessor: "created_on",
            sort: "asc",
        },
        {
            id: "type",
            label: "Analysis Type",
            accessor: "type",
            sort: "asc",
        },
        {
            id: "updated_on",
            label: "Updated On",
            accessor: "updated_on",
            sort: "asc",
        },
        {
            id: "view",
            label: "View",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <i
                        className="icon icon-eye"
                        onClick={() => onAction(2, original)}
                    ></i>
                );
            },
        },
        {
            id: "analysis",
            label: "Analysis",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <BarChartOutlined onClick={() => onAction(3, original)} />
                );
            },
        },
        {
            id: "delete",
            label: "Delete",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <i className="icon icon-delete" style={{ fontSize: "1.5em" }} onClick={() => onAction(5, original)}></i>
                );
            },
        }
    ];

    /**
     * actions to table buttons
     * @param type 
     * @param data 
     */
    const onAction = async (type, data) => {
        switch (type) {
            case 2:
                console.log(data)
                SetSelectedItem(data)
                SetPopupType("configure");
                break;
            case 3:
                console.log(data)
                SetSelectedItem(data)
                SetPopupType('analysis');
                break;
            case 4:
                SetPopupType("configure");
                break;
            case 5:
                SetPopupType("delete");
                SetSelectedItem(data)
                console.log(data)
                break;
            case 17:
                setactionPush("section")
                break;
            default:
                SetPopupType("")
        }
    }

    function onShowSizeChange(e) {
        console.log(e);
        getAnalysis(e);
    }

    const handleSizeChange = (current, pagesize) => {
        console.log(current, pagesize);
        SetPageSize(pagesize);
        //getAnalysis();
    };

    const onClose = () => {
        SetPopupType("");
        SetSelectedItem([]);
        getAnalysis(pageNum);
    }

    /**
     * delete analysis
     */
    const deleteAnalysis = async () => {
        try {
            let deleteResponse = await deleteAnalysisConfig(selectedItem.id);
            if (deleteResponse) {
                showToaster(toasterTypes.SUCCESS, "Record deleted")
                SetPopupType("")
                getAnalysis(pageNum);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleButtonClick = () => {
        alert("Hell")
        const table = document.querySelector('.custom-table');
        console.log(table)
        htmlToJSON(table)
        if (table) {
            console.log(table.innerHTML); // Get the inner HTML of the table
            // You can also process the table rows and cells here
        }
    };


    return (
        <>
            {
                popupType == "delete" &&
                <ModalComponent
                    onClose={() => SetPopupType("")}
                    isOpen={true}
                    header={<b>Delete {selectedItem.type}</b>}
                    centered={true}
                    customClass="criteria-modal"
                    body={
                        <>
                            <span>Are you sure you want to delete {selectedItem.name}?</span>
                        </>
                    }
                    footer={
                        <>
                            <CustomButton type="primary" text="Confirm" onClick={() => deleteAnalysis()} />
                        </>
                    }
                />
            }

            {popupType == "configure" ?
                <ConfigureAnalysis data={data} selectedItem={selectedItem} {...props} onClose={() => onClose()} />
                :
                popupType == "analysis" ?
                    <AnalysisReport data={data} selectedItem={selectedItem} {...props} onClose={() => onClose()} />
                    :
                    <div className="analysis-table-container">

                        <div className="header-container">
                            <Row className='m-0'>
                                <Col className='pl-3 mt-3'>
                                    <div className='form-name'>
                                        <img className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                        Data Analysis
                                    </div>
                                </Col>
                                <Col className='pr-4 mt-4 text-right'>
                                    <i className='icon icon-close' onClick={() => props.onClose()} />
                                </Col>
                            </Row>
                        </div>
                        <div className='p-4'>
                            {
                                actionPush == "section" &&
                                <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                            }
                            <CustomTable
                                header="Records"
                                data={tableData && tableData || []}
                                tableProps={columns}
                                isPushToDoc={true}
                                isSearch={false}
                                isFilter={false}
                                isView={false}
                                isMerge={false}
                                isCreate={true}
                                pagination={true}
                                onAction={(type, data = null) => onAction(type, data)}
                            ></CustomTable>
                        </div>
                        <div className='viewdata-pagination'><Pagination onChange={(e) => onShowSizeChange(e)} total={totalCount} onShowSizeChange={handleSizeChange} /></div>

                    </div>
            }

        </>
    )
}