import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { HuePicker } from 'react-color';
import { uploadChartAsImage } from '../../Editor/utils/UploadToServer';
import { downloadChartAsImage } from './downloadChartAsImage';
import SectionModal from '../../Editor/utils/SectionModal';


const StackedBarChart = ({ containerid,barstyle,data,categories,bardatastyle,title,titlecolor,width,height,yaxislabelstyle,xaxislabelstyle,gridcolor,gridstatus,yaxisstyle,xaxisstyle,xaxistext,yaxistext }) => {
  const chartRef = useRef(null);
  const [cat,SetCat] = useState(categories); 
  const [showGridLines, setShowGridLines] = useState(true);
  const [ylabel,setylabel] = useState(yaxistext)
  const [xlabel,setxlabel] = useState(xaxistext)
  const [color, Setcolor] = useState("#000000")
  const [colorLabel, SetcolorLabel] = useState("black")
	const [hideColor, SethideColor] = useState(false)
  const [FontWeight, SetFontWeight] = useState(false)
  const [fontSize, SetFontSize] = useState('16');
  const [actionPush, setactionPush] = useState('');

  /**
  * function call to Push code to editor
  */
  const addToEditor = (data) => {
      console.log(data)
      uploadChartAsImage(data,containerid)
      setactionPush("")
  }
  const upadteCat = (value) =>{
    let data = cat.filter(item => item == value);
    if(data.length != 0 ){
        SetCat(cat.filter(item => item != value))
    }else{
        SetCat([...cat,value])
    }
  }

  useEffect(()=>{
    console.log(data)
    SetCat(categories.reverse())
  },[1])

  useEffect(() => {
    // Set up dimensions and margins
    const margin = { top: 20, right: 30, bottom: 30, left: 40 };
    //const width = 600 - margin.left - margin.right;
   // const height = 400 - margin.top - margin.bottom;

    // Create SVG container
    const svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left*3},${margin.left*2})`);

    // Define colors for each category
    const color = d3.scaleOrdinal().range(['#98abc5', '#8a89a6', '#7b6888']);
    // Stack the data
    const stack = d3.stack().keys(cat || categories);

    const stackedData = stack(data);

    // Set up scales
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.label))
      .range([0, width])
      .padding(0.1);

    const y = d3.scaleLinear().domain([0, d3.max(stackedData, (d) => d3.max(d, (layer) => layer[1]))]).range([height, 0]);
    
      // Draw gridlines
          // svg
          // .append('g')
          // .attr('class', 'grid')
          // .style('color',gridcolor)
          // .attr('transform', `translate(50,${height})`)
          // .style("stroke-dasharray", "5 5")
          // .style("visibility",gridstatus)
          // .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''));

          svg
            .append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(0,0)`)
            .style("stroke-dasharray", "5 5")
            .style("color",gridcolor)
            .style("z-index",-1)
            .style("visibility",showGridLines ? "visible" : "hidden")
            .call(d3.axisLeft(y).tickSize(-width).tickFormat(''));



    // Draw the bars
    const bars = svg
    .selectAll('.bar-group')
    .data(stackedData)
    .enter()
    .append('g')
    .attr('class', 'bar-group')
    .attr('fill', (d) => color(d.key));

    bars
    .selectAll('rect')
    .data((d) => d)
    .enter()
    .append('rect')
    .attr('x', (d) => x(d.data.label))
    .attr('y', (d) => y(d[1]))
    .attr('height', (d) => y(d[0]) - y(d[1]))
    .attr('width', x.bandwidth())
    .on("mouseenter",function(d){
        d3.select(d.currentTarget)
          .transition()
          .duration(100)
          .attr('opacity', 0.6);

       // Show tooltip on mouseover
       let values="";
       for(var val in d.target.__data__.data){
        if(val != "label"){
            values += `<span style="padding:18px">`+`${val}: ${d.target.__data__.data[val]}`+`</span><br>`
        }
       }
       tooltip.transition().duration(300).style('opacity', 0.9);
        tooltip.html(values)
         .style('left', `${d.pageX}px`)
         .style('top', `${d.pageY - 28}px`);
           
        })
        .on("mouseleave",function(d){
          d3.select(d.currentTarget)
            .transition()
            .duration(200)
            .attr('opacity',1)

             //remove tooltip
             tooltip.transition().duration(500).style('opacity', 0);

          });


         // Create tooltip
         const tooltip = d3
         .select(chartRef.current)
         .append('div')
         .style('position', 'absolute')
         .style('background', '#f4f4f4')
         .style('padding', '5px')
         .style('border', '1px solid #ccc')
         .style('border-radius', '5px')
         .style('color',"red")
         .style('opacity', 0);


    // Add axes
    svg.append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x))
    .style('font-weight',xaxislabelstyle.fontweight)
    .style('font-size',xaxislabelstyle.fontsize);

    svg.append('g')
    .call(d3.axisLeft(y))
    .style('font-weight',yaxislabelstyle.fontweight)
    .style('font-size',yaxislabelstyle.fontsize);

  // Add labels on top of each bar
  bars
      .selectAll('.bar-label')
      .data((d) => d)
      .enter()
      .append('text')
      .attr('class', 'bar-label')
      .attr('x', (d) => x(d.data.label) + x.bandwidth() / 2)
      .attr('y', (d) => y(d[0] + (d[1] - d[0]) / 2))
      .attr('dy', '0.35em') // Adjust vertical alignment
      .attr('text-anchor', 'middle')
      .style('fill',bardatastyle.color)
      .style('font-weight',bardatastyle.fontweight)
      .style('font-size',bardatastyle.fontsize)
      .style('visibility',bardatastyle.visible)
      .text((d) => (d[1] - d[0]).toFixed(2)); // Display the difference between the top and bottom of the bar


       //add x axis label
       svg.append('text')
       .attr("class", "x label")
       .attr("text-anchor", "middle")
       .attr("x", width/2 - margin.top)
       .attr("y", height - 2)
       .attr("dy", xaxisstyle.dy)
       .attr("dx",width/8 - margin.left)
       .style('fill',xaxisstyle.color)
       .style('font-weight',xaxisstyle.fontweight)
       .style('font-size',xaxisstyle.fontsize)
       .text(xlabel)
       .on("click",() =>{
        let label = prompt("Edit X Axis Label")
          if(label){
          setxlabel(label)
          }
      });
  
       //add y axis label
       svg.append("text")
       .attr("class", "y label")
       .attr('transform', `translate(80)`)
       .attr("text-anchor", "end")
       .attr("y", -margin.left*2)
       .attr("dy", yaxisstyle.dy)
       .attr("x",-height/2)
       .attr("transform", "rotate(-90)")
       .style('fill',yaxisstyle.color)
       .style('font-weight',yaxisstyle.fontweight)
       .style('font-size',yaxisstyle.fontsize)
       .text(ylabel)
       .on("click",() =>{
        let label = prompt("Edit Y Axis Label")
        if(label){
        setylabel(label)
        }
      });


       //title of the chart
       svg.append('text')
       .attr('class', 'title')
       .attr('x', width / 2 )
       .attr('y', -margin.left)
       .attr('text-anchor', 'middle')
       .style('font-size',titlecolor.fontsize)
       .style('font-weight',titlecolor.fontweight)
       .style('fill',titlecolor.color)
       .text(title)



    // Legend
    const legend = svg
    .selectAll('.legend')
    .data(cat)
    .enter()
    .append('g')
    .attr('class', 'legend')
    .attr('transform', (_, i) => `translate(0, ${i * 20})`)
    .style('font', '12px sans-serif')
    .on('click', toggleLegend);
    

  legend
    .append('rect')
    .attr('x', width - 18)
    .attr('width', 18)
    .attr('height', 18)
    .attr('fill', color);

  legend
    .append('text')
    .attr('x', width)
    .attr('y', 9)
    .attr('dy', '0.35em')
    .attr('text-anchor', 'start')
    .text(d => d);

      // Function to toggle legend visibility
      function toggleLegend(d) {
        const groupName = d.target.__data__;
        const selectedLegend = svg.selectAll('.legend').filter((legendName) => legendName === groupName);
  
        // Toggle legend
        selectedLegend.classed('disabled', !selectedLegend.classed('disabled'));
  
        // Toggle corresponding bars visibility
        const bars = svg.selectAll('rect').filter((d) => d.name === groupName);
  
        // if (selectedLegend.classed('disabled')) {
        //   bars.style('display', 'none');
        //   SetHide('hidden')
        // } else {
        //   SetHide('visible')
        //   bars.style('display', null); // Setting to null restores the default display property
        // }
  
      }

     
      return () => {
        d3.select(chartRef.current).selectAll('*').remove();
      };
  }, [data,cat,showGridLines,xlabel,ylabel]);

  const handleFontSizeChange = (event) =>{
    SetFontSize(event.target.value);
  }

  return (
      <>
        <div className="controller">
        <Button className='grid' style={{backgroundColor:colorLabel}} onClick={() => SethideColor(!hideColor)}>
               Color
            </Button>
           
            <Button className='grid' style={{fontWeight:FontWeight ? "700":"100"}} onClick={() => SetFontWeight(!FontWeight)}>
               B
            </Button>
            <select className='grid' id="font-size" value={fontSize} onChange={handleFontSizeChange}>
              <option value="12">12px</option>
              <option value="14">14px</option>
              <option value="16">16px</option>
              <option value="18">18px</option>
              <option value="20">20px</option>
              <option value="24">24px</option>
              <option value="28">28px</option>
              <option value="32">32px</option>
            </select>
            {
                actionPush == "section" &&
                <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
            }
            
           
            <Button className='grid' onClick={() => downloadChartAsImage(containerid)}>
                Download
            </Button>
            <Button className='grid' onClick={() => setactionPush("section")}>
            Copy to Ediotor
            </Button>
           </div>
           {hideColor ? 
        <div className='colorpicker'>
            {/* <span>Bar</span>
            <HuePicker
                color={colorBar}
                onChange={(e)=>SetcolorBar(e.hex)}
            />  */}
            {/* <span>Box</span>
            <span style={{color:"blue", padding:"0px 5px"}} onClick={() => Setcolor("#000000")}>Reset</span>
               <HuePicker
                color={color}
                onChange={(e)=>Setcolor(e.hex)}
            /> */}
             <span>Label Color</span>
             <span style={{color:"blue", padding:"0px 5px"}} onClick={() => SetcolorLabel("#000000")}>Reset</span>
               <HuePicker
                color={colorLabel}
                onChange={(e)=>SetcolorLabel(e.hex)}
                presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']}
            />
            </div>
            :""}
        <div ref={chartRef} id={containerid}></div>
      
      </>);
};

// StackedBarChart.prototype = {
//   data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
//   width: PropTypes.number,
//   heigth: PropTypes.number,
//   barstyle: PropTypes.object,
//   bardatastyle: PropTypes.object,
//   gridcolor: PropTypes.string,
//   gridstatus: PropTypes.string,
//   yaxistext: PropTypes.string,
//   yaxislabelstyle: PropTypes.object,
//   yaxisstyle: PropTypes.object,
//   xaxistext: PropTypes.string,
//   xaxislabelstyle: PropTypes.object,
//   xaxisstyle: PropTypes.object,
//   title: PropTypes.string,
//   titlecolor: PropTypes.object
// }

// StackedBarChart.defaultProps = {
//   data:  [
//     { label: 'A', category1: 20, category2: 30, category3: 10 },
//     { label: 'B', category1: 40, category2: 10, category3: 25 },
//     { label: 'C', category1: 15, category2: 25, category3: 35 },
//   ],
//   width: "600",
//   height: "400",
//   barstyle: { color: "#000", hover: "#ccc" },
//   bardatastyle: { fontsize: "16px", fontweight: "500", color: "#000" },
//   gridcolor: "#ccc",
//   gridstatus: "hidden",
//   yaxistext: "Y Label",
//   yaxislabelstyle: { fontsize: "14px", fontweight: "400", color: "red" },
//   yaxisstyle: { fontsize: "14px", fontweight: "600", color: "#000" },
//   xaxistext: "X Label",
//   xaxislabelstyle: { fontsize: "14px", fontweight: "400", color: "red" },
//   xaxisstyle: { fontsize: "14px", fontweight: "600", color: "#000" },
//   title: "Bar Chart",
//   titlecolor: { fontsize: "24px", fontweight: "600", color: "#000" }
// }
export default StackedBarChart;
