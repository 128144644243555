import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { authSelector } from '../../store/slices/auth';
import { expectedDateFormat } from '../../utils/Utils';

export default function ProjectCardMentor(props) {
	const { currentUser } = useSelector(authSelector);
	const navigate = useNavigate();

	const { data, type } = props;


	useEffect(() => {
		data.type = "invitee"		
	}, [])



	return (
		<div className='project-card'>
			<div className='top-row'>
				<Row className='m-0'>
					<Col className='p-0'>
						<div className='created-date'>{expectedDateFormat(data.createdon || data.UpdatedOn, 'DD MMM YYYY', 'YYYY-MM-DD')}</div>
					</Col>
					<Col className='p-0 row m-0 justify-content-end'>

						{
							data.permissions && data.permissions.map(items => (
								Object.keys(items)[0] == "Projects" ?
									items.Projects.map(item => (
										item.action == "data collections" ?
											item.permissions.filter(item1 => item1.is_selected == true).map(itemValue => (
												itemValue.permission == "allowed" ?
													<div className='data-entry-button'>
														<div className='data-entry-text' onClick={() => props.onData()}>{type === 'projects' ? 'Data Entry' : 'Data Collection'}</div>
													</div>
													: ""
											))
										:""
									))
								:""
							))
						}

						{/* {currentUser?.UserType !== 'Internal User' && <i className="icon icon-delete" onClick={() => props.onDelete()}></i>}
						<i className="icon icon-edit" onClick={() => props.onEdit()}></i> */}
					</Col>
				</Row>
				<div className='topic-desc' onClick={() => props.onprojectDetail()}>{data.topicdesc}</div>
				<div className='topic-scroll' onClick={() => props.onprojectDetail()}>
					{data.topicbigdesc?.map((obj, index) => (
						<div key={index} className='topic-objective'>{index + 1}. {obj}</div>
					))}
				</div>
			</div>
			<Row className='m-0 footer-row'>
				<Col className='p-0 add-people-text'>
					<span onClick={() => navigate('/people', { state: data })}>
						People
					</span>
				</Col>
				{type === 'projects' ?
					<Col className='p-0 text-right'>
						<i className="icon icon-study" onClick={() => props.onSampleSize(data)}></i>
						{data.Shared === 'y' && <i className="icon icon-share" onClick={() => props.onMove(data)}></i>}
					</Col> :
					<Col className='p-0 text-right'>
						{
							data.permissions && data.permissions.map(items => (
								Object.keys(items)[0] == "Projects" ?
									items.Projects.map(item => (
										item.action == "study design" ?
											item.permissions.filter(item1 => item1.is_selected == true).map(itemValue => (
												itemValue.permission == "allowed" ?
													<i className="icon icon-study" onClick={() => props.onSampleSize(data)}></i>
													: ""
											))
										:""
									))
								:""
							))
						}
						<i className="icon icon-upload"></i>
					</Col>
				}
			</Row>
		</div>
	)
}
