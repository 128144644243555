import { useState } from "react";
import { FIELD_TYPES, FieldItem } from "../../../../widgets/fields";
import useFieldItem from "../../../../widgets/fields/UseFieldItem";

const VersionSection = () => {
     const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem({}, {}, { onValueChange });
     const [isVisible, setIsVisible] = useState(false);
     const [versionList,setVersions] = useState([{
          label:"Version1",
          value:"Version1"
     },{
          label:"Version2",
          value:"Version2"
     },{
          label:"Version3",
          value:"Version3"
     }]);

       /**
      * 
      * @param name 
      * @param value 
      * @param values 
      * @param fieldValues 
      * @returns 
      */
       function onValueChange(name, value, values, fieldValues = null) {
          let resetValue = {};
          //console.log(formTemplates)

          return [{
               ...values,
               ...resetValue,
               [name]: value,
          }]
     }

     return (
          <>
               <div className="version-container">
                    <div className="version-head p-2 mt-1">

                         <FieldItem
                              name="versions"
                              value={values.versions}
                              onChange={(...e) =>
                                   handleChange("versions", ...e)
                              }
                              type={FIELD_TYPES.DROP_DOWN}
                              values={versionList}
                              touched={fields.versions && fields.versions.hasError}
                              error={fields.versions && fields.versions.errorMsg}
                         />
                    </div>
               </div>
          </>
     )
}

export default VersionSection;