import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../../store/slices/auth';
import { getCurrentPayment, hasPayment } from '../project/apiController';
import ModalPopup from '../../widgets/modal';
import { showToaster, toasterTypes } from '../../widgets';

export default function Tabs(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [popupType, setpopupType] = useState('');
    const [selectedData, setselectedData] = useState(null);
    const { data, selectedTab, projectData } = props;
    const [permissionParams, SetPermissionParams] = useState<any>({});

    // const getPermissions = async (data) => {

    //     await data?.permissions && data.permissions.map(items => {

    //       if(Object.keys(items)[0].replace(/([^\w]+|\s+)/g, '') == "Tabs"){
    //         items[Object.keys(items)[0]].map(item => {
    //         if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
    //           if (item.action == "project details ,") {
    //           SetPermissionParams(prevState => ({ ...prevState, projectdetail: "allowed" }))
    //           }else if(item.action == "study design,"){
    //           SetPermissionParams(prevState => ({ ...prevState, studydesign: "allowed" }))
    //           }else if(item.action == "Data collection,"){
    //           SetPermissionParams(prevState => ({ ...prevState, datacollection: "allowed" }))
    //           }else if(item.action == "Data analysis,"){
    //           SetPermissionParams(prevState => ({ ...prevState, dataanalysis: "allowed" }))
    //           }else if(item.action == "mentors,"){
    //           SetPermissionParams(prevState => ({ ...prevState, mentors: "allowed" }))
    //           }else if(item.action == "register events"){
    //           SetPermissionParams(prevState => ({ ...prevState, registerevents: "allowed" }))
    //           }
    //         }else{
    //           if (item.action == "project details ,") {
    //           SetPermissionParams(prevState => ({ ...prevState, projectdetail: "forbidden" }))
    //           }else if(item.action == "study design,"){
    //           SetPermissionParams(prevState => ({ ...prevState, studydesign: "forbidden" }))
    //           }else if(item.action == "Data collection,"){
    //           SetPermissionParams(prevState => ({ ...prevState, datacollection: "forbidden" }))
    //           }else if(item.action == "Data analysis,"){
    //           SetPermissionParams(prevState => ({ ...prevState, dataanalysis: "forbidden" }))
    //           }else if(item.action == "mentors,"){
    //           SetPermissionParams(prevState => ({ ...prevState, mentors: "forbidden" }))
    //           }else if(item.action == "register events"){
    //           SetPermissionParams(prevState => ({ ...prevState, registerevents: "forbidden" }))
    //           }
    //         }

    //         })
    //       }	

    //     })

    //     console.log(permissionParams)

    //     }

    const checkNavigate = async (obj, type = '') => {
        if (obj.id === 2 && projectData.SDMName === 'NA' && !type) {
            setselectedData(obj);
            setpopupType('sampleSize');
            return;
        }
        try {
            let dummyData: any = cloneDeep(projectData);
            let dataTab: any = cloneDeep(projectData);
            if (dataTab.permissions) {

                dataTab && dataTab.permissions.map(async items => {

                    if (Object.keys(items)[0].replace(/([^\w]+|\s+)/g, '') == "Tabs") {

                        items[Object.keys(items)[0]].map(async item => {                                
                            if (item.action.replace(/([^\w]+|\s+)/g, '') == obj.tab.replace(/([^\w]+|\s+)/g, '')) {
                                if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
                                    console.log("TYPE::::::::::::", dummyData)
                                    dispatch(setLoading(true));
                                    dummyData = await checkPayment(dummyData);
                                    let response = await hasPayment(dummyData?.UserNumber, dummyData?.TopicCode || dummyData?.TopicID);
                                    if (response.data){
                                        let route = '';
                                        switch (obj.id) {
                                            case 1:
                                                route = '/projectdetail';
                                                break;
                                            case 2:
                                                if (projectData.SDMName === 'NA') {
                                                    route = '/sampleSize';
                                                } else {
                                                    route = '/studyModule';
                                                }
                                                break;
                                            case 3:
                                                route = '/dataCollection';
                                                break;
                                            case 4:
                                                route = '/dataanalysis';
                                                break;
                                            case 5:
                                                route = '/people';
                                                break;
                                            case 6:
                                                route = '/planner';
                                                break;
                                            case 7:
                                                route = '/registeredevents';
                                                break;
                                            default:
                                                route = '/project'
                                                break;
                                        }
                                        console.log("Tabs:::::::", dummyData)
                                        navigate(route, { state: dummyData });
                                    } else {
                                        alert('Please subscribe to proceed. Do you want to a make payment now?')
                                    }
                                    dispatch(setLoading(false));
                                } else {
                                    showToaster(toasterTypes.INFO, "Needs Permission to Access this Module")
                                }
                            }
                        })
                    }
                })
            } else {
                dispatch(setLoading(true));
                console.log(dummyData)
                dummyData = await checkPayment(dummyData);
                let response = await hasPayment(dummyData?.UserNumber, dummyData?.TopicCode || dummyData?.TopicID);
                if (response.data) {
                    let route = '';
                    switch (obj.id) {
                        case 1:
                            route = '/projectdetail';
                            break;
                        case 2:
                            if (projectData.SDMName === 'NA') {
                                route = '/sampleSize';
                            } else {
                                route = '/studyModule';
                            }
                            break;
                        case 3:
                            route = '/dataCollection';
                            break;
                        case 4:
                            route = '/dataanalysis';
                            break;
                        case 5:
                            route = '/people';
                            break;
                        case 6:
                            route = '/planner';
                            break;
                        case 7:
                            route = '/registeredevents';
                            break;

                        default:
                            route = '/project'
                            break;
                    }
                    console.log("Tabs:::::::", dummyData)
                    navigate(route, { state: dummyData });
                } else {
                    alert('Please subscribe to proceed. Do you want to a make payment now?')
                }
                dispatch(setLoading(false));
            }
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const checkPayment = async (data) => {
        let paymentData = await getCurrentPayment(data?.UserNumber, data?.TopicCode || data?.TopicID);
        if (paymentData.data === "no_active_plan" || paymentData.data === "free_trial") {
            data.type = 'can_buy';
        } else if (paymentData.data === "basic") {
            data.type = 'can_upgrade';
        } else {
            data.type = '';
        }
        return data;
    }



    useEffect(() => {
        console.log(permissionParams)
    }, [permissionParams])

    return (
        <div className='project-tabs-container'>
            {popupType === 'sampleSize' &&
                <ModalPopup
                    modalType='confirm'
                    modalText="Do you want to start project from Sample size calculation?"
                    actionText=""
                    closeModal={() => { setselectedData(null); setpopupType(''); }}
                    onAction={() => checkNavigate(selectedData, 'checked')}
                />
            }
            {data.map((obj, index) => (
                <div className={`${selectedTab === index ? 'selected-tab-col tab-col' : 'tab-col'}`} key={index} onClick={() => selectedTab === index ? null : obj.active == true ? checkNavigate(obj) : checkNavigate(obj)}>
                    <div className='tab-text'>{obj.title}</div>
                </div>
            ))}
        </div>
    )
}
