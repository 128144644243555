import axios from 'axios';
import AjaxUtil from '../../axios/axios';

export const getGroups = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`groups?topic_id=${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * get templates list
 */
export const getTemplatesList = async() => {
    try {
        return await AjaxUtil.sendRequest(`/templates`, {}, false, { method: 'get', showError: true, isDocument: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * create template
 */
export const createTemplate = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`/`, data, false, { method: 'post', showError: true, isDocument: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * get list of documents
 */
export const getDocumentList = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`list/${id}?page=1&per_page=10`, {}, false, { method: 'get', showError: true, isDocument: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get data of documents
 */
export const getDocument = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`/${id}`, {}, false, { method: 'get', showError: true, isDocument: true} );
    } catch (error) {
        throw error;
    }
} 

/**
 * get data with specific version
 */
export const getVersionDoc = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`/content/revision/${id}`, {}, false, { method: 'get', showError: true, isDocument: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * update template
 */
export const updateTemplate = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`/`, data, false, { method: 'put', showError: true, isDocument: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * update template
 */
export const deleteTemplate = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`/${id}`, {}, false, { method: 'delete', showError: true, isDocument: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * post revision code
 */
export const postRevisedData = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`/content/revision`, data, false, { method: 'post', showError: true, isDocument: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get data from rms for document
 */

export const getRMSDocument = async(id) => {
    console.log("#################")
    try {
        return await AjaxUtil.fetchDocumentData(`doc/${id}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}