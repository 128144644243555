import axios from 'axios';
import AjaxUtil from '../../axios/axios';

export const getGroups = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`groups?topic_id=${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getForms = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`forms/${id}?analytics=true`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * form id
 * @param {*} id 
 * @returns 
 */
export const getFormData = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`form/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * merge forms
 */
export const mergeForms = async(data)=>{
    try {
        return await AjaxUtil.sendRequest(`forms/merge`, data, false, { method: 'post', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get variable details
 */
export const getVariable = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`variable/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * post the grouping variables
 */
export const postGroupVariable = async(id,data) => {
    try {
        return await AjaxUtil.sendRequest(`form/${id}/variable`,data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get anaysis type
 */
export const getAnalysisTypes = async()=>{
    try {
        return await AjaxUtil.sendRequest(`types`, {}, false, { method: 'get', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get variables list
 */
export const getAnalysisVariables = async(cumid)=>{
    try {
        return await AjaxUtil.sendRequest(`form/${cumid}/variables`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get chart type list
 */
export const getAnalysisChartTypes = async()=>{
    try {
        return await AjaxUtil.sendRequest(`chart/types`, {}, false, { method: 'get', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * Save selected variables 
 */
export const postSelectedVariables = async(data)=>{
    try {
        return await AjaxUtil.sendRequest(`/`, data, false, { method: 'post', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * get analysis list
 */
export const getAnalysisList = async(groupid,perpage,page)=>{
    try {
        return await AjaxUtil.sendRequest(`list?group_id=${groupid}&page=${page}&per_page=${perpage}`, {}, false, { method: 'get', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * get analysis list
 */
export const getOutcomeVariable = async(savedid)=>{
    try {
        return await AjaxUtil.sendRequest(`/${savedid}`, {}, false, { method: 'get', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * Update config data 
 */
export const updateSelectedConfig = async(id,data)=>{
    try {
        return await AjaxUtil.sendRequest(`/${id}`, data, false, { method: 'put', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get grouping variables
 */
export const getGroupVariable = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`form/${id}/variables?field_use_type=Grouping`,{}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * delete group variable
 */
export const deleteGroupVariable = async(formid,fieldid) => {
    try {
        return await AjaxUtil.sendRequest(`variable/${formid}/${fieldid}`,{}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get analysis report data
 */
export const getAnalysisData = async(formid,configid)=>{
    try {
       // let data = await axios.get('https://da.coguide.in/analysis/default/C10624/15')
      //  return data.data;
        return await AjaxUtil.sendRequest(`https://analysis.coguide.in/analysis/genda/${formid}/${configid}`, {}, false, { method: 'get', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * get features list
 */
export const getFeatureList = async()=>{
    try {
        return await AjaxUtil.sendRequest(`features`, {}, false, { method: 'get', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get split on list
 */
export const getSplitOnList = async()=>{
    try {
        return await AjaxUtil.sendRequest(`splitons`, {}, false, { method: 'get', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}


/**
 * get formulas list
 */
export const getFormulaList = async()=>{
    try {
        return await AjaxUtil.sendRequest(`formulas`, {}, false, { method: 'get', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * delete analysis config
 */
export const deleteAnalysisConfig = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`/${id}`,{}, false, { method: 'delete', showError: true, isAnalysis: true} );
    } catch (error) {
        throw error;
    }
}