import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Button } from 'reactstrap';
import SectionModal from '../../Editor/utils/SectionModal';
import { downloadChartAsImage } from './downloadChartAsImage';
import { uploadChartAsImage } from '../../Editor/utils/UploadToServer';

const HistogramChart = ({containerid, data, width, height,title }) => {
  const svgRef = useRef();
  const tooltipRef = useRef();
  const [actionPush, setactionPush] = useState('');

  /**
  * function call to Push code to editor
  */
  const addToEditor = (data) => {
      console.log(data)
      uploadChartAsImage(data,containerid)
      setactionPush("")
  }
  useEffect(() => {
    if (!data) return;

    const svg = d3.select(svgRef.current);
    const tooltip = d3.select(tooltipRef.current);

    // Define margins
    const margin = { top: 20, right: 30, bottom: 50, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Create x scale
    const x = d3.scaleLinear()
      .domain([0, d3.max(data)])
      .range([0, innerWidth]);

    // Create histogram function
    const histogram = d3.histogram()
      .domain(x.domain())
      .thresholds(x.ticks(30));

    // Generate bins
    const bins = histogram(data);

    // Create y scale
    const y = d3.scaleLinear()
      .domain([0, d3.max(bins, d => d.length)])
      .range([innerHeight, 0]);

    // Create bars
    svg.selectAll('.bar')
      .data(bins)
      .join('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d.x0) + margin.left)
      .attr('y', d => y(d.length) + margin.top)
      .attr('width', d => x(d.x1) - x(d.x0) - 1)
      .attr('height', d => innerHeight - y(d.length))
      .attr('fill', 'steelblue');

    // Create x-axis
    const xAxis = d3.axisBottom(x)
      .ticks(10)
      .tickFormat(d3.format('.0f'))
      .tickValues(x.ticks(30).filter((d, i) => i % 2 === 0)); // Custom interval

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(${margin.left}, ${innerHeight + margin.top})`)
      .call(xAxis);

    // Create y-axis
    const yAxis = d3.axisLeft(y);
    svg.append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .call(yAxis);

    // Add normal distribution curve
    const normalCurve = d3.curveBasis;
    const line = d3.line()
      .x(d => x(d[0]) + margin.left)
      .y(d => y(d[1]) + margin.top)
      .curve(normalCurve);

    const mean = d3.mean(data);
    const deviation = d3.deviation(data);

    const normalData = d3.range(0, d3.max(data), 1).map(x => [x, normalDistribution(x, mean, deviation)]);

    svg.append('path')
      .datum(normalData)
      .attr('class', 'normal-curve')
      .attr('fill', 'none')
      .attr('stroke', 'red')
      .attr('stroke-width', 2)
      .attr('d', line)
      .on('mouseover', () => tooltip.style('display', 'block'))
      .on('mouseout', () => tooltip.style('display', 'none'))
      .on('mousemove', (event, d) => {
        const xPos = event.pageX;
        const yPos = event.pageY;
        const xValue = x.invert(event.offsetX - margin.left).toFixed(2);
        const yValue = normalDistribution(xValue, mean, deviation).toFixed(4);
        tooltip.html(`x: ${xValue}, y: ${yValue}`)
          .style('left', `${xPos/4}px`)
          .style('top', `${yPos/2}px`);
      });

    // Enable zoom
    const zoomBehavior = d3.zoom()
      .scaleExtent([1, 10]) // Set the scale extent
      .translateExtent([[0, 0], [width, height]]) // Set the translate extent
      .on('zoom', zoomed);

    svg.call(zoomBehavior);

    function zoomed(event) {
      const newX = event.transform.rescaleX(x);
      const newAxis = xAxis.scale(newX);
      const newLine = line.x(d => newX(d[0]) + margin.left);

      svg.select('.x-axis')
        .call(newAxis);

      svg.select('.normal-curve')
        .attr('d', newLine);

      svg.selectAll('.bar')
        .attr('x', d => newX(d.x0) + margin.left)
        .attr('width', d => newX(d.x1) - newX(d.x0) - 1);
    }

  }, [data, width, height]);

  // Normal distribution function
  const normalDistribution = (x, mean, deviation) => {
    return (1 / (deviation * Math.sqrt(2 * Math.PI))) * Math.exp(-Math.pow(x - mean, 2) / (2 * Math.pow(deviation, 2)));
  };

  return (
    <div style={{ position: 'relative' }}>
       <div className="controller">
       {
                actionPush == "section" &&
                <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
            }
            <Button className='grid' onClick={() => downloadChartAsImage(containerid)}>
                Download
            </Button>
            <Button className='grid' onClick={() => setactionPush("section")}>
            Copy to Ediotor
            </Button>
            {/* <Button className='grid' >
              Show Values
            </Button> */}
            <h5>Histogram</h5>

        </div>
      <div ref={tooltipRef} style={{ display: 'none', position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '5px', border: '1px solid #ccc', borderRadius: '5px' }}></div>
      <div id="chart-container">
      <svg ref={svgRef} width={width} height={height}>
        {/* SVG content will be rendered here */}
      </svg>
      </div>
    </div>
  );
};

export default HistogramChart;
