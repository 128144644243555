import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import ModalPopup from '../../widgets/modal';
import Tabs from '../sampleSize/Tabs';
import { loadHtml } from './apiController';
import { calcCCS_P, calcDCSS1_P, calcDCSS_P, calcICSCBA_N, calcICSCBA_P, calcRCTNRCT_N, calcRCTNRCT_P, calcSampleSizeForExpectedSensitivity, calcICSCBA_CC } from './formula';
import { ArrowLeftOutlined } from '@ant-design/icons';
import PushToEditor from '../Editor/utils/PushToEditor';
import { demo } from '../Editor/demo';
import SectionModal from '../Editor/utils/SectionModal';
import CopyTooltip from '../Editor/utils/CopyTooltip';
import { uploadSelectedFile } from '../submissions/apiController';

export default function StudyModule() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [projectData, setprojectData] = useState<any | null>(null);
	const [htmlData, sethtmlData] = useState('');
	const [isPopup, setisPopup] = useState(false);
	const [formulaResponse, SetformulaResponse] = useState<any>([]);
	const [sampleProduct, SetSampleProduct] = useState<any>(location.state);
	const [permissionParams, SetPermissionParams] = useState<any>({});
	const [selectedText, setSelectedText] = useState<any>([]);
	const [tooltipStyle, setTooltipStyle] = useState({});
	const [showTooltip, setShowTooltip] = useState(false);
	const [action, setAction] = useState("");

	const tabData = [
		{
			title: "Project Details",
			id: 1,
			tab: "project details"
		},
		{
			title: "Study Design",
			id: 2,
			tab: "study design"
		},
		{
			title: "Data Collection",
			id: 3,
			tab: "Data collection"

		},
		{
			title: "Data Analysis",
			id: 4,
			tab: "Data analysis"
		},
		{
			title: "People",
			id: 5,
			tab: "people"

		},
		{
			title: "Planner",
			id: 6,
			tab: "people"

		},
		{
			title: "Registered Events",
			id: 7,
			tab: "register events"

		},
		// {
		//     title: "Mentors",
		//     id: 8,
		//     tab: "mentors"
		// }
	];

	/**
	 * 
	 * @param data 
	 */
	const getPermissions = async (data) => {
		await data.permissions && data.permissions.map(items => {
			console.log(Object.keys(items)[0].replace(/([^\w]+|\s+)/g, ''))

			if (Object.keys(items)[0].replace(/([^\w]+|\s+)/g, '') == "studydesign") {
				items[Object.keys(items)[0]].map(item => {
					if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
						if (item.action == "previous button") {
							SetPermissionParams(prevState => ({ ...prevState, previous: "allowed" }))
						} else if (item.action == "cancel button") {
							SetPermissionParams(prevState => ({ ...prevState, cancel: "allowed" }))
						} else if (item.action == "save & continue button") {
							SetPermissionParams(prevState => ({ ...prevState, save: "allowed" }))
						}
					} else {
						if (item.action == "previous") {
							SetPermissionParams(prevState => ({ ...prevState, previous: "forbidden" }))
						} else if (item.action == "save & continue button") {
							SetPermissionParams(prevState => ({ ...prevState, save: "forbidden" }))
						} else if (item.action == "cancel") {
							SetPermissionParams(prevState => ({ ...prevState, cancel: "forbidden" }))
						}
					}

				})
			}

		})
	}

	useEffect(() => {
		console.log(location.state)
		if (location.state) {
			let stateData: any = location.state;
			getPermissions(stateData)
			setprojectData(stateData);
			fetchData(stateData);
			if (!stateData.SDMCode) {
				navigate('/sampleSize', { state: stateData })
			}
		} else {
			navigate('/project');
		}
	}, [location.state, navigate]);


	const fetchData = async (stateData) => {
		try {
			dispatch(setLoading(true));
			let response = await loadHtml(stateData.Output);
			populatePlaceHolders(response, stateData);
			dispatch(setLoading(false));

		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const escapeRegExp = (inputString: string) => {
		return inputString.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
	}

	const populatePlaceHolders = async (studyData: any, stateData) => {
		let direction = "";
		let inputs: any = stateData.Input;
		inputs.forEach((data) => {
			let placeHolder = data.PlaceHolder;
			if (placeHolder === "${Direction}") {
				direction = data.Value;
			}
		});
		let additionValues: any = [];
		inputs.forEach((data) => {
			let inputData = {
				PlaceHolder: "",
				Value: "",
			};
			let placeHolder = data.PlaceHolder;
			let value = data.Value;
			if (placeHolder === "${V}") {
				inputData.PlaceHolder = "${V_Label}";
				data.DropDown.forEach((optionObj) => {
					if (optionObj['value'] == data.Value) {
						inputData.Value = optionObj['name'];
						data.Value = data.Value.split(":")[1];
					} else if (optionObj['value'].split(":")[1] == data.Value) {
						inputData.Value = optionObj['name'];
					}
				});
				additionValues.push(inputData);
			}
			if (placeHolder === "${U}") {
				inputData.PlaceHolder = "${U_Label}";
				data.DropDown.forEach((optionObj) => {
					if (optionObj['value'] === data.Value) {
						inputData.Value = optionObj['name'];
					}
				});
				additionValues.push(inputData);
			}
			if (placeHolder === "${Z}") {
				inputData.PlaceHolder = "${Z_Label}";
				data.DropDown.forEach((optionObj) => {
					if (optionObj['value'] === data.Value) {
						inputData.Value = optionObj['name'];
					}
				});
				additionValues.push(inputData);
			}
			if (direction !== "" && placeHolder === "${Direction}") { //V changed to DIrection
				let spValue = value?.split(":");
				if (direction === "one sided") {
					data.Value = spValue[0];
				} else if (direction === "two sided") {
					data.Value = spValue[1];
				}
			}
			if (placeHolder === "${PropotionOutcomIntervention1}") {
				let value = parseFloat(data.Value);
				value = value / 100;
				inputData.PlaceHolder = "${PropotionOutcomIntervention1_Per}";
				inputData.Value = value + "";
				additionValues.push(inputData);
			} else if (placeHolder === "${PropotionOutcomIntervention2}") {
				let value = parseFloat(data.Value);
				value = value / 100;
				inputData.PlaceHolder = "${PropotionOutcomIntervention2_Per}";
				inputData.Value = value + "";
				additionValues.push(inputData);
			}
			studyData = studyData.replace(new RegExp(escapeRegExp(placeHolder), 'g'), data.Value);
		});

		stateData.QuestionHistory.forEach((data) => {
			let inputData = {
				PlaceHolder: "",
				Value: "",
			};
			if (data.QuestionId != undefined) {
				inputData.PlaceHolder = "${" + data.QuestionId + "}";
			} else {
				inputData.PlaceHolder = "${" + data.questionid + "}";
			}
			if (data.Answer != undefined) {
				inputData.Value = data.Answer;
			} else {
				inputData.Value = data.answer;
			}
			additionValues.push(inputData);
		});
		inputs.forEach((data) => {
			let inputData = {
				PlaceHolder: "",
				Value: "",
			};
			inputData.PlaceHolder = data.PlaceHolder;
			inputData.Value = data.Value;
			additionValues.push(inputData);
		});
		additionValues.forEach((data) => {
			studyData = studyData.replace(new RegExp(escapeRegExp(data.PlaceHolder), 'g'), data.Value);
		});

		let calArray: any = [];
		console.log(inputs)
		console.log(stateData.SDMSubCode)
		switch (stateData.SDMSubCode) {
			case "DAS":
				calArray = calcSampleSizeForExpectedSensitivity(inputs);
				break;
			case "RCTNRCT_N":
				calArray = calcRCTNRCT_N(inputs);
				break;
			case "RCTNRCT_P":
				calArray = calcRCTNRCT_P(inputs);
				break;
			case "ICSCBA_N":
				calArray = calcICSCBA_N(inputs);
				break;
			case "ICSCBA_P":
				calArray = calcICSCBA_P(inputs);
				break;
			case "DCSS_P":
				calArray = calcDCSS_P(inputs);
				break;
			case "DCSS1_P":
				calArray = calcDCSS1_P(inputs);
				break;
			case "CCS_N":
				calArray = calcRCTNRCT_N(inputs);
				break;
			case "CCS_P":
				calArray = calcCCS_P(inputs);
				break;
			case "ICSCBA_CC":
				calArray = calcICSCBA_CC(inputs);
				break;
			case "DCSS_CC":
				calArray = calcICSCBA_CC(inputs);
				break;
			default:
				break;
		}

		calArray = await calArray;
		console.log(calArray)
		calArray.forEach((data: any) => {
			studyData = studyData.replace(new RegExp(escapeRegExp(data.PlaceHolder), 'g'), data.Value)
		});
		studyData = studyData.replace(new RegExp(escapeRegExp("${SDMName}"), 'g'), stateData.SDMName)
		studyData = studyData.replace(new RegExp(escapeRegExp("${SDMSubname}"), 'g'), stateData.SDMSubname)
		sethtmlData(studyData);
		dispatch(setLoading(false));
	}

	const download = () => {
		var divContents: any = document.getElementById("printContent")?.innerHTML;
		var a: any = window.open('', '', 'height=1000, width=1000');
		a.document.write(`<title>${projectData.SDMName}</title>`);
		a.document.write(divContents);
		a.document.close();
		a.print();
	}

	/**
 * function call to Push code to editor
 * create promise array, 
 */
	const addToEditor = async (data) => {
		console.log(selectedText)
		let promiseArray : any = [];
		selectedText.forEach(item => {
			console.log(item)
			if (item.type == "img") {
				promiseArray.push(getFormDataFromURL(item.type,item.text, data.section.value))
				//getFormDataFromURL(item.text, data.section.value);
			} else if (item.text != "" && item.type == "text") {
				if(item.text != " "){
					promiseArray.push(getFormDataFromURL(item.type, item.text, data.section.value))
				}
			}
					
		});

		let dataResponse = await Promise.all(promiseArray);
		console.log("***********8",dataResponse)
		if(dataResponse){
			dataResponse.forEach((item,i) =>{
				console.log(item)
				let response = PushToEditor(item, data.section.value)
				if (response && dataResponse.length == i+1) {
					showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
				}
			})
			
		}	
	 setAction("")
	}

	useEffect(() => {
		// Attach the mouseup event listener to the document
		document.addEventListener('mouseup', getSelectedText);

		// Clean up the event listener on component unmount
		return () => {
			document.removeEventListener('mouseup', getSelectedText);
		};
	}, [selectedText]);

	/**
	 * fucntion to get image from the URL
	 * and push to editor
	 * @param url 
	 * @returns 
	 */
	async function getFormDataFromURL(type, url, data) {
		try {
			let outputObj = {};
			if(type == "img"){
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
			}

			const blob = await response.blob();
			const formData = new FormData();
			formData.append('file', blob, 'image002.png');
			let uploadResponse = await uploadSelectedFile("png", formData)
			console.log(data, uploadResponse.mediaUrl)
			outputObj = {
				"type": "figure",
				"attrs": {
					"id": "",
					"track": [],
					"group": "",
					"viewid": ""
				},
				"content": [
					{
						"type": "image",
						"attrs": {
							"id": "75ebd563-f450-4f27-be1f-b009c7bec152",
							"src": "https://go.coguide.in/c1/download/" + uploadResponse.mediaUrl,
							"alt": "",
							"title": null,
							"extraData": {},
							"fileid": null,
							"aria-describedby": "0e4c8928-b85f-4ff0-90b7-49471251d023",
							"aria-description": ""
						}
					}
				]
			};

		}else{
			outputObj = {
				"type": "paragraph",
				"attrs": {
					"id": "",
					"class": "paragraph",
					"track": [],
					"group": "",
					"viewid": ""
				},
				"content": [
					{
						"type": "text",
						"text": url
					}
				]
			};
		}
			// let pushResponse = PushToEditor(imageDoc, data)
			// if (pushResponse) {
			// 	showToaster(toasterTypes.SUCCESS, "Pushed Image to editor")
			// }
			return outputObj;
		} catch (error) {
			console.error('Error:', error);
			return null;
		}
	}


	/**
	 * process node to get nested text and images
	 * @param node 
	 */
	let dataArray: any = [];
	// Function to recursively process each node
	function processNode(node, result) {
		if (node.nodeType === Node.TEXT_NODE) {
			// If it's a text node, append the text content to the result
			result.push({ type: 'text', content: node.textContent.trim() });
		} else if (node.nodeType === Node.ELEMENT_NODE) {
			if (node.tagName === 'IMG') {
				// If it's an image element, add the image source to the result
				result.push({ type: 'image', content: node.src });
			} else if (['P', 'DIV'].includes(node.tagName)) {
				// If it's a new paragraph or block-level element, add a marker for a new line
				if (result.length > 0) {
					result.push({ type: 'newline' });
				}
				// Recursively process its child nodes
				node.childNodes.forEach(childNode => processNode(childNode, result));
			} else {
				// Recursively process other elements' child nodes
				node.childNodes.forEach(childNode => processNode(childNode, result));
			}
		}
	}



	/**
	 * selected text
	 * @param data 
	 */
	const getSelectedText = () => {
		//get the selection from window
		const selection: any = window.getSelection();
		console.log(selection)

		//previous
		if (selection.toString().trim()) {

			if (selection.rangeCount > 0) {
				// Get the range (the selected portion)
				const range = selection.getRangeAt(0);

				// Clone the contents of the range
				const documentFragment = range.cloneContents();

				// Array to store the result
				const result: any = [];

				// Iterate through the nodes in the document fragment and process each
				documentFragment.childNodes.forEach(node => processNode(node, result));

				// Merge adjacent text nodes and handle newlines
				let mergedResult: any = [];
				result.forEach((item, index) => {
					if (item.type === 'text' && mergedResult.length > 0 && mergedResult[mergedResult.length - 1].type === 'text') {
						// If the current item is text and the previous item was also text, merge them
						mergedResult[mergedResult.length - 1].content += ' ' + item.content;
					} else {
						mergedResult.push(item);
					}
				});

				// Output the merged result with line breaks for new paragraphs
				mergedResult.forEach(item => {
					if (item.type === 'text') {
						console.log("Text:", item.content);
					     dataArray.push({ type: "text", text: item.content.replace(/(\r\n|\n|\r)/gm, " ") })

					} else if (item.type === 'image') {
						console.log("Image:", item.content);
						dataArray.push({ type: "img", text: item.content })
					} else if (item.type === 'newline') {
						console.log("\n--- New Line ---\n");
					}
				});
			} else {
				console.log("No selection found.");
			}

			const range = selection.getRangeAt(0);
			const rect = range.getBoundingClientRect();


			// Update the state with the selected text and tooltip position
			//setSelectedText(selection.toString());
			setSelectedText(dataArray)

			setTooltipStyle({
				top: `${rect.top + window.scrollY + rect.height - 50}px`,
				left: `${rect.left + window.scrollX - 100}px`,
			});

			setShowTooltip(true);
		} else {
			setShowTooltip(false);
		}
	};

	const sectionAction = () => {
		setAction("section")
	}

	return (
		<div className='study-module-page'>
			{
				action == "section" &&
				<SectionModal pushAction={addToEditor} onclose={() => setAction("")} />
			}
			{isPopup &&
				<ModalPopup
					modalType='delete'
					modalText="Are you sure you want to cancel?"
					actionText="This will take you to the beginning of sample size calculation."
					closeModal={() => setisPopup(false)}
					onAction={() => navigate('/sampleSize', { state: projectData })}
				/>
			}
			<Row className='page-header m-0'>
				<Col className={window.innerWidth > 750 ? "project-title" : "project-title-mob"} md={12}>
					<ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => window.innerWidth > 750 ? navigate("/project") : navigate("/projectmobile")} />
					{projectData?.TopicDesc || projectData?.topicdesc}
				</Col>
				<Col className='p-0'></Col>
			</Row>
			<Tabs data={tabData} selectedTab={1} projectData={projectData} />
			<div className='tab-page' id="printContent" dangerouslySetInnerHTML={{ __html: htmlData }}></div>
			<Row className='footer-container m-0'>
				<Col className='p-0'>
					{permissionParams.cancel == "allowed" || Object.keys(permissionParams).length == 0 ?
						<div className='cancel-text' onClick={() => setisPopup(true)}>Cancel</div>
						: ""
					}
				</Col>

				<Col className='p-0'>
					<div className='d-flex m-0 justify-content-end'>
						<div className='mr-3'>
							{permissionParams.previous == "allowed" || Object.keys(permissionParams).length == 0 ?
								<CustomButton type="alert-primary" onClick={() => navigate('/sampleSize', { state: { data: sampleProduct, typedata: "STD" } })} className="w-100" text="Previous"></CustomButton>
								: ""
							}
						</div>
					</div>
				</Col>
				<Col className='p-0'>
					<div className='d-flex m-0 justify-content-end'>
						<div className='mr-3'>
							<CustomButton type="alert-primary" onClick={() => download()} className="w-100" text="Download"></CustomButton>
						</div>
						<CustomButton type="primary" onClick={() => localStorage.getItem("device") == "desktop" ? navigate('/project') : navigate('/projectmobile')} className="w-100" text="Save and Continue"></CustomButton>
					</div>
				</Col>
			</Row>
			{showTooltip && (
				<CopyTooltip tooltipStyle={tooltipStyle} data={selectedText} pushAction={sectionAction} />
			)}
		</div>
	)
}
