import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { deleteComment, getTaskComments, saveComment, updateComment } from '../../planner/apiController';

const GanttChart = ({ data,userData,scale,onStatusSave,teamData,onAssignedToSave,getActualBarColors}) => {
  const chartRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 1200, height: 600 }); // Set default dimensions
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const chartColors = {
    dayLabelColor:"white",
    weekLabelColor:"white",
    monthLabelColor: "white",
    yearLabelColor:"white"
  }

  useEffect(() => {

    // console.log("teamdatta",teamData);
    const updateDimensions = () => {
      if (chartRef.current) {
        const { width } = chartRef.current.getBoundingClientRect();
        // Keep the height fixed, adjust only the width
        setDimensions({ width: Math.max(width, 1200), height: 600 });
      }
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (data && chartRef.current) {
      drawChart();
    }
  }, [data, dimensions, teamData]);

  const drawChart = () => {
    // console.log("Original data:", data);

    const margin = { top: window.innerWidth > 700 ? 80 : 60, right: window.innerWidth > 700 ? 30 : 20, bottom: window.innerWidth > 700 ? 30 : 20, left: window.innerWidth > 700 ? 200 : 120 };
    const width = dimensions.width - margin.left - margin.right - (window.innerWidth > 700 ? 0 : 200);
    const height = dimensions.height - margin.top - margin.bottom - (window.innerWidth > 700 ? 0 : 100);

    // Clear previous chart
    d3.select(chartRef.current).selectAll("*").remove();

    const svg = d3.select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("class", "svg-comp")
      .style("background","white")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    // Background for header
    svg.append("rect")
      .attr("x", -margin.left)
      .attr("y", -margin.top)
      .attr("width", width + margin.left + margin.right)
      .attr("height", margin.top)
      .attr("fill", "#455570")// Header background color

    // Background for body
    svg.append("rect")
      .attr("x", -margin.left)
      .attr("y", 0)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.bottom)
      .attr("fill", "#e6f3ff"); // Body background color



    // Parse dates
    // const parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%SZ");
    // const parseTimeWithMilliseconds = d3.timeParse("%Y-%m-%dT%H:%M:%S.%LZ");

    // Function to parse a date with multiple formats
    const parseDate = (dateStr) => {
      const date = new Date(dateStr);
      return date;
    };

    const validData = data.filter(d => {
      const start = parseDate(d.planned_start_date);
      const end = parseDate(d.planned_end_date);
      return start && end && !isNaN(start) && !isNaN(end);
    }).map(d => ({
      ...d,
      plannedStart: parseDate(d.planned_start_date),
      plannedEnd: parseDate(d.planned_end_date),
      actualStart: d.actual_start_date ? parseDate(d.actual_start_date) : null,
      actualEnd: d.actual_end_date ? parseDate(d.actual_end_date) : null
    }));

    // console.log("Valid data after parsing:", validData);

    if (validData.length === 0) {
      svg.append("text")
        .attr("x", width / 2)
        .attr("y", height / 2)
        .attr("text-anchor", "middle")
        .text("No valid data to display. Please check your date formats.");
      return;
    }




    // Set up scales

    const minDate = d3.min(validData, d => d.plannedStart);
    const maxDate = d3.max(validData, d => d.actualEnd || d.plannedEnd);

    let startDate, endDate;
    if (scale === 'years') {
      startDate = new Date(minDate.getFullYear(), 0, 1);
      endDate = new Date(maxDate.getFullYear() + 1, 0, 1);
    } else {
      startDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
      endDate = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, 0);
    }

    

    const x = d3.scaleTime()
      .domain([
        startDate,
        endDate
      ])
      .range([0, width]);

    const y = d3.scaleBand()
      .domain(validData.map(d => d.task_name))
      .range([0, height])
      .padding(0.55)
      .align(0.55);
      

    // Create custom tick values for months and weeks
    const monthTicks = d3.timeMonths(...x.domain());
    // const weekTicks = d3.timeWeeks(...x.domain());
    

    let dayTicks;
    if (scale === 'days') {
      dayTicks = d3.timeDays(...x.domain());
    }
    // Calculate the width of each day
   const dayWidth = width / d3.timeDays(startDate, endDate).length;

    const truncateText = (text, maxLength) => {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    };


    const yAxis = d3.axisLeft(y)
      .tickSize(0)
      .tickFormat(d => truncateText(d, 16)); // Adjust 20 to your desired maximum length

    svg.append("g")
      .attr("class", "y-axis")
      .attr("transform", `translate(0, 0)`)
      .call(yAxis)
      .selectAll(".tick text")
      .style("text-anchor", "end")
      .attr("dx", "-0.5em")
      .attr("dy", "0.3em");
    
    // Draw axes
  if (scale === 'days') {
    // Month labels
    svg.append("g")
      .attr("class", "x-axis-months")
      .attr("transform", `translate(0, ${-margin.bottom})`)
      .selectAll(".month-label")
      .data(monthTicks)
      .enter().append("text")
      .attr("class", "month-label")
      .attr("x", d => x(d) + (x(new Date(d.getFullYear(), d.getMonth() + 1, 1)) - x(d)) / 2)
      .attr("y", -margin.bottom)
      .style("text-anchor", "middle")
      .style("fill",chartColors.monthLabelColor)
      .text(d => d3.timeFormat("%B")(d));

    // Day labels
    svg.append("g")
    .attr("class", "x-axis-days")
    .attr("transform", `translate(0, 0)`)
    .selectAll(".day-label")
    .data(dayTicks)
    .enter().append("text")
    .attr("class", "day-label")
    .attr("x", d => x(d) + dayWidth / 2) // Center within the day's space
    .attr("y", -12)
    .style("text-anchor", "middle")
    .style("fill",chartColors.dayLabelColor)
    .text(d => d.getDate());

    // Add day separators
    svg.append("g")
      .attr("class", "day-separators")
      .selectAll("line")
      .data(dayTicks)
      .enter()
      .append("line")
      .attr("x1", d => x(d))
      .attr("x2", d => x(d))
      .attr("y1", -margin.top/2)
      .attr("y2", height + margin.bottom)
      .attr("stroke", "#606676")
      .attr("stroke-opacity", "0.2")
      .attr("stroke-width", 1);


    // Add grid lines
    svg.append("g")
      .attr("class", "grid-lines")
      .selectAll("line")
      .data(monthTicks)
      .enter()
      .append("line")
      .attr("x1", d => x(d))
      .attr("x2", d => x(d))
      .attr("y1", -margin.top)
      .attr("y2", height + margin.bottom)
      .attr("stroke", "#606676")
      .attr("stroke-opacity","0.8")
      .attr("stroke-width", 1)
  }
  else if(scale === "years"){
      const yearTicks = d3.timeYears(startDate, endDate);
      const monthTicks = d3.timeMonths(startDate, endDate);
      const yearLabelHeight = margin.top / 2;

      // Year labels
      svg.append("g")
        .attr("class", "x-axis-years")
        .attr("transform", `translate(0, ${-margin.bottom})`)
        .selectAll(".year-label")
        .data(yearTicks)
        .enter().append("text")
        .attr("class", "year-label")
        .attr("x", d => x(d) + (x(new Date(d.getFullYear() + 1, 0, 1)) - x(d)) / 2)
        .attr("y", -margin.bottom)
        .style("text-anchor", "start")
        .style("font-size", "14px")
        .style("font-weight", "bold")
        .style("fill",chartColors.yearLabelColor)
        .text(d => d.getFullYear());

      // Month labels (first letter)
      svg.append("g")
    .attr("class", "x-axis-months")
    .attr("transform", `translate(0, 0)`)
    .selectAll(".month-label")
    .data(monthTicks)
    .enter().append("text")
    .attr("class", "month-label")
    .attr("x", d => {
      const monthStart = x(d);
      const monthEnd = x(new Date(d.getFullYear(), d.getMonth() + 1, 0));
      return monthStart + (monthEnd - monthStart) / 2;
    })
    .attr("y",  -10)
    .style("text-anchor", "middle")
    .style("font-size", "16px")
    .style("fill",chartColors.monthLabelColor)
    .text(d => d3.timeFormat("%b")(d));

      // Add year separators
      svg.append("g")
        .attr("class", "year-separators")
        .selectAll("line")
        .data(yearTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", -margin.top)
        .attr("y2", height + margin.bottom)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", "0.8")
        .attr("stroke-width", 1);

      // Add month separators
      svg.append("g")
        .attr("class", "month-separators")
        .selectAll("line")
        .data(monthTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", -margin.top/2)
        .attr("y2", height + margin.bottom)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", "0.4")
        .attr("stroke-width", 1);

  }
  else{

    // Generate week tick positions for each month
    const weekTicks = monthTicks.flatMap(d => {
      const firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
      const nextMonth = new Date(d.getFullYear(), d.getMonth() + 1, 1);
      const daysInMonth = (nextMonth - firstDay) / (1000 * 60 * 60 * 24);
      const weekInterval = daysInMonth / 4;
      return d3.range(0, 4).map(i => new Date(firstDay.getTime() + (weekInterval * i) * (1000 * 60 * 60 * 24)));
    });

    // Create week labels data
    const weekLabels = weekTicks.map((d, i) => ({
      date: new Date(d.getTime() + (weekTicks[1] - weekTicks[0]) / 2),
      label: `${(i % 4) + 1}w`
    }));
    

    // Draw axes
    const xAxisMonths = d3.axisTop(x)
      .tickValues(monthTicks)
      .tickFormat(d3.timeFormat("%B"))
      .tickSize(0)

    const xAxisWeeks = d3.axisTop(x)
      .tickValues(weekTicks)
      .tickFormat((d, i) => `${(i % 4) + 1}w`)
      .tickSize(10);

    


    // Compute the width of each month interval
    const monthWidth = (width / monthTicks.length);

    // Add month labels to the chart
    svg.append("g")
    .attr("class", "x-axis-months")
    .attr("transform", `translate(0, ${-margin.bottom})`)
    .selectAll(".month-label")
    .data(monthTicks)
    .enter().append("text")
    .attr("class", "month-label")
    .attr("x", d => x(d) + monthWidth / 2) // Adjust x position to center labels
    .attr("y", -margin.bottom)
    .style("text-anchor", "middle") // Center the text itself
    .style("fill",chartColors.monthLabelColor)
    .text(d => d3.timeFormat("%B")(d)); 

      // Add week labels to the chart
    svg.append("g")
    .attr("class", "x-axis-weeks")
    .attr("transform", `translate(0, 0)`)
    .selectAll(".week-label")
    .data(weekLabels)
    .enter().append("text")
    .attr("class", "week-label")
    .attr("x", d => x(d.date))
    .attr("y", -12)
    .style("text-anchor", "middle")
    .style("fill",chartColors.weekLabelColor)
    .text(d => d.label);

    //week seperator
    svg.append("g")
      .attr("class", "week-separators")
      .selectAll("line")
      .data(weekTicks)
      .enter()
      .append("line")
      .attr("x1", d => x(d))
      .attr("x2", d => x(d))
      .attr("y1", -margin.top/2)
      .attr("y2", 0)
      .attr("stroke", "#606676")
      .attr("stroke-opacity","0.8")
      .attr("stroke-width", 1)

    // Add grid lines
    svg.append("g")
      .attr("class", "grid-lines")
      .selectAll("line")
      .data(monthTicks)
      .enter()
      .append("line")
      .attr("x1", d => x(d))
      .attr("x2", d => x(d))
      .attr("y1", -margin.top)
      .attr("y2", height + margin.bottom)
      .attr("stroke", "#606676")
      .attr("stroke-opacity","1")
      .attr("stroke-width", 1)

      
    }


    // Add horizontal line below header
    svg.append("line")
      .attr("x1", -margin.left)
      .attr("x2", width + margin.right)
      .attr("y1", 0)
      .attr("y2", 0)
      .attr("stroke", "#606676")
      .attr("stroke-opacity","1")
      .attr("stroke-width", 2);

    // Add horizontal line between months and weeks
    svg.append("line")
    .attr("x1", 0)
    .attr("x2", width + margin.right)
    .attr("y1", -margin.top/2)
    .attr("y2", -margin.top/2)
    .attr("stroke", "#606676")
      .attr("stroke-opacity","1")
    .attr("stroke-width", 2);

    // Add horizontal lines between tasks
    svg.selectAll(".task-separator")
      .data(validData.slice(0, -1)) // Exclude the last task
      .enter()
      .append("line")
      .attr("class", "task-separator")
      .attr("x1", -margin.left)
      .attr("x2", width + margin.right)
      .attr("y1", d => y(d.task_name) + y.bandwidth() + y.bandwidth()/2)
      .attr("y2", d => y(d.task_name) + y.bandwidth() + y.bandwidth()/2)
      .attr("stroke", "#606676")
      .attr("stroke-opacity","0.4")
      .attr("stroke-width", 1);

    // Add "Task Name" header
    svg.append("text")
      .attr("x", -( margin.left)/2 - margin.right)
      .attr("y", -margin.top / 2)
      .attr("font-weight", "bold")
      .text("Task Name")
      .style("fill","white")

    // Create tooltip
    const tooltip = d3
    .select(chartRef.current)
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('pointer-events','none')
    .style('opacity', 0);


    // Draw bars
    const taskGroups = svg.selectAll(".task")
    .data(validData)
    .enter()
    .append("g")
    .attr("class", "task")
    .attr("transform", d => `translate(0, ${y(d.task_name)})`)
  
  const barHeight = y.bandwidth() / 2; // Adjust this value to control the height of the bars and the gap
  
  taskGroups.append("rect")
  .attr("class", "planned")
  .attr("x", d => x(d.plannedStart))
  .attr("y", 0)
  .attr("width", d => Math.max(0, x(d.plannedEnd) - x(d.plannedStart)))
  .attr("height", barHeight)
  .attr("fill", "royalblue")
  .on("click", function (event, d) {
    event.stopPropagation();
    showTooltipOnEvent(this, event, d);
  })
  // .on("mouseenter", function (event, d) {
  //   showTooltipOnEvent(this, event, d);
  // });


  let minBarWidth ;// Set a minimum width for the bar
  if(scale === "days"){
    minBarWidth = 20;
  }else if(scale === "months"){
    minBarWidth = 6;
  }else{
    minBarWidth = 2; 
  }

  taskGroups.append("rect")
    .attr("class", "actual")
    .attr("x", d => d.actualStart ? x(d.actualStart) : x(d.plannedStart))
    .attr("y", barHeight + 2)
    .attr("width", d => {
      let width;
      if (d.actualStart && d.actualEnd) {
        width = x(d.actualEnd) - x(d.actualStart);
      } else if (d.actualStart) {
        width = x(d.plannedEnd) - x(d.actualStart);
      } else {
        width = x(d.plannedEnd) - x(d.plannedStart);
      }
      return Math.max(minBarWidth, width); // Ensure the width is at least the minimum width
    })
    .attr("height", barHeight)
    .attr("fill", d => {
      return getActualBarColors(d);
    })
    .on("click", function (event, d) {
      event.stopPropagation();
      showTooltipOnEvent(this, event, d);
    });
  // .on("mouseenter", function (event, d) {
  //   showTooltipOnEvent(this, event, d);
  // });

  function showTooltipOnEvent(element, event, d) {
    hideTooltip();
    d3.select(element).attr('opacity', 0.6);

    const [mouseX, mouseY] = d3.pointer(event);
    const barWidth = d.actualEnd ? x(d.actualEnd) - x(d.actualStart) : x(d.plannedEnd) - x(d.plannedStart);
    const tooltipX = x(d.plannedStart) + barWidth / 2;
    const tooltipY = y(d.task_name) + barHeight * 2 + 2;

    showTooltip(d, tooltipX, tooltipY);
  }


  function showTooltip(d, tooltipX, tooltipY) {

    // Create the dropdown options
    const assignedToOptions = teamData && teamData.length > 0
    ? [
        `<option value="" disabled selected>None</option>`,
        ...teamData.map(user => 
          `<option value="${user.invitee_id}" data-role="${user.invitee_role}" data-name="${user.invitee_name}" ${d.assigned_to && d.assigned_to === user.invitee_id ? 'selected' : ''}>${user.invitee_name}</option>`
        )
      ].join('')
    : `<option value="" disabled selected>None</option>`;

    tooltip
    .attr('class', `${window.innerWidth > 700 ? 'tooltip' : 'tooltip tooltip-mobile'}`)
    .style('opacity', 1)
    .style('pointer-events','auto')
    .html(`
        <div style="text-align: right;"><span class="close-btn" style="cursor: pointer;">&times;</span></div>
        <p><strong>Task Name:</strong> ${d.task_name}</p>
        <p><strong>Planned Start Date:</strong> ${d3.timeFormat("%Y-%m-%d")(d.plannedStart)}</p>
        <p><strong>Planned End Date:</strong> ${d3.timeFormat("%Y-%m-%d")(d.plannedEnd)}</p>
        <p><strong>Actual Start Date:</strong> ${d.actualStart ? d3.timeFormat("%Y-%m-%d")(d.actualStart) : 'N/A'}</p>
        <p><strong>Actual End Date:</strong> ${d.actualEnd ? d3.timeFormat("%Y-%m-%d")(d.actualEnd) : 'N/A'}</p>
        <p><strong>Assigned To:</strong>
          <select id="assigned-to-dropdown">
            ${assignedToOptions}
          </select>
          <button id="save-assigned-to" style="display:none;">Save</button>
        </p>
        <p><strong>Status:</strong>
          <select id="status-dropdown">
            <option value="Not Started" ${d.status === 'Not Started' ? 'selected' : ''}>Not Started</option>
            <option value="In Progress" ${d.status === 'In Progress' ? 'selected' : ''}>In Progress</option>
            <option value="Completed" ${d.status === 'Completed' ? 'selected' : ''}>Completed</option>
          </select>
          <button id="save-status" style="display:none;">Save</button>
        </p>
        <p><strong>Remarks:</strong></p>
        <div id="remarks-container"></div>
        <div>
          <input type="text" id="new-comment" placeholder="Add a comment...">
          <button id="add-comment">+</button>
        </div>
      `)
    .style('left', `${(window.innerWidth > 700 ? (tooltipX + margin.left) : (window.innerWidth/2))}px`)
    .style('top', `${tooltipY + (window.innerWidth > 700 ? (5 * margin.top) : (3 * margin.top))}px`)
    .style('transform', 'translate(-50%, -100%)');


    // Event listener to show the save button when the Assigned To dropdown value changes
    d3.select('#assigned-to-dropdown').on('change', function() {
      d3.select('#save-assigned-to').style('display', 'inline');
    });

    // Add event listener for the save button next to Assigned TO dropdown
    d3.select('#save-assigned-to').on('click', function() {
      const dropdown = d3.select('#assigned-to-dropdown').node();
      const selectedOption = dropdown.options[dropdown.selectedIndex];
      const assignedToId = selectedOption.value;
      const assignedToName = selectedOption.dataset.name;
      const assignedToRole = selectedOption.dataset.role;

      // Update the assignedTo in your data
      d.assigned_to = assignedToId;
      d.assignee_name = assignedToName;
      d.assignee_role = assignedToRole;

      onAssignedToSave(d);

      // Hide the save button after saving
      d3.select('#save-assigned-to').style('display', 'none');
    });

    // Event listener to show the save button when dropdown value changes
    d3.select('#status-dropdown').on('change', function() {
      d3.select('#save-status').style('display', 'inline');
    });

    // Add event listener for save button
    d3.select('#save-status').on('click', function() {
      const newStatus = d3.select('#status-dropdown').property('value');
      d.status = newStatus; // update the status in your data
      onStatusSave(d);

      // Hide the save button after saving
      d3.select('#save-status').style('display', 'none');
    });

    //fetch comments of task when clicked on bar
    const remarksContainer = tooltip.select('#remarks-container');
    getComments();
    async function getComments() {
        // Populate remarks
        const {data:comments} = await getTaskComments(d.id)
         console.log(comments)
        if (comments && comments.length > 0) {
          // First, create a map of comments by their IDs
        const commentMap = new Map(comments.map(comment => [comment.id, { ...comment, replies: [] }]));
        
        // Then, organize comments into a tree structure
        const rootComments = [];

        comments.forEach(comment => {
          const repliedToId = comment.replied_to_id === "000000000000000000000000" ? null : comment.replied_to_id;

          if (repliedToId) {
            const parentComment = commentMap.get(repliedToId);
            if (parentComment) {
              parentComment.replies.push(commentMap.get(comment.id));
            }
          } else {
            rootComments.push(commentMap.get(comment.id));
          }
        });

        // Function to recursively render comments
        function renderComments(comments, level = 0) {
          comments.forEach(comment => {
            remarksContainer.append('div')
              .attr('class', 'comment-div')
              .attr('data-comment-id', comment.id)
              .attr('data-replied-to-id', comment.replied_to_id || '')
              .style('margin-left', `${level * 20}px`)
              .html(`
                <p><strong>${comment.commenter_name}:</strong> <span class="comment-text">${comment.comment}</span></p>
                <div class="comment-options">
                  <button class="reply-comment">Reply</button>
                  <button class="edit-comment"><i class="icon icon-edit"></i>
                  <button class="delete-comment"><i class="icon icon-delete"></i></button>
                </div>
              `);
              

            // Recursively render replies
            if (comment.replies && comment.replies.length > 0) {
              renderComments(comment.replies, level + 1);
            }
          });
        }

        // Render the comment tree
        renderComments(rootComments);
        } else {
          remarksContainer.append('p').attr('class', 'no-comments').text('No comments yet.');
        }

        // Add event listeners for comment options
        addCommentOptionListeners();
    }
    // Function to add event listeners for comment options
    function addCommentOptionListeners() {
      remarksContainer.selectAll('.edit-comment').on('click', handleEditComment);
      remarksContainer.selectAll('.reply-comment').on('click', handleReplyComment);
      remarksContainer.selectAll('.delete-comment').on('click', handleDeleteComment);
    }

    // Handle edit comment
    function handleEditComment(event, d) {
      const commentDiv = d3.select(this.closest('.comment-div'));
      const commentText = commentDiv.select('.comment-text');
      const originalText = commentText.text();
      console.log(originalText,"originalText")
      // Replace comment text with an input field
      commentText.html(`<input type="text" class="edit-comment-input" value="${originalText}">`);

      const currentReplyDiv = commentDiv.select('.reply-comment');
      currentReplyDiv.style('display','none');
      
      // Add save button
      commentDiv.select('.comment-options').append('button')
        .attr('class', 'save-edit-comment')
        .text('Save')
        .on('click', async function() {
          const newText = commentDiv.select('.edit-comment-input').property('value');
          const commentId = commentDiv.attr('data-comment-id');
          const repliedToId = commentDiv.attr('data-replied-to-id') || null;
          const newRepliedToId = repliedToId === "000000000000000000000000" ? null : repliedToId;
          try {
            // Call backend API to update comment
            const commentUpdateObj = {
              id : commentId,
              comment: newText,
              replied_to_id:newRepliedToId
            }
            console.log("commented obj",commentUpdateObj)
            const response  = await updateComment(commentUpdateObj);
            console.log(response)

            // Update frontend
            currentReplyDiv.style('display','inline');
            commentText.html(newText);
            d3.select(this).remove(); // Remove save button
          } catch (error) {
            console.error('Error updating comment:', error);
          }
        });
    }

    // Handle reply comment
    function handleReplyComment() {
      const commentDiv = d3.select(this.closest('.comment-div'));
      
      // Add reply input and button
      commentDiv.append('div')
        .attr('class', 'reply-div')
        .html(`
          <input type="text" class="reply-input" placeholder="Type your reply...">
          <button class="post-reply">Post Reply</button>
        `);

      // Add event listener for post reply button
      commentDiv.select('.post-reply').on('click', async function() {
        const replyText = commentDiv.select('.reply-input').property('value');
        const parentCommentId = commentDiv.attr('data-comment-id');

        try {
          // Call backend API to post reply
          const newComment = {
            task_id: d.id, 
            commenter_id: userData.id, 
            commenter_name: userData.FullName, 
            comment: replyText,
            replied_to_id:parentCommentId
          };
            console.log(newComment)
            const response = await saveComment(newComment);
            console.log(response)
            if (!response.success) {
              throw new Error('Failed to post comment');
            }

          // Update frontend
          if (response.success) {
            commentDiv.append('div')
              .attr('class', 'reply-comment')
              .html(`<p><strong>${userData.FullName}:</strong><span> ${replyText}</span></p>`);
            
            commentDiv.select('.reply-div').remove();
          }
        } catch (error) {
          console.error('Error posting reply:', error);
        }
      });
    }

    // Handle delete comment
    async function handleDeleteComment(event, d) {
      const commentDiv = d3.select(this.closest('.comment-div'));
      const commentId = commentDiv.attr('data-comment-id');

        try {
          console.log(commentId)
          // Call backend API to delete comment
          const response = await deleteComment(commentId);
          console.log(response)

          // Remove comment from frontend
          commentDiv.remove();
        } catch (error) {
          console.error('Error deleting comment:', error);
        }
      
    }

    
    // Add comment functionality
    tooltip.select('#add-comment').on('click', async function() {
      const newCommentInput = tooltip.select('#new-comment');
      const newCommentText = newCommentInput.property('value');
      
      if (newCommentText) {
        const newComment = {
          task_id: d.id, 
          commenter_id: userData.id, 
          commenter_name: userData.FullName, 
          comment: newCommentText,
        };
    
        try {
          console.log(newComment)
          const response = await saveComment(newComment);
          console.log(response)
          if (!response.success) {
            throw new Error('Failed to post comment');
          }
    
          // If successful, update the frontend
          tooltip.select('.no-comments').remove();
    
          // Add the new comment to the display
          remarksContainer.append('div')
          .attr('class', 'comment-div')
          .attr('data-comment-id', response.commentId) // Assuming the backend returns the new comment's ID
          .html(`
            <p><strong>${newComment.commenter_name}:</strong> <span class="comment-text">${newComment.comment}</span></p>
            <div class="comment-options">
              <button class="reply-comment">Reply</button>  
              <button class="edit-comment"><i class="icon icon-edit"></i></button>
              <button class="delete-comment"><i class="icon icon-delete"></i></button>
            </div>
          `);
          
    
          // Clear the input
          newCommentInput.property('value', '');
    
          // Update your data structure
          if (!d.comments) d.comments = [];
          d.comments.push(newComment);

          // Add event listeners for the new comment's options
          addCommentOptionListeners();
          
        } catch (error) {
          console.error('Error posting comment:', error);
        }
      }
    });
  
    // Add close button functionality
    tooltip.select('.close-btn').on('click', function() {
      hideTooltip();
    });
  }


  // Prevent tooltip from disappearing when clicked
  tooltip.on("click", function(event) {
    event.stopPropagation();
  });

  // Add a click event listener to the document to hide the tooltip
  d3.select(document).on("click", function() {
    hideTooltip();
  });

  function hideTooltip(){
    tooltip
        .style('opacity', 0)
        .style('pointer-events', 'none');
    taskGroups.selectAll("rect").attr('opacity', 1);
  }

    // Add border to the entire chart
    svg.append("rect")
      .attr("x", -margin.left)
      .attr("y", -margin.top)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.bottom + margin.top)
      .attr("fill", "none")
      .attr("stroke", "#606676")
      .attr("stroke-opacity","1")
      .attr("stroke-width", 4)

    // Adjust font sizes based on screen width
    const baseFontSize = Math.max(16, Math.min(16, width / 100));
    svg.selectAll("text")
      .style("font-size", `${baseFontSize}px`);

    svg.selectAll(".month-label, .year-label")
      .style("font-size", `${baseFontSize}px`);

    // console.log("Chart drawing completed");
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scrolling speed
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div
      ref={scrollContainerRef}
      style={{ width: '100%', height: '100%', overflow: 'auto' }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <div ref={chartRef} style={{ width: `${dimensions.width}px`, height: `${dimensions.height}px` }}></div>
    </div>
  );
};

export default GanttChart;