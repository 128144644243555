import { PlusOutlined } from "@ant-design/icons";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { WaxContext } from 'wax-prosemirror-core';
import { DOMParser, Fragment, DOMSerializer } from 'prosemirror-model';
import { ReplaceStep, ReplaceAroundStep } from 'prosemirror-transform';
import { Selection } from 'prosemirror-state';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { cloneDeep } from "lodash";
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
//  import { findChildren } from 'prosemirror-utils'; // or use another utility method


/**
 * 
 * @param {*} string 
 * @returns 
 */
const elementFromString = string => {
    const wrappedValue = `<body><h2>${string}</h2></body>`;
    console.log(new window.DOMParser().parseFromString(wrappedValue, 'text/html').body)
    let data = document.getElementsByClassName("ProseMirror")
    console.log(data)
    return new window.DOMParser().parseFromString(wrappedValue, 'text/html').body;
};

/**
 * 
 * @param {*} tr 
 * @param {*} startLen 
 * @param {*} bias 
 * @returns 
 */
const selectionToInsertionEnd = (tr, startLen, bias) => {
    const last = tr.steps.length - 1;
    console.log(tr, startLen, bias)
    if (last && last < startLen) {
        return;
    }

    const step = tr.steps[last];

    if (!(step instanceof ReplaceStep || step instanceof ReplaceAroundStep)) {
        return;
    }

    const map = tr.mapping.maps[last];
    let end = 0;

    map.forEach((_from, _to, _newFrom, newTo) => {
        if (end === 0) {
            end = newTo;
        }
    });
    tr.setSelection(Selection.near(tr.doc.resolve(end), bias));
};


const OutlineArea = (props) => {
    const {
        app,
        pmViews: { main },
        activeViewId,
        activeView,
        placeholderPlugin
    } = useContext(WaxContext);

    const [currentWordCount, setCurrentWordCount] = useState(0);
    const [stateData, setStateData] = useState(main.state.doc.content);
    const [inputData, setInputData] = useState(false)
    const [newHead, setNewHead] = useState("");
    const [mainData, setMainData] = useState([]);
    const [cloneData, setCloneData] = useState([]);
    useEffect(() => {
        console.log(main.state.doc.content.content)
        // main.state.doc.content?.content.filter(items => items.type.name == "heading2").map((item, i) => {
        //     console.log(item.content.content[0].text)
        // })
        let mainArray = [];
        main.state && main.state.doc.content.content.filter(items => items.type.name == "heading2").map((item, index) => {
            item.id = (index + 1).toString()
            mainArray.push(item)
        })
        console.log(mainArray)
        setMainData(mainArray)

    }, [activeView.state.selection]);


    const [selectItem, SetselectItem] = useState(1);

    const addSection = () => {
        setInputData(!inputData)
    }
    const selectItemAction = (id) => {
        SetselectItem(id)
    }


    /**
     * update the data to doc
     * convert string into Node as per the ediotor schema
     * parse existing data and updated with new parsed data
     */
    const updateData = () => {
        const { state } = activeView;
        // Replace the selection with a placeholder
        const { tr } = state;
        if (!tr.selection.empty) tr.deleteSelection();

        const parser = DOMParser.fromSchema(
            main.state.config.schema,
        );

        const parsedContent = parser.parse(
            elementFromString(newHead.replace(/^\s+|\s+$/g, '')),
            {
                preserveWhitespace: 'full'
            },
        );

        const newTr = main.state.tr;
        console.log(newTr && newTr.doc?.content.size)
        newTr
            .replaceWith(newTr && newTr.doc?.content.size, newTr && newTr.doc?.content.size, parsedContent);

        selectionToInsertionEnd(newTr, newTr.steps.length - 1, 1);

        main.dispatch(newTr);
        setNewHead("")
        setInputData(false)

    }

    /**
     * onchange text update state
     */
    const onHandleChange = (event) => {
        console.log(event.target.value)
        setNewHead(event.target.value)
    }
    useEffect(() => {

    }, [inputData, mainData])


    /**
     * 
     * @param {*} result 
     * @returns 
     */
    const handleOnDragEnd = (result) => {
        let cloneDoc = cloneDeep(main.state.doc.content)
        setCloneData(cloneDoc)

        if (!result.destination) return;
        reArrangeData(result)
        const reorderedItems = Array.from(mainData);
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        //console.log(reorderedItems,movedItem,result.destination.index)
        reorderedItems.splice(result.destination.index, 0, movedItem);
        //console.log(reorderedItems)
        setMainData(reorderedItems);
        UpdatedDocStructure(cloneDoc, reorderedItems);

    };


    /**
      * update the document structure with new rearranged data
      */
    const UpdatedDocStructure = (oldData, NewData) => {
        let contentArray = [];
        NewData.map((item, i) => {
            // console.log(item.content?.content[0].text)
            let FirstIndex = oldData.content.findIndex(firstItem => firstItem.type.name == "heading2" && firstItem.content?.content[0].text == item.content?.content[0].text)
            console.log("First--->", FirstIndex)
            let NextIndex = oldData.content.slice(FirstIndex + 1) && oldData.content.slice(FirstIndex + 1).filter((secondItem, i) => secondItem.type.name == "heading2")
            // console.log("Second--->",NextIndex[0].content.content[0].text)
            let FindNext = NextIndex.length > 0 ? oldData.content.findIndex((itemNext, i) => itemNext.type.name == "heading2" && itemNext.content?.content[0].text == (NextIndex && NextIndex[0] && NextIndex[0].content?.content[0].text)) : FirstIndex;
            console.log(FindNext)
            if (NextIndex == -1) {
                console.log(FirstIndex, FindNext)
                let slicedData = oldData.content.slice(FirstIndex);
                console.log("===>", slicedData)
                slicedData.map(itemsData => {
                    contentArray.push(itemsData)
                })
            } else {
                console.log(FirstIndex, FindNext)
                let slicedData = [];
                if (FirstIndex == FindNext) {
                    slicedData = oldData.content.slice(FirstIndex);
                } else {
                    slicedData = oldData.content.slice(FirstIndex, FindNext);
                }
                slicedData.map(itemsData => {
                    contentArray.push(itemsData)
                })

                console.log("**>", slicedData)
            }

        })
        const fragment = Fragment.fromArray(contentArray);
        const jsonContent = fragment.toJSON();

        updateEditorContent(jsonContent)
    }

    // update the editor with new data
    const updateEditorContent = (newDocJSONData) => {
        let newDocJSON = { "type": "doc", "content": newDocJSONData }
        const newDoc = main.state.config.schema.nodeFromJSON(newDocJSON);

        // Create a transaction to replace the document
        const transaction = activeView.state.tr.replaceWith(
            0,
            activeView.state.doc.content.size,
            newDoc.content
        );

        scrollToText(activeView, "here")

        // Dispatch the transaction
        activeView.dispatch(transaction);
    };

    /**
     * move data and update the main document object
     */
    const reArrangeData = (data) => {
        console.log(mainData)
    }

    useEffect(() => {

    }, [mainData])

    /**
     * on click of outline text scroll to sectoin in editor
     */
    function scrollToText(view, searchText) {
        const { state } = view;
        const { doc } = state;

        let found = false;

        doc.descendants((node, pos) => {
            console.log(node)
            if (node.isText && node.text.includes(searchText)) {
                const startPos = pos + node.text.indexOf(searchText);
                const nodeDOM = view.nodeDOM(startPos);

                if (nodeDOM) {
                    // If the nodeDOM is a text node, get its parent element
                    const element = nodeDOM.nodeType === Node.TEXT_NODE ? nodeDOM.parentElement : nodeDOM;

                    if (element && typeof element.scrollIntoView === 'function') {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        found = true;
                    }
                }

                return false; // Stop traversal if the text is found
            }
            return true;
        });

        if (!found) {
            console.log("Text not found in the document");
        }
    }


    return (
        <div className="leftside-container">
            <div className="tabs-container">
                <div className={selectItem == 1 ? "tab-active" : "tab"} onClick={() => selectItemAction(1)}>Outline</div>
                <div className={selectItem == 2 ? "tab-active" : "tab"} onClick={() => selectItemAction(2)}>Authors</div>
                <div className={selectItem == 3 ? "tab-active" : "tab"} onClick={() => selectItemAction(3)}>Keywords</div>
            </div>
            {/* <div className="content-container">
                {
                    main.state.doc.content?.content.filter(items => items.type.name == "heading2").map((item, i) => {
                        return <div className="head-outline" onClick={() => alert("Introduction")}>
                            <PlusOutlined />
                            <span style={{ marginLeft: "5px", marginTop: "2px" }}>{item.content.content.map(data => data.text)}</span>
                        </div>
                    })
                }
            </div> */}
            <div style={{ padding: 0, overflow: "auto", height: "calc(100vh - 180px)" }}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <ul {...provided.droppableProps} ref={provided.innerRef} style={{ padding: 0 }}>
                                {mainData.map((item, index) => (
                                    <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                                        {(provided) => (
                                            <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    userSelect: 'none',
                                                    padding: '16px',
                                                    margin: '4px 0',
                                                    backgroundColor: '#f4f4f4',
                                                    border: '1px solid #ccc',
                                                    listStyleType: 'none',
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className="head-outline" onClick={() => scrollToText(activeView, item.content.content.map(data => data.text))}>
                                                    {/* <PlusOutlined /> */}
                                                    <span style={{ marginLeft: "5px", marginTop: "2px" }}>{item.content.content.map(data => data.text)}</span>
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>

                                ))}

                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
                <li
                    style={{
                        userSelect: 'none',
                        padding: '16px',
                        margin: '-15px 0px 0px 0px',
                        backgroundColor: '#f4f4f4',
                        border: '1px solid #ccc',
                        listStyleType: 'none',
                    }}
                >
                    <div className="head-outline" onClick={() => addSection()}>
                        <PlusOutlined />
                        <span style={{ marginLeft: "5px", marginTop: "2px" }}>Add Section</span>
                    </div>
                </li>
                {
                    inputData &&
                    <div className="new-section-container" >
                        <input style={{ margin: "10px 5px" }} value={newHead} placeholder="Enter New Section" onChange={(e) => onHandleChange(e)} />
                        <input style={{ margin: "10px 5px" }} type="submit" value={"Add"} onClick={() => updateData()} />
                    </div>
                }
            </div>
        </div>
    )

    // return OutlineData;
}

export default OutlineArea;