import { Col, Row } from "reactstrap";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { CustomButton } from "../../widgets";
import { getVariable } from "./apiController";
import { update } from "lodash";
import { AutoComplete } from "antd";
import MonacoEditor from "./CodeEditor";
import CodeEditor from "./CodeEditor";
import MyEditor from "./CodeEditor";
import MonacoEditorWrapper from "./CodeEditor";

export default function FormulaEditor(props) {

    const initialValues = useMemo(() => (
        { formula: "" }
    ), []);

    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem({}, initialValues, { onValueChange });
    const [variableData, SetvariableData] = useState<any>([]);
    const [formulaId, SetformulaId] = useState<any>("");

    let ComputeOperators = [
        { value: ">", label: ">" },
        { value: "<", label: "<" },
        { value: "==", label: "==" },
        { value: ">=", label: ">=" },
        { value: "<=", label: "<=" },
        { value: "!=", label: "!=" },
        { value: "contains", label: "contains" },
        { value: "&&", label: "&&" },
        { value: "||", label: "||" },
        { value: "and", label: "and" },
        { value: "or", label: "or" },
        { value: "not", label: "not" },
        { value: "in", label: "in" },
        { value: "+", label: "+" },
        { value: "-", label: "-" },
        { value: "*", label: "*" },
        { value: "/", label: "/" },
        { value: "%", label: "%" },
        { value: "(", label: "(" },
        { value: ")", label: ")" },
        { value: "[", label: "[" },
        { value: "]", label: "]" },
        { value: "**1/2", label: "sqrt" },
        { value: "**2", label: "square" },
        { value: "**3", label: "cube" },
        { value: "**", label: "power" },
        { value: "reset", label: "reset" }
    ]

    ComputeOperators = [...ComputeOperators, ...props.variables]

    useEffect(() => {
        console.log(props)
    }, [variableData])

    /**
     * get variable data for selected variable 
     * @param id 
     */
    const getVariableData = async (id, key) => {
        try {
            let response = await getVariable(id);
            if (!key) {
                let fieldValues: any = [];
                response.field_value.map((item, i) => {
                    let Obj = {
                        label: item,
                        value: item
                    }
                    fieldValues.push(Obj)
                })
                console.log(fieldValues)
                SetvariableData(fieldValues)

                // variableList.filter(item => item.key == key).map((items) => {
                //     items.variablelist = fieldValues
                // })
            } else {
                SetvariableData(response.field_value)
                console.log(response.field_value)
            }
        } catch (e: any) {

        }
    }

    /**
   * handle on change values of form elements
   * @param name 
   * @param value 
   * @param values 
   * @param fieldValues 
   * @returns 
   */
    function onValueChange(name, value, values, fieldValues = null) {
        const resetValue = {}
        if (name == "variable") {
            getVariableData(value.value, "")
            SetformulaId(formulaId + ("$$" + value.value + "$$"))
            props.getFormulaData(formulaId + ("$$" + value.value + "$$"), values['formula'] + value.label);
            values.formula += value.label + " "
        }

        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    /**
     * onsave clicked create the formaula and send data
     * also checking the length of code and tempcode created
     * trigger to getFormuladata function from props
     */
    const onSave = () => {
        let formulaData = "";
        let tempCodeData = tempCode;
        //check if length is not equal get the las element of code state
        if (code.length != tempCode.length) {
            let codes = code.split(" ");
            tempCodeData += codes[codes.length - 1]
        }
        //append $$ for create formula with id
        tempCodeData.split("$$").map(item => {
            let filterData = props.variables && props.variables.filter(items => items.label == item.trim());
            if (filterData.length > 0) {
                formulaData += "$$" + filterData[0].id + "$$ "
                console.log(filterData && filterData[0], formulaId)
            } else {
                formulaData += item + " "
                console.log(formulaId)
            }
        })
        SetformulaId(formulaData)
        props.getFormulaData(formulaData, code)
        console.log(tempCode)
    }

    /**
     * on click operators add values to formula 
     */
    const updateFormula = (value) => {
        if (value != "reset") {
            let prevValue = values.formula + value + " ";
            SetformulaId(formulaId + value)
            props.getFormulaData(formulaId + value, values['formula'] + value)
            updateValue({ formula: prevValue })
            console.log(formulaId)
        } else {
            updateValue({ formula: "" })
            SetformulaId("")
        }
    }

    const [code, setCode] = useState<string>('');
    const [tempCode, settempCode] = useState<string>('');

    /**
     * as user start writing formula update the state with update formual
     * @param newCode 
     * on new code add special character if special character
     * Add space before and after the special characters
     *
     */
    let data = "";
    const handleCodeChange = (newCode: string, valueCode: string) => {
        setCode(newCode);
        if (valueCode.length != 0) {
            let specialCharExist = ComputeOperators.filter(items => items.value == valueCode.trim())
            if (specialCharExist.length > 0) {
                data += valueCode + "$$";
                //console.log(data)
            } else {
                if (valueCode == " " && valueCode.length != 0) {
                    let newValue = newCode.split(" ");
                    let lengthValue = newValue.length;
                    data += newValue[lengthValue - 2] + "$$"
                } else {
                    let newValue = newCode.split(" ");
                    let lengthValue = newValue.length;
                    // data +=  newValue[lengthValue-2]+"$$";
                }
            }

            settempCode(data);
            //settempCode(tempCode+valueCode+"$$")
            let filterData = newCode.split(" ");
            let specialCharacter = ComputeOperators.filter(items => items.value == filterData[filterData.length - 1] || items.value == newCode[newCode.length-1])
            if (specialCharacter.length > 0) {
                //check if already space exist after last character
                if (specialCharacter[0].value.length == 1 && newCode[newCode.length-2] != " ") {
                    setCode(newCode.replace(/(?=.{1}$)/, ' ') + ' ');
                }else if(specialCharacter[0].value.length == 2 && newCode[newCode.length-1] != " "){
                    setCode(newCode.replace(/(?=.{1}$)/, ' ') + ' ');
                } else {
                    setCode(newCode + ' ');
                }
            } else {
                if (valueCode.length == 0) {
                    setCode(newCode);
                } else {
                    setCode(newCode);
                }
            }
        } else {

        }
        console.log(newCode)
    };

    return (
        <>
            <div className="mt-2 mr-2 ml-2 formula-list">
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: "5px" }}>
                    <CloseOutlined style={{ color: "red" }} onClick={() => { props.removeComponent(props.values.key); props.getFormulaData("", "") }} />
                </div>
                <div className="expression">
                    <Row>
                        <Col md={12}>
                            {/* <FieldItem
                                name={"formula"}
                                value={values['formula']}
                                onChange={(...e) => handleChange("formula", ...e)}
                                type={FIELD_TYPES.TEXT_AREA}
                                isDisabled={false}
                            /> */}
                            <MonacoEditorWrapper
                                language="typescript"
                                suggest={ComputeOperators}
                                value={code}
                                onChange={handleCodeChange}
                            />
                            {/* <div className="data" style={{border:"1px solid #ccc",margin:"5px"}}>
                            <AutoComplete
                                style={{ width: "20%", padding:"5px 0px", border:"1px solid white"}}
                                options={props.variables}
                            />
                            <AutoComplete
                                style={{ width: "100px", padding:"5px 0px"}}
                                options={ComputeOperators}
                            />
                             <AutoComplete
                                style={{ width: "100px", padding:"5px 0px"}}
                                options={ComputeOperators}
                            />
                            </div> */}
                        </Col>
                        {/* <Col md={1}>
                            <CloseOutlined style={{ color: "red" }} onClick={() => { props.removeComponent(props.values.key); props.getFormulaData("", "") }} />
                        </Col> */}
                    </Row>
                    <CustomButton type="primary" className='save-button' text="Save" onClick={() => onSave()}></CustomButton>
                    {/* <Row>
                        <Col md={4}>
                            <FieldItem
                                name={"variable"}
                                value={values['variable']}
                                values={props.variables}
                                onChange={(...e) => handleChange("variable", ...e)}
                                type={FIELD_TYPES.DROP_DOWN}
                                label="Variable"
                            />

                            <Row>
                                <Col md={10}>
                                    <FieldItem
                                        name={"fieldvalue"}
                                        value={values['fieldvalue']}
                                        values={variableData}
                                        onChange={(...e) => handleChange("fieldvalue", ...e)}
                                        type={FIELD_TYPES.TEXT_AUTOCOMPLETE}
                                        placeholder="Values"
                                        label="Value"
                                    />
                                </Col>
                                <Col md={2}>
                                    <div className="add-value"
                                        onClick={() => { updateFormula(values.fieldvalue); updateValue({ fieldvalue: "" }) }}>Add</div>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={8}>
                            <div className="operators">
                                {
                                    ComputeOperators.map(item => {
                                        return <CustomButton text={item.label} onClick={(() => updateFormula(item.value))} />
                                    })
                                }
                            </div>
                        </Col>
                    </Row> */}

                </div>
            </div>
        </>
    )
}