import React, { useEffect, useState } from 'react'
import GanttChart from '../common/charts/GanttChart'
import { getProjectTeamList, saveComment, updateTask } from './apiController';
import { useDispatch } from "react-redux";
import { setLoading } from '../../store/slices/auth';


const ViewGanttChart = (props) => {
  const [scale, setScale] = useState<string>("");
  const [teamData, setTeamData] = useState<any>([]);
  const dispatch = useDispatch();
  function getScale(){
    const dates = props.formData.tasks.flatMap(task => {
        const plannedStartDate = new Date(task.planned_start_date).getTime();
        const plannedEndDate = new Date(task.planned_end_date).getTime();
        const actualStartDate = task.actual_start_date ? new Date(task.actual_start_date).getTime() : null;
        const actualEndDate = task.actual_end_date ? new Date(task.actual_end_date).getTime() : null;

        return [plannedStartDate, plannedEndDate, actualStartDate, actualEndDate].filter(date => date !== null)
    });

    if (dates.length === 0) {
        console.error('No valid dates found');
        return null;
    }

    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));

    const timeDiff = Math.abs(maxDate.getTime() - minDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    
    if (diffDays <= 30) {
      // Check if minDate and maxDate are in the same month and year
      const sameMonthAndYear = minDate.getFullYear() === maxDate.getFullYear() && minDate.getMonth() === maxDate.getMonth();
        
      if (sameMonthAndYear) {
        setScale('days');
      } else {
        setScale('months');
      }
      
    } else if (diffDays <= 300) {
      setScale('months');
    } else {
      setScale('years');
    }

    // console.log(diffDays)
    // console.log(scale)
}

  async function getTeamData(){
    try {
      dispatch(setLoading(true));
      let data = await getProjectTeamList(props.projectData?.id);
      console.log(props)
      setTeamData(data.users);
      console.log(data);
      dispatch(setLoading(false));
    } catch (e:any) {
      dispatch(setLoading(false));
    }
  }

  useEffect(() => {
    getScale();
    getTeamData();
    console.log(props.formData)
  }, [props.formData.tasks])
  

  async function onStatusSave(data){
    let {status} = data;
    // console.log(data)
    try {
      dispatch(setLoading(true));
      if(status === "In Progress"){
        let taskUpdateObj = {
              "id": data.id,
              "task_name": data.task_name,
              "task_desc": data.task_desc,
              "planned_duration": Number(data.planned_duration),
              "planned_duration_unit": data.planned_duration_unit,
              "planned_start_date": data.planned_start_date,
              "planned_end_date": data.planned_end_date,
              "status":data.status,
              "actual_start_date": new Date().toISOString(),
              "assigned_to":data.assigned_to ? data.assigned_to : null,
              "assignee_name":data.assignee_name ? data.assignee_name : null       
        };
  
        let taskResponse = await updateTask(taskUpdateObj);
      }else if(status === "Completed"){
        if(!data.actual_start_date) return;
        let taskUpdateObj = {
          "id": data.id,
              "task_name": data.task_name,
              "task_desc": data.task_desc,
              "planned_duration": Number(data.planned_duration),
              "planned_duration_unit": data.planned_duration_unit,
              "planned_start_date": data.planned_start_date,
              "planned_end_date": data.planned_end_date,
              "status":data.status,
              "actual_start_date": data.actual_start_date,
              "actual_end_date": new Date().toISOString(),
              "assigned_to":data.assigned_to ? data.assigned_to : null,
              "assignee_name":data.assignee_name ? data.assignee_name : null  
        };
  
        let taskResponse = await updateTask(taskUpdateObj);
      }else{
        let taskUpdateObj = {
          "id": data.id,
              "task_name": data.task_name,
              "task_desc": data.task_desc,
              "planned_duration": Number(data.planned_duration),
              "planned_duration_unit": data.planned_duration_unit,
              "planned_start_date": data.planned_start_date,
              "planned_end_date": data.planned_end_date,
              "status":data.status,
              "actual_start_date": null,
              "actual_end_date": null,
              "assigned_to":data.assigned_to ? data.assigned_to : null,
              "assignee_name":data.assignee_name ? data.assignee_name : null  
        };
        let taskResponse = await updateTask(taskUpdateObj);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
    
    
    
  }

  async function onAssignedToSave(data){
    // console.log(data)
    try {
      dispatch(setLoading(true));
        let taskUpdateObj = {
              "id": data.id,
              "task_name": data.task_name,
              "task_desc": data.task_desc,
              "planned_duration": Number(data.planned_duration),
              "planned_duration_unit": data.planned_duration_unit,
              "planned_start_date": data.planned_start_date,
              "planned_end_date": data.planned_end_date,
              "status":data.status ? data.status : null,
              "actual_start_date": data.actual_start_date ? data.actual_start_date : null,
              "actual_end_date": data.actual_end_date ? data.actual_end_date : null,
              "assigned_to":data.assigned_to ? data.assigned_to : null,
              "assignee_name":data.assignee_name ? data.assignee_name : null
        };
  
        let taskResponse = await updateTask(taskUpdateObj);
        console.log(taskResponse)
      dispatch(setLoading(false));
    } catch (e:any) {
      dispatch(setLoading(false));
    }
  }
    
  function getActualBarColors(data){
    // console.log(data);
    const green = "#4E9F3D";
    const yellow = "#F6C90E";
    const red = "#D21312";
    const lightGray = "#D3D3D3";
    const darkGray = "#A9A9A9";

    const {status,plannedStart,plannedEnd,actualStart,actualEnd} = data;
    const today = new Date();

    // Calculate the total duration between the planned start and end dates
    const totalDuration = plannedEnd - plannedStart;

    // Calculate 20% of the total duration
    const delayThreshold = totalDuration * 0.2;

    // Calculate the date for 20% of the total duration from the planned start date
    const delayFromStartDate = new Date(plannedStart.getTime() + delayThreshold);
    // Calculate the date for 20% of the total duration from the planned end date
    const delayFromEndDate = new Date(plannedEnd.getTime() + delayThreshold);

    if(!status || status === "Not Started"){
      if(today <= plannedStart){
        return green;
      }
      else if(today > plannedStart && today <= delayFromStartDate){
        return yellow;
      }
      else{
        return red;
      }
    }else if(status && status === "In Progress"){
      if(actualStart <= plannedStart){
        return green;
      }
      else if(actualStart > plannedStart && actualStart <= delayFromStartDate){
        return yellow;
      }
      else if(actualStart > plannedStart && actualStart > delayFromStartDate){
        return red;
      }
      return green;
    }else if(status && status === "Completed"){
      if(actualEnd <= plannedEnd){
        return green;
      }else if(actualEnd > plannedEnd && actualEnd <= delayFromEndDate){
        return lightGray;
      }else if(actualEnd > plannedEnd && actualEnd > delayFromEndDate){
        return darkGray;
      }
      return green;
    }
    return green;

  }

  console.log(props)
  return (
    <div className='viewganttchart-container'>
      <div className='viewganttchart-header'>
        <div className='header-text'>Gantt Chart</div>
        <div onClick={()=>props.onClose()}>
          <i className='icon icon-close' onClick={() => props.onClose()} />
        </div>
      </div>
      {/* <div className='viewganttchart-body'> */}
        <div className='ganttchart' style={{
          marginTop : `${window.innerWidth > 700 ? '115px' : '100px'}`
        }}>
          <GanttChart data={props.formData.tasks} userData={props.userData} scale={scale} onStatusSave={onStatusSave} teamData={teamData} onAssignedToSave={onAssignedToSave} getActualBarColors={getActualBarColors}/>
        </div>
      {/* </div> */}
    </div>
  )
}

export default ViewGanttChart