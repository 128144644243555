import { Col, Row } from "reactstrap";
import { CustomButton } from "../../widgets";
import ModalComponent from "../common/Modal/modal";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { CreateDocumentElements } from "./DocumentElements";
import { FieldItem } from "../../widgets/fields";
import { useEffect, useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { getRoles } from "../dataCollection/apiController";
import { getProjectTeamList } from "../people/apiController";
import { createTemplate, getTemplatesList } from "./apiController";
import { useLocation } from "react-router-dom";

const CreateDocument = (props) => {
     const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(CreateDocumentElements, {}, { onValueChange });
     const [permissions, setpermissions] = useState<any>([{ id: 1, role: null, users: [], user: {}, accessType: [] }]);
     const [roles, setroles] = useState<any | []>([]);
     const [templates, setTemplates] = useState<any>([]);
     const dispatch = useDispatch();
     const location = useLocation();
     let prevDataCount = Object.keys(props.prevData || {})

     useEffect(() => {
          console.log(props)
          fetchRoles(0);
          fetchInitialData();
          if (prevDataCount.length > 0) {
               updateValue({ name: props.prevData.name, docdescription: props.prevData.desc })
          }
     }, [])
     /**
      * list of roles
      * @param index 
      */
     const fetchRoles = async (index) => {
          try {
               dispatch(setLoading(true));
               let tempRoles = await getRoles();
               if (tempRoles && tempRoles.data && tempRoles.data.length > 0) {
                    tempRoles.data.forEach((obj, index) => {
                         obj.label = obj.name;
                         obj.value = obj.name.toLowerCase();
                         obj.index = index;
                         obj.users = [];
                    });
                    if (props.isEdit) {
                         let temp: any = [];
                         props.formData?.form_role_access?.forEach((roleObj) => {
                              let tempPermissions = tempRoles.data.filter((obj) => obj.value === roleObj.role_name);
                              temp.push({ role: tempPermissions[0], users: [], user: '', accessType: roleObj.permissions });
                         });
                         if (props.formData?.form_user_access && props.formData?.form_user_access.length > 0) {
                              console.log(props.formData?.form_user_access)
                              props.formData?.form_user_access?.forEach(async (userObj, userIndex) => {
                                   let tempPermissions = tempRoles.data.filter((obj) => obj.value === userObj.role_name);
                                   userObj.label = userObj.email;
                                   userObj.value = userObj.user_number;
                                   let roleIndex = tempRoles?.data.findIndex((obj) => obj.value === userObj.role_name);
                                   if (roleIndex >= 0) {
                                        try {
                                             dispatch(setLoading(true));
                                             let projectid: any = { projectid: props.projectData.id };
                                             //let tempUsers = await getUsers(props.projectData.TopicCode || props.projectData.TopicID, roleData[roleIndex].label.toLowerCase());
                                             let tempUsers = await getProjectTeamList(projectid, 1, 100, "", "accepted", userObj.role_name);

                                             //let tempUsers = await getUsers(props.projectData.TopicCode || props.projectData.TopicID, userObj.role_name);
                                             if (tempUsers?.data && tempUsers.data.length > 0) {
                                                  tempUsers.data.forEach((obj) => {
                                                       obj.label = obj.emailid;
                                                       obj.value = obj.usernumber;
                                                  });
                                                  tempRoles.data[roleIndex].users = tempUsers.data;
                                                  userObj.permissions.filter(item => item.is_selected == true).map(permissionItem => {
                                                       let accessTypeValue = permissionItem.title == "read" ? "r" : permissionItem.title == "write" ? "w" : "f";
                                                       temp.push({
                                                            role: tempPermissions[0],
                                                            users: tempUsers.data,
                                                            user: userObj, accessType: accessTypeValue
                                                       });
                                                  })
                                             } else {
                                                  userObj.permissions.filter(item => item.is_selected == true).map(permissionItem => {
                                                       let accessTypeValue = permissionItem.title == "read" ? "r" : permissionItem.title == "write" ? "w" : "f";

                                                       temp.push({
                                                            role: tempPermissions[0],
                                                            users: [],
                                                            user: userObj, accessType: accessTypeValue
                                                       });
                                                  })
                                             }
                                             dispatch(setLoading(false));
                                        } catch (e: any) {
                                             userObj.permissions.filter(item => item.is_selected == true).map(permissionItem => {
                                                  let accessTypeValue = permissionItem.title == "read" ? "r" : permissionItem.title == "write" ? "w" : "f";

                                                  temp.push({
                                                       role: tempPermissions[0],
                                                       users: [],
                                                       user: userObj, accessType: accessTypeValue
                                                  });
                                             })
                                             dispatch(setLoading(false));
                                        }
                                   }
                                   if (userIndex + 1 === props.formData?.form_user_access.length) {
                                        if (temp.length === 0) {
                                             temp = [{ id: 1, role: null, users: [], user: '', accessType: [] }];
                                        }
                                        setpermissions(temp);
                                        setroles(tempRoles.data);
                                   }
                              });
                         } else {
                              if (temp.length === 0) {
                                   temp = [{ id: 1, role: null, users: [], user: '', accessType: [] }];
                              }
                              setpermissions(temp);
                              setroles(tempRoles.data);
                         }
                    } else {
                         if (tempRoles?.data && tempRoles.data.length > 0) {
                              let tempPermissions: any = cloneDeep(permissions);
                              tempPermissions[index] = { id: 1, role: null, users: [], user: '', accessType: [] };
                              fetchUsers(tempRoles.data, 0, tempPermissions, index);
                         }
                    }
               }
               dispatch(setLoading(false));
          } catch (e: any) {
               setroles([]);
               dispatch(setLoading(false));
          }

          console.log(permissions)
     }

     const fetchUsers = async (roleData, roleIndex, tempPermissions, perIndex) => {
          if (roleData[roleIndex].users.length === 0) {

               try {
                    dispatch(setLoading(true));

                    let projectid: any = { projectid: props.projectData.id };
                    console.log(roleData, roleIndex)

                    //let tempUsers = await getUsers(props.projectData.TopicCode || props.projectData.TopicID, roleData[roleIndex].label.toLowerCase());
                    let tempUsers = await getProjectTeamList(projectid, 1, 100, "", "accepted", roleData[roleIndex].label.toLowerCase());
                    if (tempUsers?.users && tempUsers.users.length > 0) {
                         tempUsers.users.forEach((obj) => {
                              obj.label = obj.invitee_name;
                              if (obj.invitee_role == "field-executive") {
                                   obj.value = obj.imei;
                              } else {
                                   obj.value = obj.invitee_id;
                              }
                         });
                         roleData[roleIndex].users = tempUsers.users;
                         tempPermissions[perIndex].users = tempUsers.users;
                    }
                    if (tempPermissions.length === 0) {
                         tempPermissions = [{ id: 1, role: null, users: [], user: '', accessType: [] }];
                    }
                    setpermissions(tempPermissions);
                    setroles(roleData);
                    dispatch(setLoading(false));
               } catch (e: any) {
                    setroles(roleData);
                    if (tempPermissions.length === 0) {
                         tempPermissions = [{ id: 1, role: null, users: [], user: '', accessType: [] }];
                    }
                    setpermissions(tempPermissions);
                    dispatch(setLoading(false));
               }
          } else {
               tempPermissions[perIndex].users = roleData[roleIndex].users;
               setroles(roleData);
               if (tempPermissions.length === 0) {
                    tempPermissions = [{ id: 1, role: null, users: [], user: '', accessType: [] }];
               }
               setpermissions(tempPermissions);
          }
     }


     /**
      * 
      * @param name 
      * @param value 
      * @param values 
      * @param fieldValues 
      * @returns 
      */
     function onValueChange(name, value, values, fieldValues = null) {
          let resetValue = {};
          console.log(values)
          //console.log(formTemplates)

          return [{
               ...values,
               ...resetValue,
               [name]: value,
          }]
     }

     /**
      * on change role update the list of users
      * @param name 
      * @param value 
      * @param index 
      */
     function changePermissions(name, value, index) {

          let temp: any = cloneDeep(permissions);

          // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")

          if (name === 'role') {
               temp[index].user = temp[index].user;
               temp[index].users = [];
               temp[index][name] = value;
               console.log(roles, value.index, temp, index)

               fetchUsers(roles, value.index, temp, index); //commented time being
          } else if (name === 'user') {
               let isUser = false;
               for (var i = 0; i < temp.length; i++) {
                    // console.log(temp)
                    console.log("-----------------------------------");
                    let obj = temp[i]
                    if (obj.user && (obj.user.invitee_id == value.invitee_id || (value.invitee_id && obj.user?.user_number == value.invitee_id) || obj.user?.imei == value.imei)) {
                         // console.log("*************")
                         // console.log(value)
                         console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
                         // console.log(obj.user)
                         // console.log("*************")
                         continue;
                    } else {
                         console.log("inside else loop");
                         temp[index][name] = value;
                    }

               }

               console.log(temp)
               setpermissions(temp);
          } else {
               temp[index][name] = value;
               setpermissions(temp);
          }
     }

     /**
      * remove permission from array
      */
     const removePermission = (id) => {
          setpermissions(permissions.filter(item => item.id != id))
     }

     /**
      * add more user for permissions
      */
     const addUser = () => {
          let temp: any = cloneDeep(permissions);
          temp.push({ id: temp[temp.length - 1].id + 1, role: null, users: [], user: '', accessType: [] });
          setpermissions(temp);
     }

     /**
      * get intial data for templates list
      */
     const fetchInitialData = () => {
          let response = getTemplatesList();

          response.then(data => {
               setTemplates(data.map(({
                    id: value,
                    name: label
               }) => ({
                    value,
                    label
               })));
          })
     }

     /**
      * on submit create a document
      */
     const onSubmit = () => {
          console.log(location.state)
          let projectData: any = location.state;
          let role_access: any = [];
          let user_access: any = [];
          permissions.forEach((obj: any) => {
               console.log(obj)
               if (obj.accessType.length > 0 && obj.role) {
                    if (obj.user && obj.user.value && obj.user.label && (obj.user.invitee_role || obj.user.role_name) && obj.accessType && obj.accessType.length > 0) {
                         user_access.push({
                              "user_id": obj.user.id,
                              "user_number": obj.user.invitee_id || obj.user.user_number,
                              "email": obj.user.label,
                              "role_name": obj.user.invitee_role || obj.user.role_name,
                              "permissions": [
                                   {
                                        "title": "read",
                                        "permission": "read",
                                        "is_selected": obj.accessType == "r" ? true : false
                                   },
                                   {
                                        "title": "write",
                                        "permission": "write",
                                        "is_selected": obj.accessType == "w" ? true : false
                                   },
                                   {
                                        "title": "forbidden",
                                        "permission": "forbidden",
                                        "is_selected": obj.accessType == "f" ? true : false
                                   }
                              ]
                         })
                    } else if (obj.role?.value && obj.accessType && obj.accessType.length > 0) {
                         role_access.push({
                              "role_name": obj.role.value,
                              "permissions": [
                                   {
                                        "title": "read",
                                        "permission": "read",
                                        "is_selected": obj.accessType == "r" ? true : false
                                   },
                                   {
                                        "title": "write",
                                        "permission": "write",
                                        "is_selected": obj.accessType == "w" ? true : false
                                   },
                                   {
                                        "title": "forbidden",
                                        "permission": "forbidden",
                                        "is_selected": obj.accessType == "f" ? true : false
                                   }
                              ]
                         })
                    }
               }
          });
          let Obj = {
               "topic_id": projectData.TopicID,
               "project_id": projectData.id,
               "template_id": values.templates?.value,
               "name": values.name,
               "desc": values.docdescription,
               role_access,
               user_access,
          }
          let submitResponse = createTemplate(Obj);
          props.getDocuments();
          props.onClose()

          console.log(Obj)
     }


     return (
          <>
               <ModalComponent
                    width={"70%"}
                    onClose={() => props.onClose()}
                    isOpen={true}
                    header={<b>{prevDataCount.length > 0 ? `Update Document` : `Create Document`}</b>}
                    centered={true}
                    customClass="document-modal"
                    body={
                         <div>
                              <Row>
                                   <Col md={4}>
                                        <FieldItem
                                             {...CreateDocumentElements.name}
                                             value={values.name}
                                             onChange={(...e) =>
                                                  handleChange(CreateDocumentElements.name.name, ...e)
                                             }
                                             touched={fields.name && fields.name.hasError}
                                             error={fields.name && fields.name.errorMsg}
                                        />
                                   </Col>
                                   <Col md={4}>
                                        <FieldItem
                                             {...CreateDocumentElements.docdescription}
                                             value={values.docdescription}
                                             onChange={(...e) =>
                                                  handleChange(CreateDocumentElements.docdescription.name, ...e)
                                             }
                                             touched={fields.name && fields.name.hasError}
                                             error={fields.name && fields.name.errorMsg}
                                        />
                                   </Col>
                                   {/* <Col md={4}>
                                        <FieldItem
                                             {...CreateDocumentElements.automanual}
                                             value={values.automanual}
                                             onChange={(...e) =>
                                                  handleChange(CreateDocumentElements.automanual.name, ...e)
                                             }
                                             touched={fields.automanual && fields.automanual.hasError}
                                             error={fields.automanual && fields.automanual.errorMsg}
                                        />
                                   </Col> */}

                                   <Col md={4}>
                                        <FieldItem
                                             {...CreateDocumentElements.templates}
                                             value={values.templates}
                                             onChange={(...e) =>
                                                  handleChange(CreateDocumentElements.templates.name, ...e)
                                             }
                                             values={templates}
                                             isDisabled={prevDataCount.length > 0 ? true : false}
                                             touched={fields.templates && fields.templates.hasError}
                                             error={fields.templates && fields.templates.errorMsg}
                                        />
                                   </Col>
                              </Row>
                              <div className='permissions-border'>
                                   <Row className='m-0'>
                                        <Col className='p-0'>
                                             <div className='permissions-text'>Permissions</div>
                                        </Col>
                                        <Col className='p-0'>
                                             <div className='user-text' onClick={() => addUser()}>Add User</div>
                                        </Col>
                                   </Row>
                              </div>
                              <div className="permission-container">
                                   {roles.length > 0 && (
                                        permissions.map((obj: any, index) => (

                                             <Row className='m-0 permissions-row' key={index}>
                                                  <Col md="3">
                                                       <FieldItem
                                                            {...CreateDocumentElements.role}
                                                            value={obj.role}
                                                            values={roles}
                                                            onChange={(...e) => changePermissions(CreateDocumentElements.role.name, ...e, index)}
                                                       />
                                                  </Col>
                                                  <Col md="4">
                                                       <FieldItem
                                                            {...CreateDocumentElements.user}
                                                            value={obj.user}
                                                            values={obj.users}
                                                            onChange={(...e) => changePermissions(CreateDocumentElements.user.name, ...e, index)}
                                                       />
                                                  </Col>
                                                  <Col md="4">
                                                       <FieldItem
                                                            {...CreateDocumentElements.accessType}
                                                            value={obj.accessType}
                                                            onChange={(...e) => changePermissions(CreateDocumentElements.accessType.name, ...e, index)}
                                                       />
                                                  </Col>
                                                  <Col md="1">
                                                       {permissions.length > 1 ?
                                                            <DeleteFilled style={{ color: 'red' }} onClick={() => removePermission(obj.id)} />
                                                            :
                                                            ""
                                                       }
                                                  </Col>
                                             </Row>
                                        )
                                        ))}
                              </div>
                         </div>
                    }
                    footer={
                         <div>
                              <CustomButton
                                   type="primary"
                                   isDisabled={false}
                                   text={props.selectedItem ? `Update` : `Create`}
                                   onClick={() => onSubmit()}
                              ></CustomButton>
                         </div>
                    }
               />
          </>
     )
}

export default CreateDocument;