import { clone, cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalPopup from "../../widgets/modal";
import { uploadPhoto } from "../profile/apiController";
import dayjs from 'dayjs';
import {
  fetchNextVariable,
  submitIdentifierForm,
  updateIdentifierForm,
  validateIdentifierForm,
} from "./apiController";
import "./IdentifierLevel.css";
import { DownloadOutlined } from "@ant-design/icons";
import { ModifyReasonModal } from "./ModifyReasonModal";

interface PropsData {
  formFieldsData?: FormFieldsObject;
  selectedValues?: any;
  recordId: any;
  onUpload(): void | any;
}
interface FormFieldsObject {
  form_fields?: Array<FieldObject>;
}
interface FieldObject {
  id: string;
  field_type: string;
  field_name: string;
  field_use_type: string;
  field_selected_value?: any;
  field_value?: Array<Object>;
}
export default function IdentifierLevel(props: PropsData) {
  const [values, fields, handleChange, { validateValues, reset, updateValue, updateError, removeErrors }] =
    useFieldItem({}, {}, { onValueChange });
  const [formData, setformData] = useState<any | null>(null);
  const [editIndex, seteditIndex] = useState<any | null>(null);
  const [isSubmit, setisSubmit] = useState(false);
  const [isSubmitted, setisSubmitted] = useState(false);
  const [sourceFormId, setsourceFormId] = useState("");
  const [errorMobile, SeterrorMobile] = useState(false);
  const [errorEmail, SeterrorEmail] = useState(false);
  const [errorRange, SeterrorRange] = useState(false);
  const [errorMandatory, SeterrorMandatory] = useState(false);
  const [errorInteger, SeterrorInteger] = useState(false);
  const [startDate, SetstartDate] = useState(new Date());  
  const [nextBtn, SetNextbtn] = useState("");
  var selectedParentIndex: any = null;
  var selectedChildIndex: any = null;
  const dispatch = useDispatch();
  const [isLocked, SetisLocked] = useState(false);

  /**
   * set version id
   */
  useEffect(() => {
    localStorage.setItem("versionid", Date.now().toString());
  }, [1])

  /**
   * get intial data
   */
  useEffect(() => {
    let temp: any = props.formFieldsData;
    setsourceFormId(temp?.form_fields[0][0]?.source_form_id);
    let isidentifier = false;
    if (temp?.form_fields[0][0]?.field_type === "dropDownPreviousResponses") {
      isidentifier = true;
    }
    //commented if condition on edit 
    if (props.selectedValues && isidentifier && temp.isPartial) {
      fetchFormData(temp);
    } else {
      temp.form_fields = [[]].concat(temp?.form_fields);
      setformData(temp);
    }
  }, []);



  /**
   * 
   * @param name 
   * @param value 
   * @param values 
   * @param fieldValues 
   * @returns 
   */
  function onValueChange(name, value, values, fieldValues = null) {
    let temp: any = cloneDeep(formData);
    temp.form_fields[selectedParentIndex][
      selectedChildIndex
    ].field_selected_value = value;
    setformData(temp);
    return [
      {
        ...values,
        [name]: value,
      },
    ];
  }

  /**
   * validate form data
   */
  async function onValidate() {
    try {
      dispatch(setLoading(true));
      let reqObj: any = [];
      let isEmpty = false;
      formData.form_fields[0].forEach((element) => {
        if (
          element.field_selected_value === undefined ||
          element.field_selected_value === ""
        ) {
          isEmpty = true;
          return;
        }
        reqObj.push({
          field_id: element.id,
          value:
            element.field_type === "dropDownPreviousResponses"
              ? element.field_selected_value.label
              : element.field_selected_value,
        });
      });
      if (isEmpty) {

        showToaster(toasterTypes.ERROR, "Please fill the form");
      } else {
        let response = await validateIdentifierForm(sourceFormId, reqObj);
        if (response.success) {
          fetchFormData();
        }
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  }

  /**
   * validate data and call next api
   */
  async function getFetchData(tempFormData: any = "") {
    try {
      dispatch(setLoading(true));
      let fieldId: any = "";
      let answer: any = "";
      let tempForm: any = cloneDeep(tempFormData || formData);
      let isEmpty = false;
      console.log(fieldId)

      fieldId = fieldId.substring(0, fieldId.length - 1);
      answer = answer.substring(0, answer.length - 1);
      let response = await fetchNextVariable(tempForm.id, fieldId, answer);
      if (response?.data == null) {
        setisSubmit(true);
      }
      if (response?.data != null && response.success) {
        response.data.forEach((obj) => {
          if (obj.field_type === "dropDownPreviousResponses") {
            let options: any = [];

            obj.field_value.forEach((elem) => {
              options.push({
                label: elem.value,
                value: elem.version_id,
              });
            });

            if (props.selectedValues && props.selectedValues.length > 0) {
              let tempValue = props.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              let tempVal = options.filter(
                (elem) => elem.label === tempValue[0].field_selected_value
              );
              if (tempVal.length > 0) {
                obj.field_selected_value = tempVal[0];
              }
            }
            obj.field_value = options;
          } else if (obj.field_type === "boolean") {
            obj.field_selected_value = false;
            if (props.selectedValues && props.selectedValues.length > 0) {
              let tempValue = props.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                obj.field_selected_value = tempValue[0].field_selected_value;
              }
            }
          } else if (
            obj.field_type === "dropDown" ||
            obj.field_type === "radioButton" ||
            obj.field_type === "checkBox"
          ) {
            let options: any = [];
            obj.field_value.forEach((elem) => {
              options.push({
                label: elem,
                value: elem,
              });
            });
            if (props.selectedValues && props.selectedValues.length > 0) {
              let tempValue = props.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                if (obj.field_type === "checkBox") {
                   //pushing to array if the values for checkbox is String
                   let selectedData = tempValue[0]?.field_selected_value;
                   if(typeof selectedData == "string"){
                     console.log("-------------",typeof selectedData)
                     let dataArray : any = [];
                     dataArray.push(tempValue[0]?.field_selected_value)
                     selectedData = dataArray;
                   }
                   selectedData.forEach((elem) => {
                    let tempVal = options.filter(
                      (subElem) => subElem.label === elem
                    );
                    if (tempVal.length === 1) {
                      if (
                        obj.field_selected_value &&
                        obj.field_selected_value.length > 0
                      ) {
                        obj.field_selected_value.push(elem);
                      } else {
                        obj.field_selected_value = [elem];
                      }
                    }
                  });
                } else {
                  let tempVal = options.filter(
                    (elem) => elem.label === tempValue[0].field_selected_value
                  );
                  if (tempVal.length === 1) {
                    if (obj.field_type === "dropDown") {
                      obj.field_selected_value = tempVal[0];
                    } else {
                      obj.field_selected_value = tempVal[0].value;
                    }
                  }
                }
              }
            }
            obj.field_value = options;
          } else if (obj.field_type === "fileUpload") {
            //obj.field_selected_value = false;
            if (props.selectedValues && props.selectedValues.length > 0) {
              let tempValue = props.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                obj.field_selected_value = tempValue[0].field_selected_value;
                console.log(tempValue[0].field_selected_value);
              }
            }
          } else {
            if (props.selectedValues && props.selectedValues.length > 0) {
              let tempValue = props.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                obj.field_selected_value =
                  obj.field_type === "dateField"
                    ? new Date(tempValue[0].field_selected_value)
                    : tempValue[0].field_selected_value;
              }
            }
          }
        });
        tempForm.form_fields.push(response.data);
        setformData(tempForm);
      }
    } catch (e: any) {
      if (e?.response?.data?.data === null) {
        setisSubmit(true);
      }
      dispatch(setLoading(false));
    }
  }
  /**
   * on click next action validate form data
   * if conditions come true set complete state to true for data submission
   */
  async function fetchFormData(tempFormData: any = "") {
    try {
      dispatch(setLoading(true));
      let fieldId: any = "";
      let answer: any = "";
      let tempForm: any = cloneDeep(tempFormData || formData);
      let isEmpty = false;
      console.log(tempForm)
      tempForm.form_fields.forEach((obj) => {
        obj.forEach((subObj, i) => {
          subObj?.validations && subObj?.validations.map((validitaionKey) => {
            console.log(subObj?.validations);
            //if(validitaionKey  == "FE-Mandatory"){
            if (subObj.field_selected_value !== undefined &&
              subObj.field_selected_value !== "") {
              if (subObj.field_type === "checkBox") {
                subObj.field_selected_value = Array.prototype.map
                  .call(subObj.field_selected_value || [], (s) => s)
                  .toString();
                subObj.field_selected_value =
                  subObj.field_selected_value.replaceAll("|", ",");
              }
              fieldId = fieldId + subObj.id + ",";
              console.log(subObj)

              answer =
                answer +
                (subObj.field_type === "dropDownPreviousResponses" || subObj.field_type === "dropDown"
                  ? subObj.field_selected_value.label
                  : subObj.field_selected_value) +
                ",";
              SetNextbtn("");
            } else {
              SetNextbtn("disabled");
              console.log("Empty error")
              updateError(i, { hasError: true, errorMsg: "Field cannot be empty" });
              isEmpty = true;
              return;
            }
            // }        
          })

          console.log(subObj.field_selected_value);

          if (subObj.field_selected_value == undefined) {
            SetNextbtn("");
            subObj.field_selected_value = "";
            if (subObj.field_type === "checkBox") {
              subObj.field_selected_value = Array.prototype.map
                .call(subObj.field_selected_value || [], (s) => s)
                .toString();
              subObj.field_selected_value =
                subObj.field_selected_value.replaceAll("|", ",");
                console.log(subObj.field_selected_value);
            }

            fieldId = fieldId + subObj.id + ",";
            answer =
              answer +
              (subObj.field_type === "dropDownPreviousResponses" || subObj.field_type === "dropDown"
                ? subObj.field_selected_value.label
                : subObj.field_selected_value) +
              ",";
          } else {
            if (!subObj?.validations) {
              fieldId = fieldId + subObj.id + ",";

              answer =
                answer +
                (subObj.field_type === "dropDownPreviousResponses" || subObj.field_type === "dropDown"
                  ? subObj.field_selected_value.label
                  : subObj.field_selected_value) +
                ",";
            }
          }



          // if (
          //   subObj.field_selected_value !== undefined &&
          //   subObj.field_selected_value !== ""
          // ) {


          // } else {

          //   isEmpty = true;
          //   return;
          // }
        });
        if (isEmpty) {
          return;
        }
      });

      console.log(isEmpty)

      if (isEmpty) {
        showToaster(toasterTypes.ERROR, "Please fill the form1");

      } else {
        isEmpty = false;
        console.log(fieldId)
        fieldId = fieldId.substring(0, fieldId.length - 1);
        answer = answer.substring(0, answer.length - 1);
        let response = await fetchNextVariable(tempForm.id, fieldId, answer);

        if (response?.data == null) {
          setisSubmit(true);
        }
        if(response?.data != null && response.success) {
          response.data.forEach((obj) => {
            console.log(response)
            console.log(obj.field_type)
            if (obj.field_type === "dropDownPreviousResponses") {
              let options: any = [];
              obj.field_value.forEach((elem) => {
                options.push({
                  label: elem.value,
                  value: elem.version_id,
                });
              });
              if (props.selectedValues && props.selectedValues.length > 0) {
                let tempValue = props.selectedValues.filter(
                  (elem) => elem.id === obj.id
                );

                //added if partial modify 
                console.log(tempValue)

                if (tempForm.isPartial && tempValue.length > 0 && tempValue[0].field_selected_value) {
                  obj.isPartial = true
                }
                let tempVal = options.filter(
                  (elem) => elem.label == tempValue[0].field_selected_value
                );
                console.log(tempVal)

                if (tempVal.length > 0) {
                  obj.field_selected_value = tempVal[0];
                }
              }
              obj.field_value = options;
            } else if (obj.field_type === "boolean") {

              obj.field_selected_value = false;
              if (props.selectedValues && props.selectedValues.length > 0) {
                let tempValue = props.selectedValues.filter(
                  (elem) => elem.id === obj.id
                );
                console.log(tempValue)

                if (tempForm.isPartial && tempValue.length > 0 && tempValue[0].field_selected_value) {
                  obj.isPartial = true
                }
                if (tempValue.length === 1) {
                  obj.field_selected_value = tempValue[0].field_selected_value;
                }
              }
            } else if (
              obj.field_type === "dropDown" ||
              obj.field_type === "radioButton" ||
              obj.field_type === "checkBox"
            ) {

              let options: any = [];
              obj.field_value.forEach((elem) => {
                options.push({
                  label: elem,
                  value: elem,
                });
              });
              if (props.selectedValues && props.selectedValues.length > 0) {
                let tempValue = props.selectedValues.filter(
                  (elem) => elem.id === obj.id
                );
                console.log("Dropdown,radio,check--->",tempValue[0])
                if (tempForm.isPartial && tempValue.length > 0 && tempValue[0].field_selected_value) {
                  obj.isPartial = true
                }
                if (tempValue.length === 1) {
                  if (obj.field_type === "checkBox") {
                    if(tempValue[0].field_selected_value && typeof tempValue[0].field_selected_value == "string"){
                      tempValue[0].field_selected_value = [tempValue[0].field_selected_value]
                    }
                    tempValue[0].field_selected_value.forEach((elem) => {
                      let tempVal = options.filter(
                        (subElem) => subElem.label === elem
                      );
                      if (tempVal.length === 1) {
                        if (
                          obj.field_selected_value &&
                          obj.field_selected_value.length > 0
                        ) {
                          obj.field_selected_value.push(elem);
                        } else {
                          obj.field_selected_value = [elem];
                        }
                      }
                    });
                  } else {
                    let tempVal = options.filter(
                      (elem) => elem.label === tempValue[0].field_selected_value
                    );
                    if (tempVal.length === 1) {
                      if (obj.field_type === "dropDown") {
                        obj.field_selected_value = tempVal[0];
                      } else {
                        obj.field_selected_value = tempVal[0].value;
                      }
                    }
                  }
                }
              }
              obj.field_value = options;
              console.log("Dropdown,radio,check--->",obj)

            } else if (obj.field_type === "fileUpload") {

              if (props.selectedValues && props.selectedValues.length > 0) {
                let tempValue = props.selectedValues.filter(
                  (elem) => elem.id === obj.id && elem.field_selected_value && elem.field_selected_value != ""
                );
                console.log(tempValue)

                if (tempForm.isPartial && tempValue.length > 0 && tempValue[0].field_selected_value) {
                  obj.isPartial = true
                }
                if (tempValue.length === 1) {
                  obj.field_selected_value = tempValue[0].field_selected_value;
                  console.log("FIrst", tempValue[0].field_selected_value);

                }
              }
            } else {

              if (props.selectedValues && props.selectedValues.length > 0) {
                let tempValue = props.selectedValues.filter(
                  (elem) => elem.id === obj.id
                );

                if (tempForm.isPartial && tempValue.length > 0 && tempValue[0].field_selected_value) {
                  obj.isPartial = true
                }

                console.log(tempValue)

                if (tempValue.length === 1) {
                  obj.field_selected_value =
                    obj.field_type === "dateField"
                      ? new Date(tempValue[0].field_selected_value)
                      : tempValue[0].field_selected_value;
                }
              }
            }
          });
          tempForm.form_fields.push(response.data);
          console.log(tempForm)
          setformData(tempForm);
        }

      }
      dispatch(setLoading(false));
    } catch (e: any) {
      if (e?.response?.data?.data === null) {
        setisSubmit(true);
      }
      dispatch(setLoading(false));
    }
  }

  const getArrayValues = () => {
    let temp: any = cloneDeep(formData);
    temp = temp.form_fields.splice(1);
    return temp;
  };

  const onEdit = () => {
    seteditIndex(null);
    SeterrorEmail(false);
    SeterrorMandatory(false);
    SeterrorMobile(false);
    SeterrorRange(false);
    SeterrorInteger(false);

    let temp: any = cloneDeep(formData);
    temp.form_fields = temp.form_fields.splice(0, editIndex + 1);
    setformData(temp);
    removeErrors(editIndex + 1)
    setformData(temp);


  };

  //Clear all error message on click of edit
  const clearError = (temp) => {
    temp.map((obj, i) => {
      obj.map((items, j) => {
        console.log(items, j)
        updateError(j, { hasError: false, errorMsg: "" });
        return
      })
    })
  }

  const uploadDoc = async (data, resolve, reject) => {
    try {
      dispatch(setLoading(true));
      let response = await uploadPhoto(data);
      if (response.mediaUrl) {
        resolve(response.mediaUrl);
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      reject("");
      dispatch(setLoading(false));
    }
  };
/**
 * create promise all function to upload images
 */
const uploadDocs = (formData) =>{
  return new Promise(async (resolve, reject) => {
    await uploadDoc(formData, resolve, reject);
  })
}

  /**
   * save data
   * @param reason 
   */
  const save = async (reason) => {
    try {
      dispatch(setLoading(true));
      let fieldsArray: any = [];
      let tempData: any = cloneDeep(formData.form_fields);
      console.log("Temp-----",tempData)
      let uploadArray : any =[];
      tempData.forEach((obj, index) => {
        
        obj.forEach(async (subObj, subIndex) => {
          if (subObj.field_type === "fileUpload") {
            if (typeof subObj?.field_selected_value != "string") {
              const formData = new FormData();
              formData.append("file", subObj?.field_selected_value && subObj?.field_selected_value[0]);
              // subObj?.field_selected_value && await new Promise(async (resolve, reject) => {
              //   await uploadDoc(formData, resolve, reject);
              // })
              //   .then((result: any) => {
              //     console.log("Image Link------>",result)
              //     subObj.field_selected_value = result;
              //   })
              //   .catch((error) => {
              //     subObj.field_selected_value = error;
              //   });
              subObj?.field_selected_value && uploadArray.push(uploadDocs(formData))
            }
          } else if (
            subObj.field_type === "dropDown" ||
            subObj.field_type === "dropDownPreviousResponses"
          ){
            console.log(subObj)
            subObj.field_selected_value = subObj.field_selected_value && subObj.field_selected_value.label;
          }
          fieldsArray.push({
            id: subObj.id || subObj.fieldId,
            field_selected_value: subObj.field_selected_value,
          });

      
          if (
            tempData.length === index + 1 &&
            tempData[index].length === subIndex + 1
          ) {
            let versionid: number = Number(localStorage.getItem("versionid"));
            let endDate = new Date();
            
            console.log('=======',uploadArray)
            //if all documents uploaded will submit form
            const Docresults = await Promise.all(uploadArray);
            if(Docresults){
            let i = 0;
            //update file paths
            obj.forEach((subImageId) =>{
              if (subImageId.field_type === "fileUpload") {
                let objIndex = fieldsArray.findIndex(obj => obj.id == subImageId.id || subImageId.fieldId);
                fieldsArray[objIndex].field_selected_value = Docresults[i]
                i++;
              }
            })
            console.log("FInalll-----",fieldsArray)
            
            //get time zone
            const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

            let reqObj: any = {
              form_id: formData.id,
              version_id: versionid,
              fields: fieldsArray,
              start_time: startDate,
              end_time: endDate,
              update_remarks:reason,
              time_zone:tz
            };
            let response;
            if (props.selectedValues) {
              reqObj.id = props.recordId;
                console.log(props?.formFieldsData && props?.formFieldsData["islocked"])
             
                response = await updateIdentifierForm(reqObj);
              
            } else {
              response = await submitIdentifierForm(reqObj);
            }
            if (response.success) {
              showToaster(toasterTypes.SUCCESS, response.message);
              setisSubmit(false);
              setisSubmitted(true);
              dispatch(setLoading(false));
            }
            }
          }
        });
      });
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  };

  /**
   * on blur validate fields data
   * @param obj 
   * @param index 
   */
  const checkFrontEndValidation = (obj, index) => {

    obj[index]?.validations &&
      obj[index]?.validations.map((valid) => {
        if (valid.startsWith('FE')) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const numberRegex = /^[1-9]\d{9}$/;

          if (valid == "FE-EmailFormat") {
            if (!emailRegex.test(obj[index]?.field_selected_value)) {
              SetNextbtn("disabled");
              SeterrorEmail(true);
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = true);
              obj.isError = true;
              updateError(index, { hasError: true, errorMsg: obj[index]?.field_selected_value == undefined || obj[index]?.field_selected_value == "" ? 'Email cannot be empty' : 'Please entry valid Email' });
              return;
            } else {
              obj.isError = false;
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = false);
              SetNextbtn("");
              SeterrorEmail(false);
              updateError(index, { hasError: false, errorMsg: "" });
              return
            }
          } else if (valid == "FE-MobileNumber") {
            if (!numberRegex.test(obj[index]?.field_selected_value)) {
              SetNextbtn("disabled");
              SeterrorMobile(true);
              obj.isError = true;
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = true);
              updateError(index, { hasError: true, errorMsg: obj[index].field_selected_value == undefined || obj[index].field_selected_value == "" ? 'Mobile cannot be empty' : 'Please entry valid Mobile' });
              return
            } else {
              SetNextbtn("");
              SeterrorMobile(false);
              obj.isError = false;
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = false);
              updateError(index, { hasError: false, errorMsg: "" });
              return
            }
          }else if (valid == "FE-Integer") {
            var result = (obj[index]?.field_selected_value - Math.floor(obj[index]?.field_selected_value)) !== 0; 

            if (result) {
              SetNextbtn("disabled");
              SeterrorInteger(true);
              obj.isError = true;
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = true);
              updateError(index, { hasError: true, errorMsg: 'Field cannot be decimal number' });
              return;
            } else {
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = false);
              SetNextbtn("");
              SeterrorInteger(false);
              obj.isError = false;
              updateError(index, { hasError: false, errorMsg: '' });
              return;
            }
          } else if (valid == "FE-Mandatory") {
            if (obj[index]?.field_selected_value?.length == 0 || obj[index].field_selected_value == 'undefined' || obj[index].field_selected_value == null) {
              SetNextbtn("disabled");
              SeterrorMandatory(true);
              obj.isError = true;
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = true);
              updateError(index, { hasError: true, errorMsg: 'Field cannot be empty' });
              return;
            } else {
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = false);
              SetNextbtn("");
              SeterrorMandatory(false);
              obj.isError = false;
              updateError(index, { hasError: false, errorMsg: '' });
              return;
            }
          } else if (valid == "FE-Range") {
            const min = obj[index].field_min_value;
            const max = obj[index].field_max_value;
            // Check the range
            if (parseInt(obj[index].field_selected_value) < parseInt(min) || parseInt(obj[index].field_selected_value) > parseInt(max)) {
              SetNextbtn("disabled");
              SeterrorRange(true);
              obj.isError = true;
              updateError(index, { hasError: true, errorMsg: `Values should be between ${min} to ${max}` });
              return;
            } else {
              SetNextbtn("");
              SeterrorRange(false);
              obj.isError = false;
              getArrayValues()[getArrayValues().length - 1].filter(items => items.id == obj[index].id).map(item => item.isError = false);
              updateError(index, { hasError: false, errorMsg: '' });
              return;
            }

          } else {

          }

        } else if (valid.startsWith('BE')) {
          let versionid: number = Number(localStorage.getItem("versionid"));

          const resObj = {
            field_id: obj[index].id,
            value: obj[index].field_selected_value,
            version_id: versionid
          }

          console.log(resObj);
          const response = validateIdentifierForm("", [resObj]);
          response.then(respData => {

          }).catch(err => {

            //updateError(index, {hasError:true, errorMsg: "Please enter valid data"});

          })
        } else {

        }
      })

    if (!obj[index]?.validations) {
      console.log("no");
    }
  }

  const getReason = (reason) => {
      console.log(reason)
      save(reason)
      SetisLocked(false)
  }

  const onClose = () =>{
    SetisLocked(false)
  }

  return (
    <div>
      {
        isLocked ? 
         <ModifyReasonModal getReasonData={getReason} isClose={() => onClose()}/>
        :
        ""
      }
      {isSubmitted ? (
        <div className="survey-form">
          <Row className="m-0 mb-3">
            <Col className="p-0" lg={6} md={12} xs={12}>
              <div className="welcome-text">Welcome</div>
              <div className="survey-title">{formData.form_name}</div>
              <div className="survey-description">{formData.form_desc}</div>
            </Col>
            <Col className="p-0" lg={6} md={12} xs={12}>
              <img
                className="survey-tool"
                src={`${process.env.PUBLIC_URL}/images/survey_tools.svg`}
                alt="logo"
              />
            </Col>
          </Row>
          <div className='questions-container row flex-column m-0 justify-content-center align-items-center pt-0'>
            <img src={`${process.env.PUBLIC_URL}/images/awesome.svg`} alt="awesome" className="awesome-img" />
            <div className='completed-text1'>Successfully submitted your form.</div>
          </div>
        </div>
      ) : (
        formData &&
        (formData.form_fields[0].length === 0 ||
          formData.form_fields[0][0].field_use_type.includes("Identifier")) && (
          <>
            {isSubmit && (
              <ModalPopup
                modalType="delete"
                modalText="There are no more actions as per the answers submitted."
                actionText="Do you want to submit the form?"
                closeModal={() => setisSubmit(false)}
                onAction={() =>{props?.formFieldsData && props?.formFieldsData["islocked"] ? SetisLocked(true) : save("")}}
              />
            )}
            {editIndex !== null && editIndex >= 0 && (
              <ModalPopup
                modalType="delete"
                modalText="Are you sure, you want to edit?"
                actionText="This will erase the data of next variables"
                closeModal={() => seteditIndex(null)}
                onAction={() => onEdit()}
              />
            )}
            {formData.form_fields[0].length > 0 &&
              formData.form_fields[0][0].field_type ===
              "dropDownPreviousResponses" && (
                <Row>
                  {formData.form_fields[0].map((obj, index) => (
                    <Col md={obj.field_type === "checkBox" ? 12 : 6} key={index}>
                      <FieldItem
                        name={obj.id}
                        placeholder={
                          obj.field_type === "number" ||
                            obj.field_type === "textBox" ||
                            obj.field_type === "textArea"
                            ? "Enter"
                            : "Select"
                        }
                        label={obj.field_name}
                        isDisabled={formData.form_fields.length > 1}
                        type={
                          obj.field_type === "fileUpload"
                            ? FIELD_TYPES.FILE_UPLOAD
                            : obj.field_type === "dateField"
                              ? FIELD_TYPES.DATEPICKER_NEW
                              : obj.field_type === "dropDown"
                                ? FIELD_TYPES.DROP_DOWN
                                : obj.field_type === "dropDownPreviousResponses"
                                  ? FIELD_TYPES.DROP_DOWN
                                  : obj.field_type === "boolean"
                                    ? FIELD_TYPES.BOOLEAN
                                    : obj.field_type === "radioButton"
                                      ? FIELD_TYPES.RADIO_BUTTON
                                      : obj.field_type === "checkBox"
                                        ? FIELD_TYPES.CHECK_BOX
                                        : obj.field_type === "textArea"
                                          ? FIELD_TYPES.TEXT_AREA
                                          : obj.field_type === "textBox"
                                            ? FIELD_TYPES.TEXT
                                            : obj.field_type === "time"
                                              ? FIELD_TYPES.TIME_PICKER
                                              : obj.field_type === "dateTime"
                                                ? FIELD_TYPES.DATE_TIME_PICKER
                                                : FIELD_TYPES.TEXT
                        }
                        isNumber={obj.field_type === "number" ? true : false}
                        value={obj.field_type == "dateField" ? obj.field_selected_value ? dayjs(obj.field_selected_value) : null : obj.field_selected_value}
                        values={obj.field_value}
                        isListed={true}
                        isMandatory={false}
                        onChange={(...e) => {
                          selectedParentIndex = 0;
                          selectedChildIndex = index;
                          handleChange(obj.id, ...e);
                        }}
                      />
                      {obj.field_type === "fileUpload" && typeof (obj.field_selected_value && obj.field_selected_value) != "object" ?
                        <span style={{ fontSize: "12px", paddingBottom: "10px" }}>{obj.field_selected_value && obj.field_selected_value.substring(obj.field_selected_value.lastIndexOf('/') + 1)}
                          <a href={obj.field_selected_value} download><DownloadOutlined style={{ fontSize: "20px", padding: "0px 5px" }} /> </a>
                        </span>
                        : ""}
                    </Col>
                  ))}
                  <Col
                    xs={12}
                    className="w-100 d-flex m-0 mt-4 mb-4 justify-content-end"
                  >
                    {formData.form_fields.length === 1 && (
                      <CustomButton
                        type="primary"
                        onClick={() => onValidate()}
                        text="Fetch"
                      ></CustomButton>
                    )}
                  </Col>
                </Row>
              )}
            {formData.form_fields.length > 1 &&
              !props.selectedValues &&
              formData.form_fields[0].length !== 0 && (
                <div className="edit-border">
                  <div className="edit-box">
                    <div className="edit-text" onClick={() => seteditIndex(0)}>
                      Edit
                    </div>
                  </div>
                </div>
              )}
            {formData.form_fields.length > 1 &&
              getArrayValues().map((obj, index) => (
                <Row key={index}>
                  {console.log("---->",obj)}
                  {obj.map((subObj, subIndex) => (
                    <Col md={obj.field_type === "checkBox" ? 12 : 6} key={subIndex}>

                      <FieldItem
                        className={'dynamic-item'}
                        name={subObj.id}
                        placeholder={
                          subObj.field_type === "number" ||
                            subObj.field_type === "textBox" ||
                            subObj.field_type === "textArea"
                            ? "Enter"
                            : "Select"
                        }
                        label={subObj.field_name}
                        isDisabled={subObj.isPartial ? true : index + 2 !== formData.form_fields.length}
                        type={
                          subObj.field_type === "fileUpload"
                            ? FIELD_TYPES.FILE_UPLOAD
                            : subObj.field_type === "dateField"
                              ? FIELD_TYPES.DATEPICKER_NEW
                              : subObj.field_type === "dropDown"
                                ? FIELD_TYPES.DROP_DOWN
                                : subObj.field_type === "dropDownPreviousResponses"
                                  ? FIELD_TYPES.DROP_DOWN
                                  : subObj.field_type === "boolean"
                                    ? FIELD_TYPES.BOOLEAN
                                    : subObj.field_type === "radioButton"
                                      ? FIELD_TYPES.RADIO_BUTTON
                                      : subObj.field_type === "checkBox"
                                        ? FIELD_TYPES.CHECK_BOX
                                        : subObj.field_type === "textArea"
                                          ? FIELD_TYPES.TEXT_AREA
                                          : subObj.field_type === "textBox"
                                            ? FIELD_TYPES.TEXT
                                            : subObj.field_type === "time"
                                              ? FIELD_TYPES.TIME_PICKER
                                              : subObj.field_type === "dateTime"
                                                ? FIELD_TYPES.DATE_TIME_PICKER
                                                : FIELD_TYPES.TEXT
                        }
                        isNumber={subObj.field_type === "number" ? true : false}
                        value={subObj.field_type == "dateField" ? subObj.field_selected_value ? dayjs(subObj.field_selected_value) : null : subObj.field_selected_value}
                        values={subObj.field_value}
                        isListed={true}
                        onChange={(...e) => {
                          selectedParentIndex = index + 1;
                          selectedChildIndex = subIndex;
                          handleChange(subObj.id, ...e);
                        }}
                        touched={(getArrayValues().length == index + 1) ? fields[subIndex] && fields[subIndex]?.hasError : ""}
                        error={(getArrayValues().length == index + 1) ? fields[subIndex]?.errorMsg : ""}
                        onBlur={() => (getArrayValues().length == index + 1) ? checkFrontEndValidation(obj, subIndex) : ""}

                      />
                      {subObj.field_type === "fileUpload" && typeof (subObj.field_selected_value && subObj.field_selected_value) != "object" ?
                        <span style={{ fontSize: "12px", paddingBottom: "10px" }}>{subObj.field_selected_value && subObj.field_selected_value.substring(subObj.field_selected_value.lastIndexOf('/') + 1)}
                          <a href={subObj.field_selected_value} download><DownloadOutlined style={{ fontSize: "20px", padding: "0px 5px" }} /> </a>
                        </span>
                        : ""}
                    </Col>
                  ))}
                  {index + 2 !== formData.form_fields.length && (
                    <Col xs={12}>
                      {formData.form_fields.length > 1 && (
                        <div className="edit-border">
                          <div className="edit-box">
                            <div
                              className="edit-text"
                              onClick={() => seteditIndex(index + 1)}
                            >
                              Edit
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                  )}
                </Row>
              ))}
            <Row className="footer-card">
              <Col className="p-0">
                <CustomButton
                  type="alert-secondary"
                  onClick={() => props.onUpload()}
                  text="Upload"
                ></CustomButton>
              </Col>
              <Col className="p-0 d-flex m-0 justify-content-end">
                <CustomButton
                  isDisabled={errorEmail == true || errorMandatory == true || errorMobile == true || errorRange == true || errorInteger == true ? "disabled" : ""}
                  type="primary"
                  onClick={() => fetchFormData()}
                  text="Next"
                ></CustomButton>
              </Col>
            </Row>
          </>
        )
      )}
    </div>
  );
}
