import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { uploadChartAsImage } from '../../Editor/utils/UploadToServer';
import { downloadChartAsImage } from './downloadChartAsImage';
import SectionModal from '../../Editor/utils/SectionModal';

const HorizontalClusterBarChart = ({containerid,barstyle,data,bardatastyle,title,titlecolor,width,height,yaxislabelstyle,xaxislabelstyle,gridcolor,gridstatus,yaxisstyle,xaxisstyle,xaxistext,yaxistext}) => {
  const svgRef = useRef(null);
  const [hide, SetHide] = useState(bardatastyle.visible)
  const [showGridLines, setShowGridLines] = useState(true);
  const [ylabel,setylabel] = useState(yaxistext)
  const [xlabel,setxlabel] = useState(xaxistext)
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [actionPush, setactionPush] = useState('');

  /**
  * function call to Push code to editor
  */
  const addToEditor = (data) => {
      console.log(data)
      uploadChartAsImage(data,containerid)
      setactionPush("")
  }
  useEffect(() => {
    if (!data || data.length === 0) return;

    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('width', width )
      .attr('height', height)
      .append('g')
      .style("overflow", "visible")
      .attr('transform', `translate(${width / 8},${height / 8})`);
    ;

    // Remove previous elements
    svg.selectAll('*').remove();

    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const y0 = d3.scaleBand()
      .domain(data.map(d => d.group))
      .rangeRound([0, height])
      .paddingInner(0.1);

    const y1 = d3.scaleBand()
      .domain(data[0].values.map(d => d.name))
      .rangeRound([0, y0.bandwidth()])
      .padding(0.05);

    const x = d3.scaleLinear()
      .domain([0, d3.max(data, d => d3.max(d.values, d => d.value))+10])
      .range([0, width]);

    const color = d3.scaleOrdinal()
      .range(d3.schemeCategory10);

      svg
      .append('g')
      .attr('class', 'grid')
      .style('color', gridcolor)
      .attr('transform', `translate(2,${height})`)
      .style("stroke-dasharray", "5 5")
      .style("visibility", showGridLines  ? "visible" : "hidden")
      .call(d3.axisBottom(x).tickSize(-height).tickFormat(''))


    const xAxis = d3.axisBottom(x);

    const yAxis = d3.axisLeft(y0);

    svg.attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    svg.append('g')
      .selectAll('g')
      .data(data)
      .enter().append('g')
      .attr('transform', d => `translate(0,${y0(d.group)})`)
      .selectAll('rect')
      .data(d => d.values)
      .enter().append('rect')
      .attr('y', d => y1(d.name))
      .attr('x', 0)
      .attr('height', y1.bandwidth())
      .attr('width', d => x(d.value))
      .attr('fill', d => color(d.name));

    // Add text labels beside bars
    svg.append('g')
      .selectAll('g')
      .data(data)
      .enter().append('g')
      .attr('transform', d => `translate(0,${y0(d.group)})`)
      .selectAll('text')
      .data(d => d.values)
      .enter().append('text')
      .attr('x', d => x(d.value) + 5) // Adjust the horizontal position
      .attr('y', d => y1(d.name) + y1.bandwidth() / 2)
      .text(d => d.value)
      .attr('font-size', '12px')
      .attr('fill', 'black');

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis);

    svg.append('g')
      .attr('class', 'y-axis')
      .call(yAxis);

       //add x axis label
    svg.append('text')
    .attr("class", "x label")
    .attr("text-anchor", "middle")
    .attr("x", width / 3)
    .attr("y", height - 2)
    .attr("dy", xaxisstyle.dy)
    .attr("dx", width / 8 - margin.left)
    .style('fill', xaxisstyle.color)
    .style('font-weight', xaxisstyle.fontweight)
    .style('font-size', xaxisstyle.fontsize)
    .text(xlabel) 
    .on("click",() =>{
      let label = prompt("Edit X Axis Label")
        if(label){
        setxlabel(label)
        }
    });

  //add y axis label
  svg.append("text")
    .attr("class", "y label")
    .attr('transform', `translate(20)`)
    .attr("text-anchor", "middle")
    .attr("y", -margin.left - margin.left)
    .attr("dy", "3em")
    .attr("x", -height / 2)
    .attr("transform", "rotate(-90)")
    .style('fill', yaxisstyle.color)
    .style('font-weight', yaxisstyle.fontweight)
    .style('font-size', yaxisstyle.fontsize)
    .text(ylabel)
    .on("click",() =>{
      let label = prompt("Edit Y Axis Label")
      if(label){
        setylabel(label)
        }
    });


     // Create legends
     const legend = svg
     .selectAll('.legend')
     .data(color.domain())
     .enter()
     .append('g')
     .attr('class', 'legend')
     .attr('transform', (d, i) => `translate(0,${i * 20})`)
     .on('click', toggleLegend);



   legend
     .append('rect')
     .attr('x', width-margin.top)
     //.attr('y',height)
     .attr('width', 18)
     .attr('height', 18)
     .style('fill', color);

   legend
     .append('text')
     .attr('x', width)
     //.attr('y',height)
     .attr('dy','0.70em')
     .style('text-anchor', 'start')
     .text((d) => d);


        // Function to toggle legend visibility
    function toggleLegend(d) {
      const groupName = d.target.__data__;
      const selectedLegend = svg.selectAll('.legend').filter((legendName) => legendName === groupName);

      // Toggle legend
      selectedLegend.classed('disabled', !selectedLegend.classed('disabled'));

      // Toggle corresponding bars visibility
      const bars = svg.selectAll('rect').filter((d) => d.name === groupName);

      if (selectedLegend.classed('disabled')) {
        bars.style('display', 'none');
        SetHide('hidden')
      } else {
        SetHide('visible')
        bars.style('display', null); // Setting to null restores the default display property
      }
    }

    return () => {
      d3.select(svgRef.current).selectAll('*').remove();
    };


  }, [data, width, height,selectedCategories,showGridLines]);

  return (
    <>
    <div className="controller">
        <Button className='grid' onClick={() => setShowGridLines(!showGridLines)}>
            {showGridLines ? 'Hide Grid Lines' : 'Show Grid Lines'}
        </Button>
        {
                actionPush == "section" &&
                <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
            }
            
           
            <Button className='grid' onClick={() => downloadChartAsImage(containerid)}>
                Download
            </Button>
            <Button className='grid' onClick={() => setactionPush("section")}>
            Copy to Ediotor
            </Button>
    </div>  
    <div ref={svgRef} id={containerid}></div>

</>
  );
};

export default HorizontalClusterBarChart;
