import { Service } from 'wax-prosemirror-core';
import ColorMark from './schema/ColorMark';
import TextColorTool from './TextColorTool';

export default class TextColorService extends Service {
  register() {
    this.container.bind('TextColorTool').to(TextColorTool);
    const createMark = this.container.get('CreateMark');
    createMark(
      {
        colorText: ColorMark,
      },
      { toWaxSchema: true },
    );
  }

}
