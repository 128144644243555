import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Input, Label, Row } from "reactstrap";
// widgets
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
// fields
import { FieldItem } from "../../widgets/fields";
import { FormElements } from "./FormElements";
import useFieldItem from "../../widgets/fields/UseFieldItem";
// redux
import { useSelector, useDispatch } from 'react-redux';
import { login, authSelector, setLoading, setAuthFailed } from '../../store/slices/auth';
// components
import OTPComponent from "../common/components/login/otp.component";
// api
import { send_otp, create_profile, getInviteUserInfo } from './apiController';
import ModalComponent from "../common/Modal/modal";
import { TermsAndConditions } from "./TermsAndConditions";

const SIGNUP_MODES = {
    SIGNUP: "SIGNUP",
    OTP: "OTP",
    CREATE_PASSWORD: "CREATE_PASSWORD",
    DISPALY_MESSAGE: "DISPALY_MESSAGE",
};
export interface Fields {
    mobileNumber: string,
    email: string,
    displayName: string,
    password: string,
    confirmPassword: string
}

export default function SignUp() {
    // redux
    const dispatch = useDispatch();
    const { isLoading } = useSelector(authSelector);
    // navigation
    const navigate = useNavigate();
    // useStates
    const [loginModes, setloginModes] = useState(SIGNUP_MODES.SIGNUP);
    const [otp, setOtp] = useState('');
    const [isShowPassword, setisShowPassword] = useState(false);
    const [isShowConfPassword, setisShowConfPassword] = useState(false);
    const [userData, SetUserData] = useState<any>({});
    const [termsCondition, SetTermsCondition] = useState(false);
    const [urlval,setUrl] = useState<any>("");
    useEffect(() => {
        let url: any = window.location.href;
        setUrl(url)
        if (url.substring(url.lastIndexOf('/') + 1) && url.substring(url.lastIndexOf('/') + 1) !== "register") {
            console.log(url.substring(url.lastIndexOf('/') + 1))
            let response = getInviteUserInfo(url.substring(url.lastIndexOf('/') + 1));
            response.then(data => {
                SetUserData(data["users"]);
            })
            //  values.email = userData.invitee_email;
            console.log(values)

        }
    }, []);

    // greeting Text
    var greetText = '';
    if (new Date().getHours() < 12) {
        greetText = 'Good Morning';
    } else if (new Date().getHours() < 18) {
        greetText = 'Good Afternoon';
    } else {
        greetText = 'Good Evening';
    }
    // fields
    const postValidation = (name, value, field) => {
        if (loginModes === SIGNUP_MODES.CREATE_PASSWORD) {
            let { password, confirmPassword } = values;
            if ((name === 'confirmPassword' && password !== value) || (name === 'password' && confirmPassword !== value)) {
                return {
                    hasError: true,
                    errorMsg: 'Password and Confirm Password not Matching'
                };
            }
        }
        return { hasError: false, errorMsg: '' };
    }
    const onValueChange = (name, value, values, fieldValues) => {
        if (loginModes === SIGNUP_MODES.CREATE_PASSWORD) {
            let { password, confirmPassword } = values;
            if (name === 'confirmPassword' && password === value) {
                if (fieldValues?.password?.hasError) {
                    fieldValues.password.hasError = false;
                }
            } else if (name === 'password' && confirmPassword === value) {
                updateValue({ 'confirmPassword': confirmPassword });
                if (fieldValues?.confirmPassword?.hasError) {
                    fieldValues.confirmPassword.hasError = false;
                }
            }
        }

        console.log(values)
        return null;
    }
    const initialValues = useMemo(() => ({ mobileNumber: "", email: "", displayName: "", password: "", confirmPassword: "" }), []);
    const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(FormElements, initialValues, { postValidation, onValueChange });

    /**
     * Set inital value
     * @param data 
     * @returns 
     */
    function getValues(data) {
        let valuesData: any = [];
        values.email = userData.invitee_email ? userData.invitee_email : values.email;
        return valuesData;
    }


    // toggle Action
    const toggleAction = async (type: string, id?: string, data?: string) => {
        switch (type) {
            case SIGNUP_MODES.SIGNUP:
                setloginModes(type);
                break;
            case SIGNUP_MODES.OTP:
                setloginModes(type);
                try {
                    dispatch(setLoading(true))
                    await send_otp(values.email, values.mobileNumber);
                    showToaster(toasterTypes.SUCCESS, 'OTP Sent Successfully!');
                    dispatch(setLoading(false))
                } catch (e: any) {
                    dispatch(setLoading(false))
                    dispatch(setAuthFailed({ message: e.message }))
                }
                break;

            case SIGNUP_MODES.CREATE_PASSWORD:

                setOtp(id || '');
                setloginModes(type);
                break;

            case SIGNUP_MODES.DISPALY_MESSAGE:
                if (!validateValues(["password", 'confirmPassword'])) {
                    try {
                        dispatch(setLoading(true))
                        let user = await create_profile(values.displayName, values.email, values.mobileNumber, values.password, otp, userData.id);
                        dispatch(setLoading(false));
                        setloginModes(type);
                    } catch (e: any) {
                        dispatch(setLoading(false))
                        dispatch(setAuthFailed({ message: e.message }))
                    }
                }
                break;
        }
    };

    /**
     * click on registyer button
     * set terms and condition modal to true
     */
    const signup = async () => {
        //"mobileNumber",
        if (validateValues(["displayName", 'email'])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else {
            SetTermsCondition(true)
            //sendOTP();
            //toggleAction(SIGNUP_MODES.CREATE_PASSWORD);
        }

    }

    /**
     * on accept terms and conditions
     */
    const acceptSubmit = () =>{
        sendOTP();
        SetTermsCondition(false);
    }

    /**&
     * trigger otp
     */
    const sendOTP = async () => {
        try {
            dispatch(setLoading(true))
            let response  = await send_otp(values.email, values.mobileNumber);
            toggleAction(SIGNUP_MODES.CREATE_PASSWORD);
            showToaster(toasterTypes.SUCCESS, 'OTP Sent Successfully!');
            dispatch(setLoading(false))
        } catch (e: any) {
            dispatch(setLoading(false))
            dispatch(setAuthFailed({ message: e.message }))
        }
    }

    /**
     * on account created successfull render this
     * @returns 
     */
    const renderSuccessMessage = () => {
        return (
            <div className="reset-password">
                <h2 className="welcome-text">Hi, {greetText}</h2>
                <p>Your account is created</p>
                <CustomButton type="primary" onClick={() => { navigate('/login') }} className="w-100" text="Login"></CustomButton>
            </div>
        );
    };

    const onClose = () => {
        SetTermsCondition(false)
    }

    /**
     * modal for accepting terms and conditions
     * @returns 
     */
    function termsAndConditions() {
        return (
            <ModalComponent
                width="80%"
                isOpen={termsCondition}
                onClose={() => onClose()}
                header={<h5>Terms and Conditions</h5>}
                centered={true}
                customClass='invite-modal variable-modal'
                body={
                    <>
                        <TermsAndConditions />
                    </>
                }
                footer={
                    <div className="footer" style={{ width:"100%",display:"flex", borderTop:"1px solid", justifyContent:"right", paddingTop:"5px"}}>
                    <span style={{marginTop:"10px"}}>I agree and accept the terms and conditions</span>
                    <CustomButton type="primary" className="w-100" text="ACCEPT & PROCEED" onClick={() => acceptSubmit()}></CustomButton>
                    </div>
                }
            />
        )
    }


    function createPasswordComponent() {
        return (
            <div className="login-container">
                <div className="content-header">
                    <i className="icon icon-back_arrow" onClick={() => toggleAction(SIGNUP_MODES.SIGNUP)}></i>
                    <div className="headerName">Secure your account</div>
                </div>
                <Row>
                    <Col className="col-md-12">
                        <OTPComponent
                            component='login'
                            headerName="Forget Password"
                            disabled={isLoading}
                            type="forgot-pwd"
                            loginId={"+91 " + values.mobileNumber}
                            callback={(next, otpVal?: string) => next === 'reset' ? toggleAction(SIGNUP_MODES.CREATE_PASSWORD, otpVal) : next === 'resend' ? sendOTP() : toggleAction(SIGNUP_MODES.SIGNUP)} />
                    </Col>

                    <Col className="pb-3" xs={12}>
                        <FieldItem
                            {...FormElements.password}
                            value={values.password}
                            isDisabled={otp.length != 6 ? true : false}
                            onChange={(...e) => handleChange(FormElements.password.name, ...e)}
                            icon={isShowPassword ? "lock_open" : "lock"}
                            touched={fields.password && fields.password.hasError}
                            error={fields.password && fields.password.errorMsg}
                            inputType={isShowPassword ? 'text' : 'password'}
                            iconClick={() => setisShowPassword(!isShowPassword)}
                        />
                    </Col>
                    <Col className="pb-3" xs={12}>
                        <FieldItem
                            {...FormElements.confirmPassword}
                            value={values.confirmPassword}
                            isDisabled={otp.length != 6 ? true : false}
                            onChange={(...e) => handleChange(FormElements.confirmPassword.name, ...e)}
                            icon={isShowConfPassword ? "lock_open" : "lock"}
                            touched={fields.confirmPassword && fields.confirmPassword.hasError}
                            error={fields.confirmPassword && fields.confirmPassword.errorMsg}
                            inputType={isShowConfPassword ? 'text' : 'password'}
                            iconClick={() => setisShowConfPassword(!isShowConfPassword)}
                        />
                    </Col>
                </Row>
                <CustomButton type="primary" className="w-100" onClick={() => { toggleAction(SIGNUP_MODES.DISPALY_MESSAGE) }} text="Next" loading={isLoading} ></CustomButton>
            </div>
        )
    }

    function signupComponent() {
        return (<div className="login-container">
            <Row>
                <div className="pb-3 col-md-12 col-xs-12" >
                    <FieldItem
                        {...FormElements.mobileNumber}
                        value={values.mobileNumber}
                        onChange={(...e) => handleChange(FormElements.mobileNumber.name, ...e)}
                        touched={fields.mobileNumber && fields.mobileNumber.hasError}
                        error={fields.mobileNumber && fields.mobileNumber.errorMsg}
                    />
                </div>
                <div className="col-md-12 col-xs-12">
                    <FieldItem
                        {...FormElements.displayName}
                        value={values.displayName}
                        onChange={(...e) => handleChange(FormElements.displayName.name, ...e)}
                        touched={fields.displayName && fields.displayName.hasError}
                        error={fields.displayName && fields.displayName.errorMsg}
                    />
                </div>
                <div className="col-md-12 col-xs-12">
                    <FieldItem
                        {...FormElements.email}
                        value={values.email}
                        values={getValues(userData.invitee_email)}
                        onChange={(...e) => handleChange(FormElements.email.name, ...e)}
                        touched={fields.email && fields.email.hasError}
                        error={fields.email && fields.email.errorMsg}
                    />
                </div>

                {/* <div className="col-md-12 col-xs-12">
                                <FieldItem
                                    {...FormElements.termsConditions}
                                    value={values.termsConditions}
                                    onChange={(...e) => handleChange(FormElements.termsConditions.name, ...e)}
                                    touched={fields.termsConditions && fields.termsConditions.hasError}
                                    error={fields.termsConditions && fields.termsConditions.errorMsg}
                                />
                            </div> */}
            </Row>
            <CustomButton type="primary" onClick={signup} className="w-100" text="Register" loading={isLoading}></CustomButton>
            <div className='right-bg-footer'>
                <span>Already a member ?</span>
                <span onClick={() => {urlval.includes('register') ? window.location.href = 'https://reapv2.coguide.in/' : navigate("/login") }}>Login</span>
            </div>
        </div>
        )
    }

    return (
        <div className="login-page">
            <div className="logo-container">
                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="logo" className="logo" />
            </div>
            {termsCondition ? termsAndConditions() : ""}
            {loginModes === SIGNUP_MODES.SIGNUP && <div className="welcome-text">Hello, {greetText}</div>}
            {loginModes === SIGNUP_MODES.SIGNUP && signupComponent()}
            {loginModes === SIGNUP_MODES.CREATE_PASSWORD && createPasswordComponent()}
            {loginModes === SIGNUP_MODES.DISPALY_MESSAGE && renderSuccessMessage()}
            {/* {loginModes === SIGNUP_MODES.OTP && (
                <OTPComponent
                    component='login'
                    headerName="OTP Verification"
                    disabled={isLoading}
                    type="forgot-pwd"
                    loginId={"+91 " + values.mobileNumber}
                    callback={(next, otpVal?: string) => next === 'reset' ? toggleAction(SIGNUP_MODES.CREATE_PASSWORD, otpVal) : next === 'resend' ?  toggleAction(SIGNUP_MODES.OTP) : toggleAction(SIGNUP_MODES.SIGNUP)}
                />
            )} */}
        </div>
    );
}
