import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from "../common/Modal/modal";
import { CreateCriteriaElements } from "./FormElements";
import { setLoading } from "../../store/slices/auth";
import { getCriteria, getFormVariables, getForms, getGroups, postCreateCriteria, updateCreateCriteria } from "./apiController";
import { useLocation } from "react-router-dom";
import CriteriaEditorComponent from "./CriteriaEditor";
import { MinusCircleFilled, MinusCircleOutlined, PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";

export default function CriteriaForm(props) {
  const dispatch = useDispatch();
  const [action, setaction] = useState<any | "">("");
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [formList, SetFormList] = useState<any>([]);
  const [groupData, SetGroup] = useState<any>([]);
  const [formvariables, setformVariables] = useState<any>([]);
  const [formvariablesValue, setformVariablesValue] = useState<any>([]);
  const [rules, Setrules] = useState<any>([]);
  const location = useLocation();


  const [values, fields, handleChange, { validateValues, updateValue, updateError, removeErrors }] =
    useFieldItem(CreateCriteriaElements, {}, { onValueChange });

  /**
   * on value change for form fields
   * @param name 
   * @param value 
   * @param values 
   * @param fieldValues 
   * @returns 
   */
  function onValueChange(name, value, values, fieldValues = null) {
    let resetValue = {};
    console.log(values)
    if (name == "source") {
      fetchFormVariables(value);
    }

    /**
     * check if the current var type is variable
     * if yes get the variable data from formVariables state
     * update the rules state with the updated values
     */
    //let varName = name.replace(/[^A-Za-z]/g, "")
    if (name.slice(0, -1) == "variable") {
      let variableData = formvariables.filter(items => items.label == value.label)
      let fieldArray: any = [];
      variableData[0].field_value && variableData[0].field_value.map(item => {
        fieldArray.push({ label: item, value: item })
      })
      variableData[0].fielddropDown = fieldArray;
      let idValue = name[name.length - 1] - 1;

      rules[name[name.length - 1]] = {
        id: rules[name[name.length - 1]].id,
        variable: value,
        operator: "",
        uservalue: "",
        logicoperator: "",
        variabledata: variableData[0]
      }

    } else if (name.slice(0, -1) == "operator") {

      let idValue = name[name.length - 1] - 1;
      rules[idValue] = {
        id: rules[idValue].id,
        variable: rules[idValue].variable,
        operator: value,
        uservalue: rules[idValue].uservalue,
        logicoperator: rules[idValue].logicoperator,
        variabledata: rules[idValue].variabledata
      }
    } else if (name.replace(/[^A-Za-z]/g, "") == "uservalue") {
     // let idValue = name[name.length - 1] - 1;
      let idValue = name.replace(/\D/g, "") - 1;
      console.log(name,value,idValue)
      rules[idValue] = {
        id: rules[idValue].id,
        variable: rules[idValue].variable,
        operator: rules[idValue].operator,
        uservalue: value,
        logicoperator: rules[idValue].logicoperator,
        variabledata: rules[idValue].variabledata
      }
    } else if (name.slice(0, -1) == "logic") {
      let idValue = name[name.length - 1] - 1;

      rules[idValue] = {
        id: rules[idValue].id,
        variable: rules[idValue].variable,
        operator: rules[idValue].operator,
        uservalue: rules[idValue].uservalue,
        logicoperator: value,
        variabledata: rules[idValue].variabledata
      }
      addRules()
    }

    return [
      {
        ...values,
        ...resetValue,
        [name]: value,
      },
    ];
  }

  /** onBlur check source and destination duplication */
  const onBlurAction = (type) => {
    console.log(values["destination"].label == values["source"].label)
    if (values["destination"].label == values["source"].label) {
      if (type != "source") {
        updateError("destination", { hasError: true, errorMsg: "Cannot be same as Source" });
      } else {
        updateError("source", { hasError: true, errorMsg: "Cannot be same as Destination" });
      }
    }
  }

  useEffect(() => {
    console.log(props)
    fetchForms(props.groupData && props.groupData?.id);
    if(!props.selectedItem){
    addRules();
    }
  }, [])


 useEffect(() => {
   
  }, [rules])


  /**
 * fetch form list
 * @param index 
 * @param id 
 */
  const fetchForms = async (id) => {
    try {
      dispatch(setLoading(true));
      let tempForms = await getForms(id);
      SetFormList([]);
      let tempArray:any=[];
      await tempForms.map((item, i) => {
        let Obj = {
          label: item.form_name,
          value: item.id
        }
        tempArray.push(Obj)
      })
      SetFormList(tempArray);
      if(props.selectedItem){
        dispatch(setLoading(true));
        await fetchPreSelectedData(tempArray);
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  };


  /**
   * get variables data
   * @param formData 
   */
  const fetchFormVariables = async (formData) => {
    try {
      dispatch(setLoading(true));
      let response = await getFormVariables(formData.value);
      setformVariables([]);
      setformVariablesValue(response.data);
      let tempArray : any =[];
      response.data.map((item, i) => {
        let Obj = {
          label: item.field_name,
          value: item.id,
          field_value: item.field_value,
          field_type: item.field_type
        }
        tempArray.push(Obj)
        //setformVariables(prevState => [...prevState, Obj]);
      })
      setformVariables(tempArray);
      dispatch(setLoading(false));
      return tempArray;

    } catch (e: any) {
      setformVariables([]);
      dispatch(setLoading(false));
    }
  }

  /**
   * fetch intial selected item data
   * create rules 
   * update values objects
   */
  const fetchPreSelectedData = async(data) =>{
    console.log(CreateCriteriaElements.autoapply)
    let autoApplyValues = CreateCriteriaElements.autoapply.values;
    let sourceform = data && data.filter(item => item.value == props.selectedItem.source_form_id);
    let destinationform = data && data.filter(item => item.value == props.selectedItem.target_form_id);
    let autoApplyValue = autoApplyValues && autoApplyValues.filter(item => item.value == props.selectedItem.auto_response_capture);

    updateValue({source:sourceform[0],destination:destinationform[0],autoapply:autoApplyValue[0]})
    //trigger to get variable list for selected source form
    let tempformVariables = await fetchFormVariables(sourceform[0]);
    let fieldsArray : any = [];
    let logicalArray : any = [];
    //loop criteria
    props.selectedItem.criteria && props.selectedItem.criteria.filter((itemfilter) => itemfilter.key == "field").map((item,index) => {
        let conditionsTemp = props.selectedItem.criteria.filter((itemfilter) => itemfilter.key == "condition");  
         const specialCharactersRegex = /[!==%^&*(),.?":{}|<>]/g;
         let Obj : any;
         let operatorTemp : any;
         let userValueTemp : any;
         let variableTemp = tempformVariables && tempformVariables.filter(itemid => itemid.value == item.field_id)[0];
         let fieldArray: any = [];
         //create user values dropdown values
         variableTemp.field_value && variableTemp.field_value.map(item => {
           fieldArray.push({ label: item, value: item })
         })
         variableTemp.fielddropDown = fieldArray;
       
         updateValue({[`variable${index}`]:variableTemp,
         [`logic${index+1}`]:conditionsTemp[index] ? {label: conditionsTemp[index].value, value: conditionsTemp[index].value}: {}})

         //if condtion checks if the value typeis expresssion or string
         //if expression get the special Character from 
         if(item.value_type == "expression"){
          operatorTemp = item.value.match(specialCharactersRegex)
          userValueTemp = item.value.split(operatorTemp.join(""))
          updateValue({[`operator${index+1}`]:{value:operatorTemp.join(""),label:operatorTemp.join("")},[`uservalue${index+1}`]:userValueTemp[userValueTemp.length-1]})
          Obj = {
              id: index+1,
              variable: variableTemp,
              operator: {value:operatorTemp.join(""),label:operatorTemp.join("")},
              uservalue: userValueTemp[userValueTemp.length-1],
              logicoperator: conditionsTemp[index] ? {label: conditionsTemp[index].value, value: conditionsTemp[index].value}: "",
              variabledata: variableTemp
            }
        }else if(item.value_type == "string"){
          if(variableTemp.field_type == "radioButton" || variableTemp.field_type == "checkBox" || variableTemp.field_type == "dropDown"){
            userValueTemp = {value:item.value,label:item.value}
          }else{
           userValueTemp = item.value
          }
          updateValue({[`operator${index+1}`]:{value:"==",label:"=="},[`uservalue${index+1}`]:userValueTemp})
          Obj = {
            id: index+1,
            variable: variableTemp,
            operator: {value:"==",label:"=="},
            uservalue: userValueTemp,
            logicoperator: conditionsTemp[index] ? {label: conditionsTemp[index].value, value: conditionsTemp[index].value}: "",
            variabledata: variableTemp
          }
        }
        fieldsArray.push(Obj)
    })
    Setrules(fieldsArray)

  }

  /**
   * submit action
   */
  const onAdd = async () => {
    if (validateValues(["name", "source", "destination"])) {
      showToaster(
        toasterTypes.ERROR,
        "Please enter the proper values in the fields highlighted in red"
      );
    } else {
      //props.onClose();
      props.addData(true);
      console.log(rules);
      let tempCriteriaArray: any = [];
      /**
       * arrange rules
       * create criteria array of objects with expressions and conditions
       * logical operator if not empty or length 
       */
      rules && rules.map((items, i) => {
        if (items.variable['field_type'] == "number") {
          tempCriteriaArray.push({
            "key": "field",
            "field_id": items.variable['value'],
            "condition_name":`${items.variable['label']}${items.operator['label']}${items.uservalue}`,
            "value_type": "expression",
            "value": `x${items.operator['label']}${items.uservalue}`
          })
          if (items.logicoperator && items.logicoperator != "" && rules.length-1 != i) {
            tempCriteriaArray.push({
              "key": "condition",
              "value_type": "logical_operator",
              "value": `${items.logicoperator['value']}`
            })
          }
        } else if (items.variable['field_type'] == "radioButton" || items.variable['field_type'] == "dropDown" || items.variable['field_type'] == 'textBox') {
          tempCriteriaArray.push({
            "key": "field",
            "field_id": items.variable['value'],
            "condition_name":items.variable['label'],
            "value_type": "string",
            "value": `${typeof items.uservalue == 'string' ? items.uservalue : items.uservalue.label}`
          })
          if (items.logicoperator && items.logicoperator != "" && rules.length-1 != i) {
            tempCriteriaArray.push({
              "key": "condition",
              "value_type": "logical_operator",
              "value": `${items.logicoperator['value']}`
            })
          }
        } else if(items.variable['field_type'] == "checkBox") {
          tempCriteriaArray.push({
            "key": "field",
            "field_id": items.variable['value'],
            "condition_name":items.variable['label'],
            "value_type": "list",
            "value": `${items.uservalue}`
          })

          if (items.logicoperator && items.logicoperator != "" && rules.length-1 != i) {
            tempCriteriaArray.push({
              "key": "condition",
              "value_type": "logical_operator",
              "value": `${items.logicoperator['value']}`
            })
          }
        }
      })

      let reqObj: any = {
        topic_id: props.projectData?.TopicID || props.projectData?.TopicCode,
        group_id: props.groupData?.id,
        // criteria_name: values.name,
        auto_response_capture:values.autoapply.value,
        source_form_id: values.source.value,
        target_form_id: values.destination.value,
        criteria: tempCriteriaArray
      };

      try {
        dispatch(setLoading(true));
        let response: any;
        console.log("reqObj:::", reqObj);
        if (props.selectedItem) {
          reqObj.id = props.selectedItem.id;
          response = await updateCreateCriteria(reqObj);
        } else {
          response = await postCreateCriteria(reqObj);
        }
        dispatch(setLoading(false));
        showToaster(toasterTypes.SUCCESS, response.message);
        props.onClose();
        props.fetchFormsData();
        props.onFormCreation();
      } catch (e: any) {
        dispatch(setLoading(false));
      }
    }
  };


  const onAction = async (type, data: any = null) => {
    switch (type) {
      case 1:
        if (data?.total_responses === 0) {
          showToaster(toasterTypes.ERROR, "No Records Found");
        } else {
          dispatch(setLoading(true));
          dispatch(setLoading(false));
          setaction(type);
        }
        break;
      case 2:
        try {
          dispatch(setLoading(true));
          dispatch(setLoading(false));
          setaction(type);
        } catch (e: any) {
          // setgroups([]);
          dispatch(setLoading(false));
        }
        break;
      case 3:
        // setformData(data);
        setaction(type);
        break;
      default:
        setaction(type);
        break;
    }
  };

  /**
   * add rules
   */
  const addRules = () => {
    let Obj = {
      id: rules.length == 0 ? 1 : rules[rules.length - 1]["id"] + 1,
      variable: "",
      operator: "",
      uservalue: "",
      logicoperator: "",
      variabledata: ""
    }
    Setrules(prevState => [...prevState, Obj])
  }

  /**
   * remove items 
   */
  const removeRules = (itemid) => {
    console.log(itemid)
    let updatedArray = rules.filter(item => item.id != itemid + 1)
    console.log(updatedArray)
    Setrules(updatedArray)

  }

 

  return (
    <ModalComponent
      width={"90%"}
      onClose={() => props.onClose()}
      isOpen={true}
      header={<b>{props.selectedItem ? `Update Criteria` :  `Create Criteria`}</b>}
      centered={true}
      customClass="criteria-modal"
      body={
        <div>
          {/* <Row>
            <Col md={12}>
              <FieldItem
                {...CreateCriteriaElements.name}
                value={values.name}
                onChange={(...e) =>
                  handleChange(CreateCriteriaElements.name.name, ...e)
                }
                touched={fields.name && fields.name.hasError}
                error={fields.name && fields.name.errorMsg}
              />
            </Col>
          </Row> */}
          <Row>
            <Col md={4}>
              <FieldItem
                {...CreateCriteriaElements.source}
                value={values.source}
                values={formList && formList}
                onChange={(...e) =>
                  handleChange(CreateCriteriaElements.source.name, ...e)
                }
                touched={fields.source && fields.source.hasError}
                error={fields.source && fields.source.errorMsg}
                onBlur={() => onBlurAction("source")}
              />
            </Col>
            <Col md={4}>
              <FieldItem
                {...CreateCriteriaElements.destination}
                value={values.destination}
                values={formList && formList}
                onChange={(...e) =>
                  handleChange(CreateCriteriaElements.destination.name, ...e)
                }
                touched={fields.destination && fields.destination.hasError}
                error={fields.destination && fields.destination.errorMsg}
                onBlur={() => onBlurAction("destination")}
              />
            </Col>

            <Col md={4}>
              <FieldItem
                {...CreateCriteriaElements.autoapply}
                value={values.autoapply}
                onChange={(...e) =>
                  handleChange(CreateCriteriaElements.autoapply.name, ...e)
                }
                touched={fields.autoapply && fields.autoapply.hasError}
                error={fields.autoapply && fields.autoapply.errorMsg}
              />
            </Col>
          </Row>
          <div className="permissions-border">
            <Row className="m-0">
              <Col className="p-0">
                <div className="permissions-text">Rules</div>
              </Col>
              {/* <Col className="p-0">
                <div className="user-text" onClick={() => addRules()}>
                  Add Rules
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="code-editor">
            {
              rules && rules.map((item, index) => {
                return <Row key={index}>
                  <Col md={3}>
                    <FieldItem
                      name={"variable" + item.id}
                      value={values["variable" + (item.id - 1)]}
                      values={formvariables && formvariables}
                      type={FIELD_TYPES.DROP_DOWN}
                      label={CreateCriteriaElements.variable.label}
                      onChange={(...e) =>
                        handleChange("variable" + index, ...e)
                      }
                      isMandatory={true}
                      touched={fields["variable" + item.id] && fields["variable" + item.id].hasError}
                      error={fields["variable" + item.id] && fields["variable" + item.id].errorMsg}
                    />
                  </Col>

                  <Col md={3}>
                    <FieldItem
                      name={"operator" + item.id}
                      value={values["operator" + (item.id)]}
                      values={CreateCriteriaElements.operator.values}
                      type={FIELD_TYPES.DROP_DOWN}
                      isMandatory={true}
                      label={CreateCriteriaElements.operator.label}
                      onChange={(...e) =>
                        handleChange("operator" + item.id, ...e)
                      }
                      touched={fields["operator" + item.id] && fields["operator" + item.id].hasError}
                      error={fields["operator" + item.id] && fields["operator" + item.id].errorMsg}
                    />
                  </Col>
                  <Col md={3}>
                    <FieldItem
                      name={"uservalue" + item.id}
                      value={values["uservalue" + (item.id)]}
                      isMandatory={true}
                      type={rules && item.variabledata.field_type == "radioButton" || item.variabledata.field_type == "dropDown" ? FIELD_TYPES.DROP_DOWN : item.variabledata.field_type == "checkBox" ? FIELD_TYPES.MULTI_SELECT : FIELD_TYPES.TEXT}
                      values={rules && item.variabledata.field_type == "radioButton" || item.variabledata.field_type == "checkBox" || item.variabledata.field_type == "dropDown" ? rules[index].variabledata?.fielddropDown : ""}
                      label={CreateCriteriaElements.uservalue.label}
                      onChange={(...e) =>
                        handleChange("uservalue" + item.id, ...e)
                      }
                      touched={fields["uservalue" + item.id] && fields["uservalue" + item.id].hasError}
                      error={fields["uservalue" + item.id] && fields["uservalue" + item.id].errorMsg}
                    />
                  </Col>
                  <Col md={2}>
                    <FieldItem
                      name={"logic" + item.id}
                      value={values["logic" + (item.id)]}
                      values={CreateCriteriaElements.logicoperator.values}
                      type={FIELD_TYPES.DROP_DOWN}
                      label={CreateCriteriaElements.logicoperator.label}
                      onChange={(...e) =>
                        handleChange("logic" + item.id, ...e)
                      }
                      touched={fields["logic" + item.id] && fields["logic" + item.id].hasError}
                      error={fields["logic" + item.id] && fields["logic" + item.id].errorMsg}
                    />
                  </Col>

                  <Col md={1}>
                    <div className="add-row" >
                      {rules && rules.length == index + 1 ?
                        <>
                          <MinusCircleOutlined className="remove" onClick={() => removeRules(index)} />
                          {/* <PlusCircleOutlined className="add" onClick={() => addRules()}/> */}
                        </>
                        :
                        <MinusCircleOutlined className="remove" onClick={() => removeRules(index)} />
                      }
                    </div>
                  </Col>

                </Row>
              })
            }

          </div>
        </div>
      }
      footer={
        <div>
          <CustomButton
            type="primary"
            isDisabled={false}
            text={props.selectedItem ? `Update` :  `Create`}
            onClick={() => onAdd()}
          ></CustomButton>
        </div>
      }
    />
  );
}
