import React, { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import {
  MemoryRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import PreLoginLayoutRoute from "../layouts/preLogin";
import "../themes/out/base.css";
import i18n from "../translations";
import SecuredLogin from "../utils/SecuredLogin";
import { Toaster, toasterPositions } from "../widgets";
import Home from "./home";
import Login from "./login";
import SignUp from "./signup";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Dashboard from "./dashboard";
import Profile from "./profile";
import PostLoginLayoutRoute from "../layouts/postLogin";
import Project from "./project";
import CreateProject from "./createProject";
import { useDispatch } from "react-redux";
import { CurrentUser, setAuthSuccess } from "../store/slices/auth";
import SampleSize from "./sampleSize";
import DataCollection from "./dataCollection";
import StudyModule from "./studyModule";
import Subscriptions from "./subscriptions";
import TopicsLibrary from "./TopicsLibrary";
import JournalSearch from "./journalSearch";
import Events from "./events";
import AddVariables from "./dataCollection/AddVariables";
import DataEntry from "./dataCollection/DataEntry";
import SurveyForm from "./surveyForm";
import CreateCriteria from "./dataCollection/CreateCriteria";
import CriteriaForm from "./dataCollection/CriteriaForm";
import People from "./people";
import PeopleProfile from "./people/PeopleProfile";
import PeopleInviteListMobile from "./people/PeopleInviteListMobile";
import PeopleProfileMobile from "./people/PeopleProfileMobile";
import InviteSuccessMobile from "./people/inviteSuccessMobile";
import PeopleListChat from "./people/PeopleListChat";
import PeopleInvite from "./people/PeopleInvite";
import PeopleInviteMobile from "./people/PeopleInviteMobile";
import PeopleChatMobile from "./people/PeopleChatMobile";
import InviteReject from "./people/InviteReject";
import ProjectMobile from "./projectmobile";
import Submissions from "./submissions";
import SubmissionInfo from "./submissions/SubmissionInfo";
import RegisteredEvents from "./submissions/RegisteredEvents";
import SubmissionDocument from "./submissions/SubmissionDocument";
import ProjectDetail from "./projectDetails";
import { usePWAInstall } from "react-use-pwa-install";
import { Modal } from "reactstrap";
import CreateProjectMobile from "./createProjectMobile";
import Success from "./createProjectMobile/Success";
import TitleGeneration from "./createProjectMobile/TitleGeneration";
import withClearCache from "../ClearCache";
import DataAnalysis from "./dataAnalysis";
import ProfileMobile from "./profileMobile";
import LearningModule from "./learning";
import CourseModules from "./learning/CourseModules";
import ModuleVideo from "./learning/ModuleVideo";
import DataAnalysisTable from "./dataAnalysis/DataAnalysisTable";
import ConfigChart from "./dataAnalysis/ConfigChart";
import AnalysisReport from "./dataAnalysis/AnalysisReport";
import AddGroupingMobile from "./dataAnalysis/AddGroupingMobile";
import Editor from "./Editor/Editor";
import Planner from "./planner";
import Documents from "./documents";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(true);


  useEffect(() => {
    let userData = localStorage.getItem("coguide_user");
    if (userData) {
      let currentUser: CurrentUser = JSON.parse(userData);
      dispatch(setAuthSuccess(currentUser));
    }
    if (window.innerWidth > 700) {
      localStorage.setItem("device", "desktop")
    } else {
      localStorage.setItem("device", "mobile")
    }
  }, [dispatch]);

  const install = usePWAInstall();

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      {install &&
        <Modal closable={false} visible={isModalOpen} okText={"Add"} onOk={install} onCancel={handleCancel} >
          Add Coguide to Home Screen?
          {/* <p>Build date: {getBuildDate(packageJson.buildDate)}</p> */}
        </Modal>
      }
      <I18nextProvider i18n={i18n}>

        <Toaster position={toasterPositions["TOP-RIGHT"]} />
        <Router>
          <Routes>
            <Route
              path="/*"
              element={
                <SecuredLogin>
                  <Routes>
                    <Route
                      element={
                        <PostLoginLayoutRoute>
                          <Outlet />
                        </PostLoginLayoutRoute>
                      }
                    >
                      <Route path="/project" element={<Project />} />

                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/learning" element={<LearningModule />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/profilemobile" element={<ProfileMobile />} />
                      <Route path="/people" element={<People />} />
                      <Route path="/planner" element={<Planner />} />
                      <Route path="/document" element={<Documents/>} />
                      <Route path="/projectmobile" element={<ProjectMobile />} />
                      <Route path="/peopleprofile" element={<PeopleProfile />} />

                      <Route path="/home" element={<Home />} />
                      <Route path="/*" element={<Dashboard />} />
                      <Route path="/sampleSize" element={<SampleSize />} />
                      <Route path="/studyModule" element={<StudyModule />} />
                      <Route path="/submissioninfo" element={<SubmissionInfo />} />
                      <Route path="/registeredevents" element={<RegisteredEvents />} />
                      <Route path="/documentsubmission" element={<SubmissionDocument />} />
                      <Route
                        path="/dataCollection"
                        element={<DataCollection />}
                      />
                      <Route path="/coursemodule" element={<CourseModules />} />
                      <Route path="/modulevideo" element={<ModuleVideo />} />
                      <Route path="/dataanalysis" element={<DataAnalysis />} />
                      {/* <Route path="/dataanalysistable" element={<DataAnalysisTable/>} /> */}
                      <Route path="/subscriptions" element={<Subscriptions />} />
                      <Route path="/topics" element={<TopicsLibrary />} />
                      <Route path="/journal" element={<JournalSearch />} />
                      <Route path="/events" element={<Events />} />
                      <Route path="/submissions" element={<Submissions />} />
                      <Route path="/projectdetail" element={<ProjectDetail />} />
                      <Route path="/chart" element={<ConfigChart />} />
                      <Route path="/analysis" element={<AnalysisReport />} />

                    </Route>
                    <Route path="/peopleinvitemobile" element={<PeopleInviteListMobile />} />
                    <Route path="/peopleinvite" element={<PeopleInviteMobile />} />
                    <Route path="/peopleprofilemobile" element={<PeopleProfileMobile />} />
                    <Route path="/invitesuccess" element={<InviteSuccessMobile />} />
                    <Route path="/peoplemobile" element={<PeopleListChat />} />
                    <Route path="/peoplechat" element={<PeopleChatMobile />} />
                    <Route path="/invitereject" element={<InviteReject />} />
                    {/* Routes for project creation */}
                    <Route path="/titlegenerate" element={<TitleGeneration />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/editor" element={<Editor/>} />

                    <Route path="/addVariables" element={<AddVariables />} />
                    <Route path="/createProject" element={<CreateProject />} />
                    <Route path="/createprojectmobile" element={<CreateProjectMobile />} />
                    <Route path="/dataEntry" element={<DataEntry />} />
                    <Route path="/createCriteria" element={<CreateCriteria />} />
                    <Route path="/criteriaForm" element={<CriteriaForm />} />
                  </Routes>
                </SecuredLogin>
              }
            />
            <Route
              element={
                <PreLoginLayoutRoute>
                  <Outlet />
                </PreLoginLayoutRoute>
              }
            >
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<SignUp />} />

            </Route>
            <Route>
              <Route path="/surveyForm" element={<SurveyForm />} />
              <Route path="/editor" element={<Editor/>} />
            </Route>
            
          </Routes>
        </Router>
      </I18nextProvider>
    </>
  );
}

export default App;
