import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import CustomTable from "../../widgets/table";
import { getFormData, getForms, getGroups, mergeForms } from "./apiController";
import Tabs from "../sampleSize/Tabs";
import GroupVariable from "./GroupVariablesModal";
import { ArrowLeftOutlined, GroupOutlined, SettingOutlined } from "@ant-design/icons";
import * as monaco from 'monaco-editor';
import ConfigureAnalysis from "./ConfigureAnalysis";
import { showToaster, toasterTypes } from "../../widgets";
import ViewFormData from "./ViewFormData";
import DataAnalysisTable from "./DataAnalysisTable";
import ConfigChart from "./ConfigChart";
import GroupVariableTable from "./GroupVariableTable";
import { getFormRecords } from "../dataCollection/apiController";
import AnalysisCard from "./AnalysisCard";
import DataAnalysisMobile from "./DataAnanlysisMobile";
import GroupVariableMobile from "./GroupVariableMobile";
import AskQuestions from "../dataCollection/AskQuestions";
import PushToEditor from "../Editor/utils/PushToEditor";
import SectionModal from "../Editor/utils/SectionModal";
import CopyTooltip from "../Editor/utils/CopyTooltip";
import { TableData } from "../Editor/utils/ContentType";


export default function DataAnalysis(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [projectData, setprojectData] = useState<any | null>(null);
    const [userData, setuserData] = useState<any | null>(null);
    const [groups, setgroups] = useState<any | null>([]);
    const [groupid, SetgroupId] = useState("");
    const [selectedGroup, setselectedGroup] = useState<any | null>(null);
    const [forms, setforms] = useState<any | null>([]);
    const [selectedItem, setselectedItem] = useState<any | "">("");
    const [formData, setformData] = useState<any | null>(null);
    const [formCount, SetFormCount] = useState(0);
    const [popupType, setpopupType] = useState("");
    const [pageSize, SetPageSize] = useState(10);
    const [column, setcolumns] = useState<any>([])
    const [device, setDevice] = useState(window.innerWidth > 750);
    const [selectedText, setSelectedText] = useState('');
	const [tooltipStyle, setTooltipStyle] = useState({});
	const [showTooltip, setShowTooltip] = useState(false);
	const [action, setAction] = useState("");
	
	/**
 * function call to Push code to editor
 */
	const addToEditor = (data) => {
		console.log(data)
		let Obj = {
			"type": "paragraph",
			"attrs": {
				"id": "",
				"class": "paragraph",
				"track": [],
				"group": "",
				"viewid": ""
			},
			"content": [
				{
					"type": "text",
					"text": selectedText
				}
			]
		};
		let response = PushToEditor(Obj, data.section.value)
		if (response) {
			showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
		}
		setAction("")

	}

	useEffect(() => {
		// Attach the mouseup event listener to the document
		document.addEventListener('mouseup', getSelectedText);

		// Clean up the event listener on component unmount
		return () => {
			document.removeEventListener('mouseup', getSelectedText);
		};
	}, [selectedText]);

	/**
	 * selected text
	 * @param data 
	 */
	const getSelectedText = () => {
		const selection: any = window.getSelection();
		if (selection.toString().trim()) {
			const range = selection.getRangeAt(0);
			const rect = range.getBoundingClientRect();

			// Update the state with the selected text and tooltip position
			setSelectedText(selection.toString());
			setTooltipStyle({
				top: `${rect.top + window.scrollY + rect.height - 50}px`,
				left: `${rect.left + window.scrollX - 100}px`,
			});

			setShowTooltip(true);
		} else {
			setShowTooltip(false);
		}
	};

	const sectionAction = () => {
		setAction("section")
	}
   
    const tabData = [
        {
            title: "Project Details",
            id: 1,
            tab: "project details"
        },
        {
            title: "Study Design",
            id: 2,
            tab: "study design"
        },
        {
            title: "Data Collection",
            id: 3,
            tab: "Data collection"

        },
        {
            title: "Data Analysis",
            id: 4,
            tab: "Data analysis"
        },
        {
            title: "People",
            id: 5,
            tab: "people"

        },
        {
            title: "Planner",
            id: 6,
            tab: "people"

        },
        {
            title: "Registered Events",
            id: 7,
            tab: "register events"

        },
        // {
        //     title: "Mentors",
        //     id: 8,
        //     tab: "mentors"
        // }
    ];

    useEffect(() => {
        if (location.state) {
            let tempState: any = location.state;
            setprojectData(tempState);
            console.log(tempState)
            let temp: any = localStorage.getItem("coguide_user");
            if (temp) {
                temp = JSON.parse(temp);
                setuserData(temp);
                fetchGroups(tempState.TopicID || tempState.TopicCode);
            }
        } else {
            navigate("/project");
        }
        fetchColumns();

    }, [location.state, navigate]);


    /**
     * columns
     */
    const columns = [
        {
            id: "form_name",
            label: "Form Name",
            accessor: "form_name",
            sort: "asc",
        },
        {
            id: "total_responses",
            label: "Total Records",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <>
                        <span>{original.total_responses}</span>                    </>
                );
            },
        }, {
            id: "grouping",
            label: "Configure Grouping",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <GroupOutlined onClick={() => onAction(6, original)} />
                );
            },
        }, {
            id: "analysis",
            label: "Configure Analysis",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <SettingOutlined onClick={() => onAction(9, original)} />
                );
            },
        },
        {
            id: "view",
            label: "View",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <i
                        className="icon icon-eye"
                        onClick={() => onAction(1, original)}
                    ></i>
                );
            },
        },
        {
            id: "question",
            label: "Ask Question",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <i className="icon icon-question" onClick={() => onAction("question", original)} ></i>
                );
            },
        },
    ];

    /**
     * fetch groups data
     */
    const fetchGroups = async (id) => {
        try {
            dispatch(setLoading(true));
            let tempGroups = await getGroups(id);
            tempGroups?.data.forEach((obj) => {
                obj.label = obj.name;
                obj.value = obj.id;
            });
            setgroups(tempGroups?.data || []);
            let groupIndex = tempGroups?.data.findIndex((obj) => obj.is_default);
            if (groupIndex >= 0) {
                fetchForms(groupIndex, tempGroups?.data[groupIndex].id);
                SetgroupId(tempGroups?.data[groupIndex].id);
            } else {
                fetchForms(0, tempGroups?.data[0].id);
                SetgroupId(tempGroups?.data[0].id);
            }

            dispatch(setLoading(false));
        } catch (e: any) {
            setgroups([]);
            dispatch(setLoading(false));
        }
    };

    const fetchForms = async (index, id = "") => {
        try {
            dispatch(setLoading(true));
            let tempForms = await getForms(id || groups[index].id);
            setforms(tempForms || []);
            TableData(tempForms)
            setselectedGroup(index);
            dispatch(setLoading(false));
        } catch (e: any) {
            setgroups([]);
            dispatch(setLoading(false));
        }
    };


    const fetchColumns = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getFormRecords(projectData?.formData.id, 1, pageSize);
            if (response.data && response.data.length > 0) {
                let tempHeaders: any = [];
                response.data.forEach((obj, index) => {
                    obj.fields.forEach(subObj => {
                        if (index > 0) {
                            let check = tempHeaders.filter(elem => elem.value === subObj.id);
                            if (check.length === 0) {
                                tempHeaders.push({
                                    label: subObj.field_name,
                                    value: subObj.id,
                                    data: subObj,
                                    type: subObj.field_type
                                });
                            }
                        } else {
                            tempHeaders.push({
                                label: subObj.field_name,
                                value: subObj.id,
                                data: subObj,
                                type: subObj.field_type
                            });
                        }
                    });
                });
                console.log(tempHeaders)

                let tempColumns: any = [];
                tempHeaders.forEach(obj => {
                    tempColumns.push({
                        "id": obj.value,
                        "label": obj.label,
                        accessor: elem => (obj.data.field_type === 'fileUpload' && elem[obj.value]) ? <div className='link-text' onClick={() => window.open(elem[obj.value])}>Link</div> : elem[obj.value]?.toString() || '-',
                        "sort": "asc",
                        "field_type": obj.type
                    })
                })

                setcolumns(tempColumns);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            setgroups([]);
            dispatch(setLoading(false));
        }
    };

    const fetchFormData = async (data) => {
        try {
            dispatch(setLoading(true));
            let response = await getFormData(data?.id);
            setformData(response?.data);
            SetFormCount(data.total_responses);
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onclick Action
     */
    const onAction = async (type, data) => {
        switch (type) {
            case 1:
                if (data?.total_responses === 0) {
                    showToaster(toasterTypes.ERROR, "No Records Found");
                } else {
                    await fetchFormData(data)
                    console.log(type)
                    setpopupType(type);
                }
                break;
            case 6:
                await fetchFormData(data)
                setpopupType("group")
                break;
            case 9:
                await fetchFormData(data)
                setpopupType("analysis");
                break;
            case 10:
                navigate("/chart");
                break;
            case 12:
                try {
                    dispatch(setLoading(true));
                    let formid = forms.filter(item => item.form_type == "cumulative")
                    let response = await mergeForms({
                        "group_id": groupid,
                        "cumulative_form_id": formid[0].id
                    });
                    dispatch(setLoading(false));
                } catch (err: any) {
                    dispatch(setLoading(false));
                }
                break;
            case "question":
                console.log(data)
                setselectedItem(data)
                setpopupType("question")
                break;
            default:
                setpopupType("analyse")

        }
        // if (type == 6) {

        // } else if (type == 9) {
        //     setpopupType("analysis")
        // } else {
        //     setpopupType("analyse")
        // }
    }


    const onClose = () => {
        setpopupType("");
    }

    return (
        <>
        {
				action == "section" &&
				<SectionModal pushAction={addToEditor} onclose={() => setAction("")} />
			}
			{showTooltip && (
				<CopyTooltip tooltipStyle={tooltipStyle} data={selectedText} pushAction={sectionAction} />
			)}

            {
                popupType == "question" &&
                <AskQuestions {...props} isClose={() => setpopupType("")} formId={selectedItem.id} />
            }
            {popupType == "group" ?
                device ?
                    <GroupVariableTable {...props}
                        data={projectData}
                        forms={forms}
                        variables={column}
                        formData={formData}
                        formPageCount={formCount}
                        projectData={projectData}
                        groups={groups}
                        group={groups[selectedGroup]}
                        selectedGroup={selectedGroup}
                        onClose={() => onClose()} />
                    :
                    <GroupVariableMobile
                        {...props}
                        data={projectData}
                        forms={forms}
                        variables={column}
                        formData={formData}
                        formPageCount={formCount}
                        projectData={projectData}
                        groups={groups}
                        group={groups[selectedGroup]}
                        selectedGroup={selectedGroup}
                        onClose={() => onClose()} />
                :
                popupType == "analysis" ?
                    device ?
                        <DataAnalysisTable
                            {...props}
                            data={projectData}
                            forms={forms}
                            formData={formData}
                            formPageCount={formCount}
                            projectData={projectData}
                            groups={groups}
                            group={groups[selectedGroup]}
                            selectedGroup={selectedGroup}
                            onClose={() => onClose()} />
                        :
                        <DataAnalysisMobile
                            {...props}
                            data={projectData}
                            forms={forms}
                            formData={formData}
                            formPageCount={formCount}
                            projectData={projectData}
                            groups={groups}
                            group={groups[selectedGroup]}
                            selectedGroup={selectedGroup}
                            onClose={() => onClose()} />
                    :
                    popupType == "1" ?
                        <ViewFormData
                            {...props}
                            forms={forms}
                            formData={formData}
                            formPageCount={formCount}
                            projectData={projectData}
                            groups={groups}
                            group={groups[selectedGroup]}
                            selectedGroup={selectedGroup}
                            onClose={() => onClose()}
                        />
                        :
                        <div className="data-analysis-container">
                            {/* <div className="page-header-data">
                                <div className={window.innerWidth > 750 ? "project-title" : "project-title-mob"}>
                                    <ArrowLeftOutlined className="arrow" onClick={() => device ? navigate("/project") : navigate("/projectmobile")} />
                                    {projectData?.TopicDesc || projectData?.topicdesc}
                                </div>
                            </div> */}
                            <Row className='page-header m-0'>
                                <Col className={window.innerWidth > 750 ? "project-title" : "project-title-mob"} md={12}>
                                    <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => window.innerWidth > 750 ? navigate("/project") : navigate("/projectmobile")} />
                                    {projectData?.TopicDesc || projectData?.topicdesc}
                                </Col>
                                <Col className='p-0'></Col>
                            </Row>
                            <Tabs data={tabData} selectedTab={3} projectData={projectData} />
                            <div className="table-container">
                                {
                                    window.innerWidth > 750 ?
                                        <CustomTable
                                            header="Records"
                                            data={forms}
                                            tableProps={columns}
                                            isSearch={false}
                                            isFilter={false}
                                            isView={false}
                                            isMerge={forms.length > 0 ? true :  false}
                                            onAction={(type, data = null) => onAction(type, data)}
                                        ></CustomTable>
                                        :
                                        forms.map((items, index) => {
                                            return <AnalysisCard data={items} key={index} projectdata={location.state} onAction={onAction} />
                                        })

                                }
                            </div>
                        </div>
            }
        </>
    )
}