import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { TwitterPicker,HuePicker,CompactPicker } from 'react-color';
import SectionModal from '../../Editor/utils/SectionModal';
import { uploadChartAsImage } from '../../Editor/utils/UploadToServer';
import { downloadChartAsImage } from './downloadChartAsImage';

/**
 * @param param0 
 * @returns 
 */
const ErrorBarChart = ({ containerid,barstyle, data, interval, bardatastyle, title, titlecolor, width, height, yaxislabelstyle, xaxislabelstyle, gridcolor, gridstatus, yaxisstyle, xaxisstyle, xaxistext, yaxistext }) => {
    const svgRef = useRef(null);
    const [showGridLines, setShowGridLines] = useState(true);
	const [hideError, SethideError] = useState(true)
	const [hideColor, SethideColor] = useState(false)
	const [colorBar, SetcolorBar] = useState(barstyle.color)
	const [colorError, SetcolorError] = useState("#000000")
	const [colorLabel, SetcolorLabel] = useState("black")
    const [ylabel,setylabel] = useState(yaxistext)
    const [xlabel,setxlabel] = useState(xaxistext)
    const [actionPush, setactionPush] = useState('');

    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        uploadChartAsImage(data,containerid)
        setactionPush("")
    }

    useEffect(() => {
        console.log("Data:::::::::::",colorBar)
        // Define the chart dimensions
        const margin = { top: 20, right: 20, bottom: 30, left: 40 };
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;
        const intervaldata = interval;

        const svg = d3
            .select(svgRef.current)
            .append('svg')
            .attr('width', width + margin.right)
            .attr('height', height + margin.bottom)
            .append('g')
            .style("overflow", "scroll")
            .style('fill', colorBar)
            .attr('transform', `translate(0,20)`)


        // Create scales
        const xScale = d3
            .scaleBand()
            .domain(data.map((d) => d.variable))
            .range([margin.left, width - margin.right])
            .padding(0.1);

        const yScale = d3
            .scaleLinear()
            .domain([0, d3.max(data, (d) => parseInt(d.percentage) + parseInt(d.error))])
            .range([height - margin.bottom, margin.top]);


        // Draw x-axis
        const xAxis = svg
            .append('g')
            .attr('transform', `translate(${margin.left}, ${height})`)
            .call(d3.axisBottom(xScale))
            .selectAll("text")
            .style('font-weight', xaxislabelstyle.fontweight)
            .style('font-size', xaxislabelstyle.fontsize)
            .style("text-anchor", "end")
            .attr("dx", "0em")
            .attr("dy", "0em")
            .attr("transform", `rotate(${xaxislabelstyle.rotate})`);

        // Draw y-axis and set interval for ticks
        const yAxis = svg
            .append('g')
            .attr('transform', `translate(${margin.left + margin.left},${margin.bottom})`)
            .call(d3.axisLeft(yScale).tickValues(d3.range(0, d3.max(data, (d) => parseInt(d.percentage) + parseInt(d.error)), intervaldata)))
            .style('font-weight', yaxislabelstyle.fontweight)
            .style('font-size', yaxislabelstyle.fontsize);


        // Draw gridlines
        // svg
        // .append('g')
        // .attr('class', 'grid')
        // .style('color',gridcolor)
        // .attr('transform', `translate(50,${height})`)
        // .style("stroke-dasharray", "5 5")
        // .style("visibility",gridstatus)
        // .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''));

        svg
            .append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(80,30)`)
            .style("stroke-dasharray", "5 5")
            .style("color", gridcolor)
            .style("z-index", -1)
            .style("visibility", showGridLines ? "visible" : "hidden")
            .call(d3.axisLeft(yScale).tickSize(-width).tickFormat(''));

            const colorScale = d3.scaleOrdinal(d3.schemeCategory10); // Or any other color scheme

     
        // Add values on top of bars
        svg
            .selectAll('.bar-value')
            .data(data)
            .enter()
            .append('text')
            .attr('class', 'bar-value')
            .attr('transform', `translate(${margin.left-10},${margin.top-2})`)
            .attr('x', (d) => xScale(d.variable) + xScale.bandwidth() / 2)
            .attr('y', (d) => yScale(d.percentage) + 12)
            .attr('text-anchor', 'middle')
            .style('fill', bardatastyle.color)
            .style('font-weight', bardatastyle.fontweight)
            .style('font-size', bardatastyle.fontsize)
            .style('visibility', bardatastyle.visible)
            .text((d) => d.percentage)

             // Draw center dots
            svg.selectAll('.dot')
            .data(data)
            .enter()
            .append('circle')
            .attr('transform', `translate(${margin.left-10},${margin.top-2})`)
            .attr('class', 'dot')
            .attr('cx', (d) => xScale(d.variable) + xScale.bandwidth() / 2 + 10)
            .attr('cy', (d) => yScale(d.percentage) + 12)
            .attr('r',2);

            // Sort data by value
             data.sort((a, b) => b.value - a.value);

            // Exclude top two values
            const remainingData = data.slice(2);

            svg
            .selectAll('.bar-valuetop')
            .data(data)
            .enter()
            .append('text')
            .attr('class', 'bar-valuetop')
            .attr('transform', `translate(${margin.left},${margin.top+10})`)
            .attr('x', (d) => xScale(d.variable) + xScale.bandwidth() / 2)
            .attr('y', (d) => yScale(parseInt(d.percentage) + parseInt(d.error)))
            .attr('text-anchor', 'middle')
            .style('fill', bardatastyle.color)
            .style('font-weight', bardatastyle.fontweight)
            .style('font-size', bardatastyle.fontsize)
            .style('visibility', bardatastyle.visible)
            .text((d) => d.error ? d.percentage+d.error:"");
    
            svg
            .selectAll('.bar-valuebottom')
            .data(data)
            .enter()
            .append('text')
            .attr('class', 'bar-valuebottom')
            .attr('transform', `translate(${margin.left},${margin.top+20})`)
            .attr('x', (d) => xScale(d.variable) + xScale.bandwidth() / 2)
            .attr('y', (d) => yScale(parseInt(d.percentage) - parseInt(d.error)))
            .attr('text-anchor', 'middle')
            .style('fill', bardatastyle.color)
            .style('font-weight', bardatastyle.fontweight)
            .style('font-size', bardatastyle.fontsize)
            .style('visibility', bardatastyle.visible)
            .text((d) => d.error ?  d.percentage-d.error : "");

            svg
            .selectAll('.bar-line')
            .enter()
            .append('line')
            .attr('class', 'bar-line')
            .attr('transform', `translate(${margin.left},${margin.top+20})`)
            .attr('x1', 0)
            .attr('y1', yScale(d3.min(data, d => d.percentage - d.error)))
            .attr('x2', 20)
            .attr('y2', yScale(d3.min(data, d => d.percentage - d.error)))
            .attr('stroke', 'red')
            .attr('stroke-width', 1);
            

            // Error bars
            svg.selectAll('.error-bar')
            .data(data)
            .enter().append('line')
            .attr('class', 'error-bar')
            .attr('transform', `translate(${margin.left},${margin.bottom}) `)
            .attr('x1', d => xScale(d.variable) + xScale.bandwidth() / 2)
            .attr('y1', d => yScale(d.percentage-d.error) ) // Adjust as needed
            .attr('x2', d => xScale(d.variable) + xScale.bandwidth() / 2)
            .attr('y2', d => yScale(d.percentage+d.error)) // Adjust as needed
            .attr('stroke', colorError);
           
    

        //add x axis label
        svg.append('text')
            .attr("class", "x label")
            .attr("text-anchor", "middle")
            .attr("x", width / 2)
            .attr("y", height + margin.bottom + 10)
            .style('fill', colorLabel)
            .style('font-weight', xaxisstyle.fontweight)
            .style('font-size', xaxisstyle.fontsize)
            .text(xlabel)
            .on("click",() =>{
                let label = prompt("Edit X Axis Label")
                if(label){
                    setxlabel(label)
                }
              });

        //add y axis label
        svg.append("text")
            .attr("class", "y label")
            .attr("text-anchor", "end")
            .attr("y", margin.left)
            .attr("x", -height / 2)
            .attr("transform", "rotate(-90)")
            .style('fill', colorLabel)
            .style('font-weight', yaxisstyle.fontweight)
            .style('font-size', yaxisstyle.fontsize)
            .text(ylabel)
            .on("click",() =>{
                let label = prompt("Edit Y Axis Label")
                if(label){
                setylabel(label)
                }
              });

        //title of the chart
        svg.append('text')
            .attr('class', 'title')
            .attr('x', width / 2 + margin.left)
            .attr('y', height / 5 - 3)
            .attr('text-anchor', 'middle')
            .style('font-size', titlecolor.fontsize)
            .style('font-weight', titlecolor.fontweight)
            .style('fill', titlecolor.color)
            .text(title)



        return () => {
            d3.select(svgRef.current).selectAll('*').remove();
        };
    }, [data, showGridLines,hideError,colorBar,colorError,colorLabel,ylabel,xlabel]);

    return <>
        <div className="controller">
        {
                actionPush == "section" &&
                <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
            }
            <Button className='grid' style={{backgroundColor:colorBar}} onClick={() => SethideColor(!hideColor)}>
               Color
            </Button>
            
            <Button className='grid' onClick={() => setShowGridLines(!showGridLines)}>
                {showGridLines ? 'Hide Grid Lines' : 'Show Grid Lines'}
            </Button>
           
            <Button className='grid' onClick={() => downloadChartAsImage(containerid)}>
                Download
            </Button>
            <Button className='grid' onClick={() => setactionPush("section")}>
            Copy to Ediotor
            </Button>
        </div>  
        {hideColor ? 
        <div className='colorpicker'>
            <span>Error</span>
            <span style={{color:"blue", padding:"0px 5px"}} onClick={() => SetcolorError("#000000")}>Reset</span>
               <HuePicker
                color={colorError}
                onChange={(e)=>SetcolorError(e.hex)}
            />
             <span>Axis</span>
             <span style={{color:"blue", padding:"0px 5px"}} onClick={() => SetcolorLabel("#000000")}>Reset</span>
               <HuePicker
                color={colorLabel}
                onChange={(e)=>SetcolorLabel(e.hex)}
                presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']}
            />
            </div>
            : ''}  
        <div ref={svgRef} id={containerid}></div>
    </>
};

// ErrorBarChart.prototype = {
//     data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
//     width: PropTypes.number,
//     heigth: PropTypes.number,
//     interval: PropTypes.number,
//     barstyle: PropTypes.object,
//     bardatastyle: PropTypes.object,
//     gridcolor: PropTypes.string,
//     gridstatus: PropTypes.string,
//     yaxistext: PropTypes.string,
//     yaxislabelstyle: PropTypes.object,
//     yaxisstyle: PropTypes.object,
//     xaxistext: PropTypes.string,
//     xaxislabelstyle: PropTypes.object,
//     xaxisstyle: PropTypes.object,
//     title: PropTypes.string,
//     titlecolor: PropTypes.object
// }

/**
 * from data replace label as variable and value as percentage
 */
// ErrorBarChart.defaultProps = {
//     data: [
//         { label: 'param1', value: 30 },
//         { label: 'param2', value: 50 }],
//     width: "600",
//     height: "400",
//     interval: 1,
//     barstyle: { color: "#000", hover: "#ccc", interval: 5 },
//     bardatastyle: { fontsize: "16px", fontweight: "500", color: "#000" },
//     gridcolor: "#ccc",
//     gridstatus: "hidden",
//     yaxistext: "Y Label",
//     yaxislabelstyle: { fontsize: "14px", fontweight: "400", color: "red" },
//     yaxisstyle: { fontsize: "14px", fontweight: "600", color: "#000" },
//     xaxistext: "X Label",
//     xaxislabelstyle: { fontsize: "14px", fontweight: "400", color: "red" },
//     xaxisstyle: { fontsize: "14px", fontweight: "600", color: "#000" },
//     title: "Bar Chart",
//     titlecolor: { fontsize: "24px", fontweight: "600", color: "#000" }
// }
export default ErrorBarChart;
