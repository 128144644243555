import { cloneDeep, isArray } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'reactstrap'
import { setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem, FIELD_TYPES } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalPopup from '../../widgets/modal';
import { uploadPhoto } from '../profile/apiController';
import { getNextQuestion, submitForm, validateForm } from './apiController';
import ScrollMenuQuestion from './ScrollMenuQuestion';
import ScrollMenu from "react-horizontal-scrolling-menu";
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { stringify } from 'querystring';

interface SurveyProps {
	page: number,
	onSubmit(): void | any,
	formData: SurveyFormData,
	authenticatorData: any
}



interface SurveyFormData {
	form_type: string;
	id?: string,
	form_name?: string,
	form_desc?: string,
	form_fields: {
		field_selected_value?: any; id?: string, form_id?: string, action_enabled?: boolean, fieldId?: string, field_name: string; field_type: string; field_category: string; field_value?: any; validations?: any; field_max_value?: any; field_min_value: any;
	}[]
}

export default function SurveyQuestions(props: SurveyProps) {
	const dispatch = useDispatch();
	const FormElements = {
		fieldValue: {
			name: "fieldValue",
			label: "",
			isMandatory: true
		}
	}
	const initialValues = useMemo(() => ({
		fieldValue: ''
	}), []);
	const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem(FormElements, initialValues, { onValueChange });
	const [formFields, setformFields] = useState(props.formData.form_fields);
	const [page, setpage] = useState(props.page);
	const [isSubmit, setisSubmit] = useState(false);
	const [isSubmitted, setisSubmitted] = useState(false);
	const scrollRef = React.useRef<any>();
	const [submitData, SetSubmitData] = useState<any>([]);
	const [isEdit, SetisEdit] = useState<any>(false);
	const [editItem, seteditItem] = useState<any>({});
	const [progressView, SetProgressView] = useState<any>(false);
	const [errorMobile, SeterrorMobile] = useState(false);
	const [errorInteger, SeterrorInteger] = useState(false);
	const [errorEmail, SeterrorEmail] = useState(false);
	const [errorRange, SeterrorRange] = useState(false);
	const [errorMandatory, SeterrorMandatory] = useState(false);
	const [errorValidate, SeterrorValidate] = useState(false);
	const [startdate, setstartdate] = useState(new Date());
	const [formstartdate, setformstartdate] = useState(new Date());
	const [startendTime, SetstartendTime] = useState<any>([]);
	const [genuid, Setgenuid] = useState<any>([]);
	let isRange: any = false;
	let isMobile: any = false;
	let isEmail: any = false;
	let isInteger: any = false;


	/**
	 * useefect set localstorage at once
	 */
	// useEffect(() => {
	// 	SetSubmitData(JSON.parse(localStorage.getItem("answerdata") || "[]"));
	// 	setformFields(JSON.parse(localStorage.getItem("formdatastore") || "[]").length != 0 ? JSON.parse(localStorage.getItem("formdatastore") || "[]") : props.formData.form_fields);
	// 	setpage(JSON.parse(localStorage.getItem("indexno") || "[]").pageno);
	// 	typeof (JSON.parse(localStorage.getItem("formdatastore") || "[]")[JSON.parse(localStorage.getItem("indexno") || "[]").pageno]) != "object" ?
	// 	values.fieldValue = JSON.parse(localStorage.getItem("formdatastore") || "[]")[JSON.parse(localStorage.getItem("indexno") || "[]").pageno]?.field_selected_value.value
	// 	:
	// 	values.fieldValue = JSON.parse(localStorage.getItem("formdatastore") || "[]")[JSON.parse(localStorage.getItem("indexno") || "[]").pageno]?.field_selected_value;

	// },[1]);

	useEffect(() => {
		let pathName = window.location.href;
		let tmp = window.location.href.slice(window.location.href.lastIndexOf("&"), window.location.href.length);
		tmp = tmp.replace('&', '');
		tmp = tmp.replace(/%20/g, ' ');

		Setgenuid(tmp.split('='))
		console.log(tmp.split('='))
		let date = new Date();
		setformstartdate(date)
		setstartdate(date);
		localStorage.setItem("versionid", Date.now().toString());
	}, [1])


	/**
	 * Avoid page refresh
	 * @returns 
	 */
	window.onbeforeunload = function () {
		return "you can not refresh the page";
	}


	/**
	 * get data and display next field on validating previous data
	 */
	const getData = async () => {
		console.log(props.formData.form_type)
		//if(errorEmail == true || errorMandatory == true || errorMobile == true || errorRange == true || errorValidate == true){
		//	console.log("Error");
		//}else{
		console.log(page + 1, formFields.length)
		if (page + 1 === formFields.length) {
			if (props.formData.form_type !== 'root') {
				try {

					dispatch(setLoading(true));
					let fieldId: any = '';
					let answer: any = '';
					props.authenticatorData.forEach(obj => {
						fieldId = fieldId + obj.id + ',';
						answer = answer + obj.field_name == genuid[0] ? { name: genuid[1], label: genuid[1] } : obj.field_selected_value + ',';
					});

					formFields.forEach((obj, index) => {
						if (page > index) {
							fieldId = fieldId + obj.id + ',';
							answer = answer + obj.field_name == genuid[0] ? { name: genuid[1], label: genuid[1] } : obj.field_selected_value + ',';
						} else if (page === index) {
							fieldId = fieldId + obj.id + ',';
							answer = answer + ((obj.field_type === 'number' ? parseInt(values.fieldValue) : obj.field_type === 'dropDown' || obj.field_type === 'dropDownPreviousResponses' ? obj.field_name == genuid[0] ? genuid[1] : values.fieldValue.value : values.fieldValue)) + ',';
							let objv = {
								id: obj.id,
								value: obj.field_type === 'number' ? parseInt(values.fieldValue) : obj.field_type === 'dropDown' || obj.field_type === 'dropDownPreviousResponses' ? genuid[0] == obj.field_name ? genuid[1] : values.fieldValue.value : values.fieldValue,
								sourceformid: props.formData.id,
								fieldtype: obj.field_type
							}

							if (submitData.filter(item => item.id == obj.id).length == 0) {
								SetSubmitData([...submitData, objv]);
								localStorage.setItem("answerdata", JSON.stringify([...submitData, objv]))
							}

							submitData.filter(item => item.id == obj.id).map(itemvalue => {
								if (itemvalue.id == obj.id) {
									itemvalue.value = obj.field_type === 'number' ? parseInt(values.fieldValue) : obj.field_type === 'dropDown' || obj.field_type === 'dropDownPreviousResponses' ? genuid[0] == obj.field_name ? genuid[1] : values.fieldValue.value : values.fieldValue;
								} else {
									SetSubmitData([...submitData, objv]);
									localStorage.setItem("answerdata", JSON.stringify([...submitData, objv]));
								}
							})
						}
					});

					//checkFrontEndValidation(obj,index);

					fieldId = fieldId.substring(0, fieldId.length - 1);
					answer = answer.substring(0, answer.length - 1);

					let response = await getNextQuestion(props.formData.id, fieldId, answer);
					if (!response.data) {
						setisSubmit(true)
					}

					response.data.field_category = response.data?.field_category || '';

					let temp: any = cloneDeep(formFields);
					temp[page].field_selected_value = values.fieldValue;
					temp = temp.concat(response.data);
					if (temp[page + 1].field_type === 'boolean') {
						values.fieldValue = false;
					}
					updateValue({ fieldValue: '' });
					console.log("$$$$$$$$$$$$$$$")
					console.log(temp)
					setformFields(temp);
					setpage(page + 1);
					dispatch(setLoading(false));
				} catch (e: any) {
					if (e?.response?.data?.data === null) {
						let temp: any = cloneDeep(formFields);
						temp[page].field_selected_value = values.fieldValue;
						setformFields(temp);
						setisSubmit(true);
					}
					dispatch(setLoading(false));
				}
			} else if (props.formData.form_type == 'root') {
				console.log(props.formData.form_type)

				try {
					dispatch(setLoading(true));
					let fieldId: any = '';
					let answer: any = '';
					props.authenticatorData.forEach(obj => {
						fieldId = fieldId + obj.id + ',';
						answer = answer + obj.field_name == genuid[0] ? { name: genuid[1], label: genuid[1] } : obj.field_selected_value + ',';
					});

					formFields.forEach((obj, index) => {
						if (page > index) {
							fieldId = fieldId + obj.id + ',';
							answer = answer + obj.field_name == genuid[0] ? { name: genuid[1], label: genuid[1] } : obj.field_selected_value + ',';
						} else if (page === index) {
							fieldId = fieldId + obj.id + ',';
							answer = answer + ((obj.field_type === 'number' ? parseInt(values.fieldValue) : obj.field_type === 'dropDown' || obj.field_type === 'dropDownPreviousResponses' ? genuid[0] == obj.field_name ? genuid[1] : values.fieldValue.value : values.fieldValue)) + ',';
							let objv = {
								id: obj.id,
								value: obj.field_type === 'number' ? parseInt(values.fieldValue) : obj.field_type === 'dropDown' || obj.field_type === 'dropDownPreviousResponses' ? genuid[0] == obj.field_name ? genuid[1] : values.fieldValue.value : values.fieldValue,
								sourceformid: props.formData.id,
								fieldtype: obj.field_type
							}

							if (submitData.filter(item => item.id == obj.id).length == 0) {
								SetSubmitData([...submitData, objv]);
								localStorage.setItem("answerdata", JSON.stringify([...submitData, objv]))
							}

							submitData.filter(item => item.id == obj.id).map(itemvalue => {
								if (itemvalue.id == obj.id) {
									itemvalue.value = obj.field_type === 'number' ? parseInt(values.fieldValue) : obj.field_type === 'dropDown' ? values.fieldValue.value : values.fieldValue;
								} else {
									SetSubmitData([...submitData, objv]);
									localStorage.setItem("answerdata", JSON.stringify([...submitData, objv]));
								}
							})
						}

					});

					//checkFrontEndValidation(obj,index);
					fieldId = fieldId.substring(0, fieldId.length - 1);
					answer = answer.substring(0, answer.length - 1);

					let response = await getNextQuestion(props.formData.id, fieldId, answer);
					if (!response.data) {
						setisSubmit(true)
					}
					console.log("$$$$$$$$$$$$$$$")
					if (response.data) {
						response.data.field_category = response.data?.field_category || '';
					}
					console.log(response)

					let temp: any = cloneDeep(formFields);

					temp[page].field_selected_value = values.fieldValue;
					if (response.data) {
						temp = temp.concat(response.data);
					}



					updateValue({ fieldValue: '' });
					console.log("&&&&&&&&&&&&&&&&")
					console.log(temp)
					setformFields(temp);

					if (response.data) {
						setpage(page + 1);
					}
					if (temp[page + 1] && temp[page + 1].field_type === 'boolean') {
						values.fieldValue = false;
					}
					dispatch(setLoading(false));
				} catch (e: any) {
					if (e?.response?.data?.data === null) {
						let temp: any = cloneDeep(formFields);
						temp[page].field_selected_value = values.fieldValue;
						setformFields(temp);
						setisSubmit(true);
					}
					dispatch(setLoading(false));
				}
			} else {
				let temp: any = cloneDeep(formFields);
				temp[page].field_selected_value = values.fieldValue;
				let objv = {
					id: temp[page].id,
					value: temp[page].field_selected_value,
					sourceformid: props.formData.id,
					fieldtype: temp[page].field_type
				}
				if (submitData.filter(item => item.id == temp[page].id).length == 0) {
					SetSubmitData([...submitData, objv]);
					localStorage.setItem("answerdata", JSON.stringify([...submitData, objv]))
				}

				submitData.filter(item => item.id == temp[page].id).map(itemvalue => {
					if (itemvalue.id == temp[page].id) {
						itemvalue.value = temp[page].field_selected_value;
					} else {
						SetSubmitData([...submitData, objv]);
						localStorage.setItem("answerdata", JSON.stringify([...submitData, objv]))
					}
				})

				console.log("@@@@@@@@@@@@@@@")
				console.log(temp)
				setformFields(temp);

				setisSubmit(true);
			}
		} else {

			let temp: any = cloneDeep(formFields);
			temp[page].field_selected_value = values.fieldValue;
			values.fieldValue = '';
			setformFields(temp);

			let obj = {
				id: temp[page].id,
				value: temp[page].field_selected_value,
				sourceformid: temp[page].source_form_id,
				fieldtype: temp[page].field_type
			}

			if (submitData.filter(item => item.id == temp[page].id).length == 0) {
				SetSubmitData([...submitData, obj]);
				localStorage.setItem("answerdata", JSON.stringify([...submitData, obj]))
			}

			submitData.filter(item => item.id == temp[page].id).map(itemvalue => {
				if (itemvalue.id == temp[page].id) {
					itemvalue.value = temp[page].field_selected_value;
				} else {
					SetSubmitData([...submitData, obj]);
					localStorage.setItem("answerdata", JSON.stringify([...submitData, obj]))

				}
			})
			if (temp[page + 1].field_type === 'boolean') {
				values.fieldValue = false;
			}
			setpage(page + 1);
		}
		//}
		// updateValue({ fieldValue: "" });
		localStorage.setItem("formdatastore", JSON.stringify(formFields));
	}


	/**
	 * on click next verify the data and call getData function call for next question
	 * @returns 
	 */
	async function next() {
		if (formFields[page]?.field_name == genuid[0]) {
			updateValue({ fieldValue: { name: genuid[1], label: genuid[1] } })
			getData();
		} else {
			let endDate = new Date();
			console.log(endDate)
			SetstartendTime(prevState => [...prevState, { "start": startdate, "end": endDate }])
			let startDate = new Date();
			setstartdate(startDate);
			console.log(startendTime)
			localStorage.setItem("formdatastore", JSON.stringify(formFields));
			localStorage.setItem("indexno", JSON.stringify({ pageno: page }));
			if (formFields[page].validations == undefined) {
				getData();
			}
			formFields[page].validations && formFields[page]?.validations.map((items, i) => {
				if (items.startsWith('FE')) {
					const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
					const numberRegex = /^[0-9]\d{9}$/;
					if (items == "FE-EmailFormat") {
						//formFields[page].field_value = values.fieldValue;
						if (!emailRegex.test(values.fieldValue)) {
							SeterrorEmail(true);
							isEmail = true;
							updateError(page, { hasError: true, errorMsg: values.fieldValue == undefined || values.fieldValue == "" ? 'Email cannot be empty' : 'Please entry valid Email' });
							return;
						} else {
							SeterrorEmail(false);
							console.log(items)

							if (formFields[page]?.validations.length == i + 1 && !errorMandatory) {
								updateError(page, { hasError: false, errorMsg: '' });
								getData();
							}
						}
					} else if (items == "FE-MobileNumber"){
						if (!numberRegex.test(values.fieldValue)) {
							SeterrorMobile(true);
							isMobile = true;
							updateError(page, { hasError: true, errorMsg: values.fieldValue == undefined || values.fieldValue == "" ? 'Mobile cannot be empty' : 'Please entry valid Mobile' });
							return;
						} else {
							SeterrorMobile(false);
							if (formFields[page]?.validations.length == i + 1) {
								updateError(page, { hasError: false, errorMsg: '' });
								getData();
							}
						}
					} else if (items == "FE-Integer") {
						if (!numberRegex.test(values.fieldValue)) {
							SeterrorInteger(true);
							isInteger = true;

							updateError(page, { hasError: true, errorMsg: values.fieldValue == undefined || values.fieldValue == "" ? 'Mobile cannot be empty' : 'Please entry valid Mobile' });
							return;
						} else {
							SeterrorInteger(false);
							if (formFields[page]?.validations.length == i + 1) {
								updateError(page, { hasError: false, errorMsg: '' });
								getData();
							}
						}
					} else if (items == "FE-Mandatory") {

						if (values.fieldValue.length == 0) {
							SeterrorMandatory(true);
							updateError(page, { hasError: true, errorMsg: "Field cannot be empty" });
							return;
						} else {
							SeterrorMandatory(false);
							console.log(errorRange);
							if (formFields[page]?.validations.length == i + 1 && !isRange && !isEmail && !isMobile && !isInteger) {
								console.log(errorRange)
								updateError(page, { hasError: false, errorMsg: '' });
								getData();
							}
						}
					} else if (items == "FE-Range") {
						const min = formFields[page].field_min_value;
						const max = formFields[page].field_max_value;
						console.log(parseInt(values.fieldValue) < parseInt(min))
						console.log(parseInt(values.fieldValue) > parseInt(max))

						// Check the range
						if (parseInt(values.fieldValue) < parseInt(min) || parseInt(values.fieldValue) > parseInt(max)) {
							SeterrorRange(true);
							console.log(errorRange);
							isRange = true;
							console.log(isRange);
							updateError(page, { hasError: true, errorMsg: `Values should be between ${min} to ${max}` });
							return;
						} else {
							SeterrorRange(false);
							console.log(errorRange)
							if (formFields[page]?.validations.length == i + 1) {
								updateError(page, { hasError: false, errorMsg: '' });
								getData();
							}
						}

					} else {

					}
				} else if (items.startsWith('BE')) {
					const resObj = {
						field_id: formFields[page].id,
						value: values.fieldValue,
						version_id: Date.now()
					}

					//console.log(resObj);
					const resp = validateForm("", [resObj]);
					resp.then(res => {
						if (res?.success == true) {
							if (formFields[page]?.validations.length == i + 1) {
								SeterrorValidate(false);
								getData();
							}
						} else {
							SeterrorValidate(true);
							updateError(page, { hasError: true, errorMsg: "Value already used" });
							return;
						}
					}).catch(err => {
						console.log(err)
					});
				} else {

					if (formFields[page]?.validations.length == i + 1) {
						//getData();
					}

				}

			})
			//: getData();
		}
	}

	/**
	 * on value change
	 */
	function onValueChange(name, value, values, fieldValues = null) {
		console.log(value)
		return [{
			...values,
			[name]: value
		}]
	}

	function getValues(data, type) {
		let valuesData: any = [];
		console.log(data)
		if (type == "prev") {
			data.forEach((element, i) => {
				valuesData.push({
					label: element.value,
					value: element.value
				});
			});
		} else {
			data.forEach((element, i) => {
				valuesData.push({
					label: element,
					value: element
				});
			});
		}

		return valuesData;
	}

	const uploadDoc = async (data, resolve, reject) => {
		try {
			dispatch(setLoading(true));
			let response = await uploadPhoto(data);
			if (response.mediaUrl) {
				resolve(response.mediaUrl);
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			reject('');
			dispatch(setLoading(false));
		}
	}

	/**
	 * Edit form modal confirm action
	 * Check item from formFields
	 * if exist remove the next items from the form and submitData
	 * @formFields Array of formitems 
	 * @submitData Array of answers
	 */
	const EditForm = async () => {
		SetstartendTime(startendTime.slice(0, editItem.index))
		let date = new Date();
		setstartdate(date);

		formFields.map((getformItem, i) => {
			console.log(editItem.index)
			if (getformItem.id == editItem["id"]) {
				setpage(i);
				values.fieldValue = formFields[i].field_selected_value;
				for (var j = i; j < formFields.length; j++) {
					console.log(i + 1, formFields[i].action_enabled);
					console.log(formFields);
					formFields.splice(i + 1, formFields.length - (i + 1));
					formFields.length = i + 1;
					submitData.splice(i + 1);
				}
			}


		})
		localStorage.setItem("indexno", JSON.stringify({ pageno: 0 }));
		localStorage.setItem("answerdata", JSON.stringify(submitData));
		localStorage.setItem("formdatastore", JSON.stringify(formFields));
		SetisEdit(false);

		console.log(formFields)
	}

	/**
	 * onclick previous button
	 */
	const PreviousForm = async () => {

		SetstartendTime(startendTime.slice(0, editItem.index))
		let date = new Date();
		setstartdate(date);
		console.log(submitData)

		values.fieldValue = formFields[submitData.length - 1].field_selected_value;
		if (formFields[page]?.field_name == genuid[0]) {
			updateValue({ fieldValue: { name: genuid[1], label: genuid[1] } })
		}
		setpage(submitData.length - 1);
		formFields.splice(submitData.length-1+1,formFields.length - (submitData.length-1 + 1));
		formFields.length = submitData.length-1+1;
		submitData.splice(submitData.length - 1);
		console.log(submitData)
		localStorage.setItem("indexno", JSON.stringify({ pageno: 0 }));
		localStorage.setItem("answerdata", JSON.stringify(submitData));
		localStorage.setItem("formdatastore", JSON.stringify(formFields));
		SetisEdit(false);

		console.log(submitData)
	}

	/**
	 * 
	 * @param data onclick callback fucntion get the value of current selected element
	 */
	const getAnswerClicked = (data: string) => {
		SetisEdit(true);
		seteditItem(data);
	}


	/**
	 * submit datat to server
	 */
	const submitSurvey = async () => {
		try {
			dispatch(setLoading(true));
			let fieldsArray: any = [];
			console.log(formFields)
			let temp: any = cloneDeep(formFields);
			let tempAuthData: any = cloneDeep(props.authenticatorData || []);
			console.log(formFields)
			tempAuthData.forEach(obj => {
				if (obj.field_type === 'dropDown') {
					obj.field_selected_value = obj.field_selected_value.value
				}
				fieldsArray.push({
					id: obj.id || obj.fieldId,
					field_selected_value: obj.field_selected_value
				})
			})

			temp.forEach(async (obj, i) => {
				if (obj.field_type === 'fileUpload') {
					const formData = new FormData();
					formData.append('file', obj.field_selected_value && obj?.field_selected_value[0]);
					obj.field_selected_value && await new Promise((resolve, reject) => {
						uploadDoc(formData, resolve, reject);
					}).then((result: any) => {
						obj.field_selected_value = result;
					}).catch(error => {
						obj.field_selected_value = error;
					});
				} else if (obj.field_type === 'dropDown' || obj.field_type === 'dropDownPreviousResponses') {
					obj.field_selected_value = obj.field_selected_value.value
				}

				console.log(startendTime)
				console.log(obj)
				fieldsArray.push({
					id: obj.id || obj.fieldId,
					field_selected_value: obj.field_selected_value,
					response_start_time: startendTime[i].start,
					response_end_time: startendTime[i].end
				})

				console.log(obj.field_type, obj.field_selected_value)
				if (fieldsArray.length === temp.length) {
					let versionid: number = Number(localStorage.getItem("versionid"));
					let enddate = new Date();
					const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

					let reqObj = {
						"form_id": props.formData.id,
						"version_id": versionid,
						"fields": fieldsArray,
						"start_time": formstartdate,
						"end_time": enddate,
						 "time_zone":tz
					}
					console.log(reqObj)
					let response = await submitForm(reqObj);

					if (response.success) {
						console.log(response)
						showToaster(toasterTypes.SUCCESS, response.message);
						setisSubmit(false);
						setisSubmitted(true);
						SetSubmitData([]);
						SetProgressView(true);
						localStorage.setItem("indexno", JSON.stringify({ pageno: 0 }));
						localStorage.setItem("answerdata", JSON.stringify([]));
						localStorage.setItem("formdatastore", JSON.stringify([]));
					}
					dispatch(setLoading(false));
				}
			});
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	useEffect(() => {
		const el = scrollRef.current;
		if (el) {
			const onWheel = e => {
				if (e.deltaY == 0) return;
				e.preventDefault();
				el.scrollTo({
					left: el.scrollLeft + e.deltaY,
					behavior: "smooth"
				});
			};
			el.addEventListener("wheel", onWheel);
			return () => el.removeEventListener("wheel", onWheel);
		}
	}, []);


	const slide = (shift) => {
		scrollRef.current.scrollLeft += shift;
	};

	useEffect(() => {
		console.log(formFields);
		// if(!errorEmail && !errorMandatory){
		// 	getData();
		// }		
	}, [errorRange, errorMandatory, errorEmail, errorMobile, errorValidate, errorInteger, formFields])


	return (
		<div className='survey-form'>

			{isSubmit &&
				<ModalPopup
					modalType='delete'
					modalText="There are no more actions as per the answers submitted."
					actionText="Do you want to submit the form?"
					closeModal={() => setisSubmit(false)}
					onAction={() => submitSurvey()}
				/>
			}

			{isEdit &&
				<ModalPopup
					modalType='Edit'
					modalText="Editing will clear all the answers after this."
					actionText="Do you want to continue editing the form?"
					closeModal={() => SetisEdit(false)}
					onAction={() => EditForm()}
				/>
			}


			<Row className='m-0 mb-3'>
				<Col className='p-0' lg={6} md={12} xs={12}>
					<div className='welcome-text'>Welcome</div>
					<div className='survey-title'>{props.formData.form_name}</div>
					<div className='survey-description'>{props.formData.form_desc}</div>
				</Col>
				<Col className='p-0' lg={6} md={12} xs={12}>
					{!progressView && window.innerWidth < 768 && submitData.length != 0 ?
						<div className='progress-bar-container-mob'>

							<ul className='progressbar-ul-mob' ref={scrollRef}>
								{
									submitData.length != 0 ?
										submitData?.slice(0).reverse().map((items, i) => {
											items.index = (submitData.length - 1) - i;
											return <ScrollMenuQuestion data={items} getAnswerClicked={getAnswerClicked} />
										})
										:
										<ScrollMenuQuestion data={"No Data Found"} getAnswerClicked={getAnswerClicked} />
								}
							</ul>

						</div>
						:
						<img className='survey-tool' src={`${process.env.PUBLIC_URL}/images/survey_tools.svg`} alt='logo' />
					}
				</Col>
			</Row>
			{!progressView && window.innerWidth > 800 ?
				<div className='progress-bar-container'>

					<button className="progress-previous-btn" onClick={() => slide(-50)}>
						<CaretLeftOutlined color='#fff' />
					</button>

					<ul className='progressbar-ul' ref={scrollRef}>
						{
							submitData.length != 0 ?
								submitData?.map((items, i) => {
									items.index = i;
									return <ScrollMenuQuestion data={items} getAnswerClicked={getAnswerClicked} />
								})
								:
								<ScrollMenuQuestion data={"No Data Found"} getAnswerClicked={getAnswerClicked} />
						}

					</ul>


					<button className="progress-next-btn" onClick={() => slide(+50)}>
						<CaretRightOutlined />
					</button>
				</div>
				: ""}

			{isSubmitted ?
				<div className='questions-container row flex-column m-0 justify-content-center align-items-center pt-0'>
					<img src={`${process.env.PUBLIC_URL}/images/awesome.svg`} alt="awesome" className="awesome-img" />
					<div className='completed-text1'>Successfully submitted your form.</div>
				</div> :
				<div className={window.innerWidth > 800 ? 'questions-container' : 'questions-container-mobile'}>
					<div className='category-text'>Category: <span className='category-name'>{formFields[page]?.field_category || '-'}</span></div>
					<div className='question-height'>
						<div className='field-name'>{formFields[page]?.field_name}</div>
						<div className="answers">
							<FieldItem
								{...FormElements.fieldValue}
								value={genuid && genuid[0] == formFields[page]?.field_name ? [{ name: genuid[1], label: genuid[1] }] : values.fieldValue}
								values={formFields[page]?.field_type === 'dropDownPreviousResponses' ? getValues(formFields[page]?.field_value || [], "prev") : getValues(formFields[page]?.field_value || [], "")}
								isListed={true}
								isDisabled={formFields[page]?.field_name == genuid[0] ? true : false}
								isNumber={formFields[page]?.field_type === "number" ? true : false}
								placeholder={(formFields[page]?.field_type === 'number' || formFields[page]?.field_type === 'textBox' || formFields[page]?.field_type === 'textArea') ? 'Enter' : 'Select'}
								type={formFields[page]?.field_type === 'fileUpload' ? FIELD_TYPES.FILE_UPLOAD : formFields[page]?.field_type === 'dateField' ? FIELD_TYPES.DATE_PICKER : formFields[page]?.field_type === 'dropDown' ? FIELD_TYPES.DROP_DOWN : formFields[page]?.field_type === 'dropDownPreviousResponses' ? FIELD_TYPES.DROP_DOWN : formFields[page]?.field_type === 'boolean' ? FIELD_TYPES.BOOLEAN : formFields[page]?.field_type === 'radioButton' ? FIELD_TYPES.RADIO_BUTTON : formFields[page]?.field_type === 'checkBox' ? FIELD_TYPES.CHECK_BOX : formFields[page]?.field_type === 'textArea' ? FIELD_TYPES.TEXT_AREA : formFields[page]?.field_type === "time"
									? FIELD_TYPES.TIME_PICKER
									: formFields[page]?.field_type === "dateTime"
										? FIELD_TYPES.DATE_TIME_PICKER : FIELD_TYPES.TEXT}
								touched={fields[page] && fields[page]?.hasError}
								error={fields[page] && fields[page]?.errorMsg}
								onChange={(...e) => handleChange(FormElements.fieldValue.name, ...e, formFields[page])}
							/>
						</div>

					</div>
					<div className='button-bottom'>

						{
							submitData.length > 0 ?
								<CustomButton
									text="Previous"
									onClick={() => PreviousForm()}>
								</CustomButton> : ""
						}

						<CustomButton
							type="primary"
							text="Next"
							onClick={() => next()}>
						</CustomButton>


					</div>
				</div>
			}
		</div>
	)
}
