import html2canvas from 'html2canvas';
import { showToaster, toasterTypes } from '../../../widgets';
import PushToEditor from './PushToEditor';
import { uploadSelectedFile } from '../../submissions/apiController';

/**
 * get contauiner for chart convert to blob and push to server
 * @param {*} data 
 */
export const uploadChartAsImage = (data,container) => {
     html2canvas(document.querySelector("#"+container)).then(canvas => {
       canvas.toBlob(async blob => {
         // Create a form data object to upload the image
         const formData = new FormData();
         formData.append('file', blob, 'chart.png');
         //upload to server
         let uploadResponse = await uploadSelectedFile("png",formData)
         console.log(uploadResponse.mediaUrl)

         let imageDoc =  {
          "type": "figure",
          "attrs": {
            "id": "",
            "track": [],
            "group": "",
            "viewid": ""
          },
          "content": [
            {
              "type": "image",
              "attrs": {
                "id": "75ebd563-f450-4f27-be1f-b009c7bec152",
                "src": "https://go.coguide.in/c1/download/"+uploadResponse.mediaUrl,
                "alt": "",
                "title": null,
                "extraData": {},
                "fileid": null,
                "aria-describedby": "0e4c8928-b85f-4ff0-90b7-49471251d023",
                "aria-description": ""
              }
            }
          ]
        };
        let pushResponse = PushToEditor(imageDoc, data.section.value)
        if (pushResponse) {
            showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
        }

       }, 'image/png');
     });
   };

