import { Col, Row } from "react-bootstrap";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import ModalComponent from "../common/Modal/modal";
import { CustomButton } from "../../widgets";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { VariableFormElements } from "./FieldElements";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { getAnalysisVariables, getFeatureList, getFormulaList, getOutcomeVariable, getSplitOnList } from "./apiController";

export default function VariableModal(props) {
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem(VariableFormElements, {}, { onValueChange });
    const dispatch = useDispatch();
    const [selectedItems, SetselectedItems] = useState<any>(props?.selectedValues);
    const [filtervariableData, SetFilterVariableData] = useState<any>([]);
    const [variableData, SetVariableData] = useState<any>([]);
    const [outcomevariableData, SetOutcomeVariableData] = useState<any>([]);
    const [expVariables, SetExpVariables] = useState<any>([]);
    const { data, id } = props;
    let selectData: any = [];

    useEffect(() => {
        console.log(props)
        getVariables("");
    }, [])

    useEffect(() => {

    }, [filtervariableData])

    /**
     * fetch variable list
     * if outcome varaiable get the lis tof outcome variables  API call
     */
    const getVariables = async (value) => {
        try {
            dispatch(setLoading(true));
            let response = await getAnalysisVariables(data?.formData?.id || props.formData?.id);
            if (props?.variabletype == "explanatory") {
                let selectedArray: any = [];
                selectedItems && selectedItems.map(item => selectedArray.push(item.value))
                if (value == "" && !values.variable) SetselectedItems([]);
                updateValue({ variable: value != "" ? value : selectedArray })
                let outComeArray: any = [];
                let fieldtype = response.data.filter(fieldtype => fieldtype.field_name == (value == "" && selectedArray ? selectedArray[0] : value[0]))
                await response.data.filter(itemvalue => value == "" && selectedArray?.length == 0 ? itemvalue.field_name != value : fieldtype[0].field_type == "number" ? itemvalue.field_type == fieldtype[0].field_type : itemvalue.field_type != "number").map((item) => {
                    if (item?.field_name != props.outcomeSelected) {
                        outComeArray.push({ label: item?.field_name, value: item?.field_name, id: item?.id, type:item?.field_type })
                    }
                })
                SetVariableData(outComeArray);
                SetFilterVariableData(outComeArray);
            } else if (props?.variabletype == "formula") {
                let responseFormula = await getFormulaList();
                updateValue({ variable: props?.selectedValues })
                let outComeArray: any = [];
                await responseFormula.map((item) => {
                    outComeArray.push({ label: item?.name, value: item?.key })
                })
                SetVariableData(outComeArray);
                SetFilterVariableData(outComeArray);
            } else if (props?.variabletype == "spliton") {
                //let responseFormula = await getSplitOnList();
                let responseFormula = await getAnalysisVariables(data?.formData?.id || props.formData?.id);

                updateValue({ variable: props?.selectedValues })
                let outComeArray: any = [];
               outComeArray.push({ label: "None", value: "None", id: "none"})
                await responseFormula.data.filter(itemvalue => itemvalue.field_type != "number").map((item) => {
                    outComeArray.push({ label: item?.field_name, value: item?.field_name, id: item?.id, type:item?.field_type })
                })
                SetVariableData(outComeArray);
                SetFilterVariableData(outComeArray);
            } else if (props?.variabletype == "feature") {
                let responseFormula = await getFeatureList();
                updateValue({ variable: props?.selectedValues })
                let outComeArray: any = [];
                await responseFormula.map((item) => {
                    outComeArray.push({ label: item?.name, value: item?.key })
                })
                SetVariableData(outComeArray);
                SetFilterVariableData(outComeArray);
            } else {
                let responseOutcome = await getOutcomeVariable(id);
                let outComeArray: any = [];
                console.log(responseOutcome)
                await responseOutcome.fields.map((item) => {
                    let outcomeData = response.data.filter(items => items.id == item.field_id);
                    outComeArray.push({ label: outcomeData[0]?.field_name, value: outcomeData[0]?.field_name, id: outcomeData[0]?.id, type:outcomeData[0]?.field_type })
                })
                SetFilterVariableData(outComeArray);
            }
            dispatch(setLoading(false))
        } catch (e: any) {
            dispatch(setLoading(false))
            console.log(e)
        }
    }

    /**
    * handle on change values of form elements
    * @param name 
    * @param value 
    * @param values 
    * @param fieldValues 
    * @returns 
    */
    function onValueChange(name, value, values, fieldValues = null) {
        const resetValue = {}
        console.log(name, value)
        if (name == "search") {
            if (value) {
                // getVariables();
                console.log(variableData)
                let data = variableData.filter(item => item.field_name.toLowerCase().includes(value.toLowerCase()));
                console.log(data)
                SetFilterVariableData(data);
            } else {
                getVariables("");
            }
        }
        else if (name == "variableout") {
            let existData = filtervariableData.filter((item) => item.label == value);
            SetOutcomeVariableData(existData)
        } else if (name == "variable") {
            if (values.variable?.length == 0 && props?.variabletype == "explanatory") {
                getVariables(value)
            }
        }
        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    const getExpoVariable = () => {
        let expoArray: any = [];
        values.variable && values.variable.map(item => {
            let existData = filtervariableData.filter((itemfilter) => itemfilter.label == item);
            expoArray.push(existData[0])
        })
        props.getExplanatory(props?.variabletype != "explanatory" ? values.variable : expoArray);
        props.onClose();
    }


    return (
        <>
            <ModalComponent
                width="95%"
                isOpen={true}
                onClose={() => props.onClose()}
                header={props?.variabletype == "outcome" ? "Outcome Variable" : "Explanatory Variable"}
                centered={true}
                customClass='invite-modal variable-modal'
                body={
                    <>
                        <div className="variable-container">
                            {/* <div className="search">
                                <FieldItem
                                    name={"search"}
                                    type={FIELD_TYPES.SEARCH}
                                    value={values['search']}
                                    onChange={(...e) => handleChange("search", ...e)}
                                />
                            </div> */}
                            <div className="variable-list">
                                {
                                    props?.variabletype != "outcome" ?
                                        // filtervariableData && filtervariableData.map((item, index) => {
                                        // return (
                                        <Row className="variable-row">
                                            <Col md={12}>
                                                <div className="variable-items">
                                                    <FieldItem
                                                        name={"variable"}
                                                        value={values["variable"]}
                                                        values={filtervariableData}
                                                        onChange={(...e) => handleChange("variable", ...e)}
                                                        type={props.variabletype == "outcome" ? FIELD_TYPES.RADIO_BUTTON : FIELD_TYPES.CHECK_BOX}
                                                    />
                                                    {/* <span className="variable-name">{item?.field_name}</span> */}
                                                    {/* <span className="variable-count">10 Values</span> */}
                                                </div>
                                            </Col>
                                            {/* <Col md={1}>
                                                    <div className="select-item">
                                                        
                                                    </div>
                                                </Col> */}
                                        </Row>
                                        //)

                                        :
                                        <Row className="variable-row1">
                                            <Col md={12}>
                                                <div className="variable-items1">
                                                    <FieldItem
                                                        name={"variableout"}
                                                        value={values["variableout"]}
                                                        values={filtervariableData}
                                                        onChange={(...e) => handleChange("variableout", ...e)}
                                                        type={props.variabletype == "outcome" ? FIELD_TYPES.RADIO_BUTTON : FIELD_TYPES.CHECK_BOX}
                                                    />
                                                    {/* <span className="variable-name">{item?.field_name}</span> */}
                                                    {/* <span className="variable-count">10 Values</span> */}
                                                </div>
                                            </Col>
                                            {/* <Col md={1}>
                                                    <div className="select-item">
                                                        
                                                    </div>
                                                </Col> */}
                                        </Row>
                                }



                            </div>
                        </div>
                    </>
                }
                footer={
                    props.variabletype == "outcome" ?
                        <CustomButton type="primary" text="Add" onClick={() => { props.getData(outcomevariableData); props.onClose(); }} />
                        :
                        props.variabletype == "formula" ?
                            <CustomButton type="primary" text="Add" onClick={() => { getExpoVariable(); }} />
                            :
                            <CustomButton type="primary" text="Add" onClick={() => { getExpoVariable(); }} />
                }
            />
        </>
    )
}