import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalPopup from "../../widgets/modal";
import { onAction } from "./apiController";
import { CreateVariableElements } from "./FormElements";

export default function Actions(props) {
  const [variables, setvariables] = useState<any | []>([]);
  const [actions, setactions] = useState<any | []>([]);
  const [delIndex, setdelIndex] = useState<any | null>(null);
  const [values, fields, handleChange] = useFieldItem(
    CreateVariableElements,
    {},
    {onValueChange}
  );
  const dispatch = useDispatch();
  useEffect(() => {
    let temp: any = cloneDeep(
      props.variables.filter((elem) => elem.field_type !== "fffffileUpload")
    );
    let tempActions: any = actions;
    temp.forEach((obj) => {
      obj.label = obj.field_name;
      obj.value = obj.id;
      let tempValues: any = [];

      obj.field_value?.forEach((subObj) => {
        tempValues.push({
          label: subObj,
          value: subObj,
          actions: null,
          variables: null,
        });
      });
      obj.field_value = tempValues;
      if (obj.actions && Object.keys(obj.actions).length > 0) {
        let tempActionObj: any = Object.values(obj.actions)[0];
        obj.field_value = [];
        tempActionObj.forEach((subObj) => {
          let tempVar: any = props.variables.filter(
            (varObj) => varObj.id === subObj.next_field_id
          );
          if ((tempVar && tempVar.length > 0) || subObj.action === "end") {
            tempVar = tempVar[0];
            if (subObj.action !== "end") {
              tempVar.label = tempVar.field_name;
              tempVar.value = tempVar.id;
              
            }
            obj.field_value.push({
              label: subObj.option,
              value: subObj.option,
              variables: subObj.action === "end" ? null : tempVar,
              actions: {
                label: subObj.action_type_label,
                value: subObj.action_type_value,
              },
              actionsvariables:{
                label:  subObj.action == "end" ? "End" : "Skip" ,   
                value:  subObj.action == "end" ? "end" : "skip_to"  ,
              }
            });
          }
        });
        tempActions.push(obj);
        obj.isHidden = true;
      }
    });
    setactions(tempActions);
    setvariables(temp);
  }, []);

  function onValueChange(name, value, values) {
    return [
      {
        ...values,
        [name]: value,
      },
    ];
  }

  /**
   * Create action panel on click of Add on Variables list
   * @param value 
   * @param index 
   */
  function onAddVariable(value, index) {
    let temp: any = actions;
    temp.push(value);
    setactions(temp);
    let tempVariables = cloneDeep(variables);
    tempVariables[index].isHidden = true;
    setvariables(tempVariables);
  }

  
  /**
   * Function update the edited values of actions
   * and set the update to State
   * @param type 
   * @param value 
   * @param index 
   * @param subIndex 
   */
  function changeVariableData(type, value, index, subIndex) {
    if (index >= 0) {
      if (type === "label") {
        let temp: any = cloneDeep(actions);
        temp[index].field_value[subIndex][type] = value.target.value || "";

        setactions(temp);
      } else if (type === "actions") {
        let temp: any = cloneDeep(actions);
        temp[index].field_value[subIndex][type] = value;  
    
        setactions(temp);
      } else if (type === "actionsvariables") {        
        let temp: any = cloneDeep(actions);
        temp[index].field_value[subIndex][type] = value;  
        setactions(temp);      
      } else {
        let temp: any = cloneDeep(actions);
        temp[index].field_value[subIndex][type] = value;

        setactions(temp);
      }

    }
    handleChange(type, value, values);
  }

  /**
   * on delete action remove item from the Array 
   * Make a api trigger to update the data on server
   */
  async function onDelete() {
    let tempActions: any = cloneDeep(actions);
    let tempVariables: any = cloneDeep(variables);
    let ind: any = tempVariables.findIndex(
      (obj) => obj.id === tempActions[delIndex].id
    );
    tempVariables[ind].isHidden = false;
    //tempActions[delIndex].isDelete = true;
    let reqObj = {
      form_id: props.formId,
      actions: [{
        "field_id":tempActions[delIndex].id,
        "options":[]
      }],
    };

    let response = await onAction(reqObj);
    tempActions.splice(delIndex, 1);
    setvariables(tempVariables);
    setactions(tempActions);
    setdelIndex(null);
  }

  /**
   * onclick Add create empty action with index and id
   * @param index 
   */
  function onAddAction(index) {
    let temp: any = cloneDeep(actions);
    temp[index].field_value = temp[index].field_value || [];
    temp[index].field_value.push({
      label: "",
      actions: [],
      variables: [],
    });
    setactions(temp);
  }

  /**
   * Onsave get all the actions list 
   * and trigger to action api
   */
  const onSave = async () => {
    try {
      dispatch(setLoading(true));
      let actionsData: any = [];
      actions.forEach((obj) => {
        
        let actionOptions: any = [];
        obj.field_value.forEach((subObj) => {
          if (
            obj.field_type !== "dropDown" &&
            obj.field_type !== "checkBox" &&
            obj.field_type !== "radioButton" &&
            subObj.actions?.value &&
            (subObj.variables?.id || subObj.actionsvariables?.value === "end")
          ) {
            actionOptions.push({
              value:
                subObj.actions?.value === "greater_than" ||
                subObj.actions?.value === "greater_than_equals_to" ||
                subObj.actions?.value === "less_than" ||
                subObj.actions?.value === "less_than_equals_to" ||
                subObj.actions?.value === "equals_to"
                  ? `x ${subObj?.actions?.label} ${subObj.label}`
                  : subObj.label,
              value_type:
                subObj.actions?.value &&
                (subObj.variables?.id || subObj.actionsvariables?.value === "end")
                  ? subObj.actions?.value === "greater_than" ||
                    subObj.actions?.value === "greater_than_equals_to" ||
                    subObj.actions?.value === "less_than" ||
                    subObj.actions?.value === "less_than_equals_to" ||
                    subObj.actions?.value === "equals_to"
                    ? "expression"
                    : "string"
                  : "",
              action: subObj.actionsvariables?.value === "end" ? "end" : "skip_to",
              next_field_id:
                subObj.actionsvariables?.value === "end"
                  ? ""
                  : subObj.actionsvariables?.value && subObj.variables?.id
                  ? subObj.variables?.id
                  : "",
              action_type_label: subObj.actions?.label,
              action_type_value: subObj.actions?.value,
              option: subObj.label,
            });
          } else if (
            obj.field_type === "dropDown" ||
            obj.field_type === "checkBox" ||
            obj.field_type === "radioButton"
          ) {
            actionOptions.push({
              value:
                subObj.actions?.value === "greater_than" ||
                subObj.actions?.value === "greater_than_equals_to" ||
                subObj.actions?.value === "less_than" ||
                subObj.actions?.value === "less_than_equals_to" ||
                subObj.actions?.value === "equals_to"
                  ? `x ${subObj?.actions?.label} ${subObj.label}`
                  : subObj.label,
              value_type:
                subObj.actions?.value &&
                (subObj.variables?.id || subObj.actionsvariables?.value === "end")
                  ? subObj.actions?.value === "greater_than" ||
                    subObj.actions?.value === "greater_than_equals_to" ||
                    subObj.actions?.value === "less_than" ||
                    subObj.actions?.value === "less_than_equals_to" ||
                    subObj.actions?.value === "equals_to"
                    ? "expression"
                    : "string"
                  : "string",
              action: subObj.actionsvariables?.value === "end" ? "end" : "skip_to",
              next_field_id:
                subObj.actionsvariables?.value === "end"
                  ? ""
                  : subObj.actionsvariables?.value && subObj.variables?.id
                  ? subObj.variables?.id
                  : "",
              action_type_label: subObj.actions?.label,
              action_type_value: subObj.actions?.value,
              option: subObj.label,
            });
          }
        });
        if (actionOptions.length > 0) {
          actionsData.push({
            field_id: obj.id,
            options: actionOptions,
          });
        }
   
      });
      
      let reqObj = {
        form_id: props.formId,
        actions: actionsData,
      };
      let response = await onAction(reqObj);
      if (response.success) {
        showToaster(toasterTypes.SUCCESS, response.message);
        props.onClose("save");
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="actions-page">
      {delIndex != null && (
        <ModalPopup
          modalType="delete"
          modalText="Are you sure, you want to delete?"
          actionText="This action cannot be undone"
          closeModal={() => {
            setdelIndex(null);
          }}
          onAction={() => onDelete()}
        />
      )}
      <Row className="m-0 align-items-center actions-header">
        <Col className="p-0">
          <div className="actions-text">Actions</div>
        </Col>
        <Col className="p-0 row m-0 justify-content-end">
          <CustomButton
            type="alert-primary"
            onClick={() => onSave()}
            className="w-100"
            text="Save"
          ></CustomButton>
          <i className="icon icon-close" onClick={() => props.onClose()} />
        </Col>
      </Row>
      <div className="actions-body">
        <div className="action-container">
          <div className="action-width">
            <Row className="m-0 action-field-container">
              <Col className="p-0" md={10}>
                <div className="action-field">Variables</div>
              </Col>
            </Row>
            {variables.length === actions.length ? (
              <div className="variables-div">
                <div className="no-text">
                  All variables are added for actions
                </div>
              </div>
            ) : (
              <div className="variables-scroll">
                {variables.filter(item => !item.field_use_type.split(",").includes("Identifier") && !item.field_use_type.split(",").includes("Parent Identifier") && !item.field_use_type.split(",").includes("Authenticator") ).map(
                  (obj, index) =>
                    !obj.isHidden && !obj?.field_user_access && (
                      <Row key={index} className="m-0 variable-container">
                        <Col className="p-0 variable-text" md={9}>
                          {obj.field_name}
                        </Col>
                        <Col className="p-0 text-right" md={3}>
                          <div
                            className="add-text"
                            onClick={() => onAddVariable(obj, index)}
                          >
                            Add
                          </div>
                        </Col>
                      </Row>
                    )
                )}
              </div>
            )}
          </div>
        </div>
        <div className="actions-scroll">
          {actions.map((obj, index) => (
            <div key={index} className="action-container">
              <div className="action-width">
                <Row className="m-0 action-field-container">
                  <Col className="p-0" md={10}>
                    <div className="action-field">{obj.field_name}</div>
                  </Col>
                  <Col className="p-0 text-right">
                    <i
                      className="icon icon-delete"
                      onClick={() => setdelIndex(index)}
                    />
                  </Col>
                </Row>
                <div className="options-scroll">
                  {obj.field_type !== "dropDown" &&
                  obj.field_type !== "checkBox" &&
                  obj.field_type !== "radioButton" ? (
                    <div
                      className="add-action text-right pt-2 pr-2"
                      onClick={() => onAddAction(index)}
                    >
                      Add
                    </div>
                  ) : null}

                  
                  {  obj.field_value.map((subObj, subIndex) => (                    
                    <div className="option-container" key={subIndex}>
                     {
                      obj.field_type !== "dropDown" &&
                      obj.field_type !== "checkBox" &&
                      obj.field_type !== "radioButton" ? (
                       <FieldItem
                        {...CreateVariableElements.actions}
                        value={subObj.actions || null}
                        onChange={(...e) =>
                          changeVariableData(
                            CreateVariableElements.actions.name,
                            ...e,
                            index,
                            subIndex
                          )
                        }
                        menuPlacement={
                          obj.field_value.length !== 1 &&
                          ((subIndex !== 0 && obj.field_value.length > 1) ||
                            subIndex + 1 === obj.field_value.length)
                            ? "top"
                            : "bottom"
                        }
                      />
                      ):""}
                      
                      {
                      obj.field_type !== "dropDown" &&
                      obj.field_type !== "checkBox" &&
                      obj.field_type !== "radioButton" ? (
                        <input
                          type="text"
                          placeholder="Value Here"
                          autoFocus={true}
                          value={subObj.label}
                          onChange={(...e) =>
                            changeVariableData("label", ...e, index, subIndex)
                          }
                        />
                      ) : (
                        <div className="option-text">{subObj.label}</div>
                      )}

                    <FieldItem
                        {...CreateVariableElements.actionsvariables}
                        value={subObj.actionsvariables || null}
                        onChange={(...e) =>
                          changeVariableData(
                            CreateVariableElements.actionsvariables.name,
                            ...e,
                            index,
                            subIndex
                          )
                        }
                        menuPlacement={
                          obj.field_value.length !== 1 &&
                          ((subIndex !== 0 && obj.field_value.length > 1) ||
                            subIndex + 1 === obj.field_value.length)
                            ? "top"
                            : "bottom"
                        }
                      />


                      <FieldItem
                        {...CreateVariableElements.variables}
                        value={subObj.variables || null}
                        values={variables.filter(
                          (elem) => elem.id !== obj.value
                        )}
                        isDisabled={subObj.actionsvariables?.value === "end"}
                        onChange={(...e) =>
                          changeVariableData(
                            CreateVariableElements.variables.name,
                            ...e,
                            index,
                            subIndex
                          )
                        }
                        menuPlacement={
                          obj.field_value.length !== 1 &&
                          ((subIndex !== 0 && obj.field_value.length > 1) ||
                            subIndex + 1 === obj.field_value.length)
                            ? "top"
                            : "bottom"
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
