import { useEffect, useState } from "react";
import ModalComponent from "../Modal/modal";
import HistogramChart from "../charts/HistogramChart";
import { Col, Row } from "reactstrap";
import BoxPlot from "../charts/BoxPlotChart";
import QQChart from "../charts/QQChart";
import StemAndLeafChart from "../charts/SteamAndLeafChart";

export default function NormalityCharts(props) {
    const { data } = props;
    const [histoData, sethistoData] = useState<any>([]);
    const [chartArray, setchartArray] = useState<any>([]);

    useEffect(() => {
        console.log(data)
        data && Object.keys(data.rawdata).map(item => {
            let Obj: any = [];
            Object.keys(data.rawdata[item]).map(items => {
                Obj.push(parseInt(data.rawdata[item][items].toFixed(3)))
            })
            sethistoData(prevState => [...prevState, { [item]: Obj }])
        })

        data && Object.keys(data.rawdata).map(item => {
            let Obj: any = [];
            Object.keys(data.rawdata[item]).map(items => {
                Obj.push(parseFloat(data.rawdata[item][items].toFixed(3)))
            })

            // const expandedData = Obj.reduce((acc, value, index) => {
            //     for (let i = 0; i < value; i++) {
            //         acc.push(index);
            //     }
            //     return acc;
            // }, [])

            setchartArray(prevState => [...prevState, { [item]: Obj.sort() }])
        })



    }, [])

    useEffect(() => {
        console.log(chartArray)
    }, [histoData, chartArray])

    return (
        <>
            <ModalComponent
                width="98%"
                isOpen={true}
                onClose={() => props.onClose()}
                header={`Graph Plots to check Normality for ${data?.colvariablenames[0]}`}
                centered={true}
                customClass='invite-modal variable-modal'
                body={
                    <>
                        <Row>
                            {
                                histoData && histoData.map((item,i) => {
                                    //console.log(item[Object.keys(item)[0]])
                                    return <Col md={6}>
                                        <HistogramChart containerid={"Histogram"+i} data={item[Object.keys(item)[0]]} title={Object.keys(item)[0]} width={500} height={300} />
                                    </Col>
                                })
                            }
                            {
                                histoData && histoData.map((item,i) => {
                                    //console.log(item[Object.keys(item)[0]])
                                    return <Col md={6}>
                                        <BoxPlot
                                            containerid={"Boxplot" + i}
                                            data={[item[Object.keys(item)[0]]]}
                                            label={[Object.keys(item)[0]]}
                                            width={400}
                                            height={300}
                                            chartstyle={{ color: "lightblue", hover: "#ccc", dotradius: 5, linecolor: "#ccc" }}
                                            chartdatastyle={{ fontsize: "12px", fontweight: "500", color: "black", visible: "visible" }}
                                            chartdots={{ color: "#000", radius: 0 }}
                                            gridcolor={"#ccc"}
                                            gridstatus={"visible"}
                                            yaxistext={chartArray.yaxis}
                                            yaxislabelstyle={{ fontsize: "14px", fontweight: "400", color: "#000" }}
                                            yaxisstyle={{ fontsize: "12px", fontweight: "600", color: "#000", dy: "-2em" }}
                                            xaxistext={chartArray.xaxis}
                                            xaxislabelstyle={{ fontsize: "12px", fontweight: "500", color: "#000", rotate: "-45" }}
                                            xaxisstyle={{ fontsize: "14px", fontweight: "500", color: "#000", dy: "0em" }}
                                            title={" "}
                                            titlecolor={{ fontsize: "24px", fontweight: "600", color: "green" }} />
                                    </Col>
                                })
                            }

                            {
                                chartArray && chartArray.map((item,i) => {
                                    //console.log(item[Object.keys(item)[0]])
                                    return <Col md={6}>
                                        <QQChart containerid={"QQplot"+i} data={item[Object.keys(item)[0]].sort(function (a, b) { return a - b })} width={400} height={300} />
                                    </Col>
                                })
                            }

                            {
                                histoData && histoData.map((item,i) => {
                                    //console.log(item[Object.keys(item)[0]])
                                    return <Col md={6}>
                                        <StemAndLeafChart containerid={"Stem"+i} data={item[Object.keys(item)[0]]} width={400} height={300} />
                                    </Col>
                                })
                            }


                        </Row>
                    </>
                }
                footer={
                    <></>
                }
            />
        </>
    )
}