import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import { copyData } from "../../utils/Utils";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalPopup from "../../widgets/modal";
import CustomTable from "../../widgets/table";
import Tabs from "../sampleSize/Tabs";
import {
  deletePlan,
  getPlanData,
  getPlans
} from "./apiController";
import CreateForm from "./CreatePlan";
import {
  ArrowLeftOutlined,
  BackwardOutlined,
  CopyOutlined,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import ModalComponent from "../common/Modal/modal";
import ViewGanttChart from "./ViewGanttChart";

export default function Planner(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projectData, setprojectData] = useState<any | null>(null);
  const [userData, setuserData] = useState<any | null>(null);
  const [selectedForm, setselectedForm] = useState<any | null>(null);
  const [forms, setforms] = useState<any | null>([]);
  const [plans, setplans] = useState<any | null>([]);
  const [planData, setplanData] = useState<any | null>(null);
  const [action, setaction] = useState<any | "">("");
  const [lockData, SetLockData] = useState<any>({});
  const [formData, setformData] = useState<any | null>(null);
  const [formCount, SetFormCount] = useState(0);
  const [permissionParams, SetPermissionParams] = useState<any>({});
  const [formTemplates, setFormTemplates] = useState<any>([]);
  const [isRearranged, setIsRearranged] = useState(false);
  /**
   * tabs items
   */
  const tabData = [
    {
      title: "Project Details",
      id: 1,
      tab: "project details",
    },
    {
      title: "Study Design",
      id: 2,
      tab: "study design",
    },
    {
      title: "Data Collection",
      id: 3,
      tab: "Data collection",
    },
    {
      title: "Data Analysis",
      id: 4,
      tab: "Data analysis",
    },
    {
      title: "People",
      id: 5,
      tab: "people",
    },
    {
      title: "Planner",
      id: 6,
      tab: "planner",
    },
    {
      title: "Registered Events",
      id: 7,
      tab: "register events",
    },
    // {
    //   title: "Mentors",
    //   id: 8,
    //   tab: "mentors",
    // },
  ];

  /**
   * form elements
   */
  const FormElements = {
    lockmode: {
      name: "lockmode",
      placeholder: "Please enter remarks",
      label: "Remarks to Unlock",
      type: FIELD_TYPES.TEXT_AREA,
      isMandatory: true,
    },
    formTemplate: {
      name: "formTemplate",
      placeholder: "Select",
      label: "Select Template",
      type: FIELD_TYPES.DROP_DOWN,
      isMandatory: false,
    },
    formName: {
      name: "formName",
      placeholder: "Form Name",
      label: "New Form Name",
      type: FIELD_TYPES.TEXT,
      isMandatory: true,
    },
    formTypes: {
      name: "formType",
      placeholder: "Form Type",
      label: "",
      type: FIELD_TYPES.RADIO_BUTTON,
      values: [
        { label: "Create custom planner", value: "createform" },
        { label: "Create planner using template", value: "createtemplate" },
      ],
      isMandatory: true,
    },
  };

  const [values, fields, handleChange, { validateValues, updateValue }] =
    useFieldItem(FormElements, {}, { onValueChange });

  /**
   * handle on change values of form elements
   * @param name
   * @param value
   * @param values
   * @param fieldValues
   * @returns
   */
  function onValueChange(name, value, values, fieldValues = null) {
    const resetValue = {};
    if (name == "formType") {
      setaction(value);
    }
    return [
      {
        ...values,
        ...resetValue,
        [name]: value,
      },
    ];
  }

  /**
   * table header
   */
  const columns = [
    {
      id: "plan_name",
      label: "Plan Name",
      accessor: "plan_name",
      sort: "asc",
    },
    {
      id: "total_tasks",
      label: "Total Tasks",
      className: "text-center",
      width: '50',
      Cell: ({ row, data }) => {
        const { original } = row;
        return <>
          <span>{original.total_tasks ? original.total_tasks : "0"}</span>
        </>
      },
    },
    {
      id: "status",
      label: "Status",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return <>
          <span>In Progress</span>
        </>
      },
    },
    {
      id: "start_date",
      label: "Start date",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        const isoDate = original.plan_start_date;
        const date = new Date(isoDate);
        const dateString = date.toLocaleDateString();
        return <>
          <span>{dateString}</span>
        </>;
      },
    },
    {
      id: "end_date",
      label: "End date",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        const isoDate = original.plan_end_date;
        const date = new Date(isoDate);
        const dateString = date.toLocaleDateString();
        return <>
          <span>{dateString}</span>
        </>;
      },
    },
    {
      id: "progress",
      label: "Progress",
      width: "20",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return <i
            className="icon icon-eye"
            onClick={() => onAction(5, original)}
          ></i>
      },
    },
    {
      id: "view",
      label: "View",
      width: "20",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return <i
          className="icon icon-eye"
          onClick={() => onAction(1, original)}
        ></i>
      },
    },
    {
      id: "edit",
      label: "Edit",
      width: "20",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return <i
          className="icon icon-edit"
          onClick={() => onAction(2, original)}
        ></i>
      },
    },
    {
      id: "delete",
      label: "Delete",
      width: "30",
      className: "text-center",
      Cell: ({ row, data }) => {
        const { original } = row;
        return <i
          className="icon icon-delete"
          onClick={() => onAction(3, original)}
        ></i>
      },
    }
  ];

  useEffect(() => {
    console.log(location.state);
    if (location.state) {
      let tempState: any = location.state;
      setprojectData(tempState);
      let temp: any = localStorage.getItem("coguide_user");
      console.log(temp);
      if (temp) {
        temp = JSON.parse(temp);
        setuserData(temp);
        console.log(temp)
        fetchPlans(tempState.id);
      }
    } else {
      navigate("/project");
    }
  }, [location.state, navigate]);

  const fetchPlans = async (id = "") => {
    try {
      dispatch(setLoading(true));
      let tempPlans = await getPlans(id || projectData.id);
      setplans(tempPlans || []);
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  }


  /**
   * actions for button clicks
   * @param type
   * @param data
   */
  const onAction = async (type, data: any = null) => {
    console.log(type, data);
    switch (type) {
      case 1:
        try {
          dispatch(setLoading(true));
          let response = await getPlanData(data?.id);
          console.log(response);
          setplanData(response?.data);
          dispatch(setLoading(false));
          setaction(type);
        } catch (e: any) {
          dispatch(setLoading(false));
        }
        break;
      case 2:
        try {
          dispatch(setLoading(true));
          let response = await getPlanData(data?.id);
          console.log(response);
          setplanData(response?.data);
          dispatch(setLoading(false));
          setaction(type);
        } catch (e: any) {
          dispatch(setLoading(false));
        }
        break;
      case 3:
        setplanData(data);
        setaction(type);
        break;
      case 5:
        try {
          dispatch(setLoading(true));
          let response = await getPlanData(data?.id);
          console.log(response);
          setplanData(response?.data);
          dispatch(setLoading(false));
          setaction(type);
        } catch (e: any) {
          dispatch(setLoading(false));
        }
        break;
      default:
        setaction(type);
        break;
    }
  };

  /**
   * on From crete get all the forms list
   */
  const onFormCreation = () => {
    setaction("");
    setplans([]);
    fetchPlans(projectData.id);
  };

  //delete action
  const onDelete = async () => {
    // console.log("delete",formData);
    try {
      dispatch(setLoading(true));
      let response = await deletePlan(planData?.id);
      setaction("");
      fetchPlans();
      showToaster(toasterTypes.SUCCESS, response.message);
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  };

  //onclose
  const onCloseView = (type = "") => {
    if (type === "forms") {
      onFormCreation();
    }
    setaction("");
  };


  return (
    <div className="planner">
      {(action == 5) && (
        <ViewGanttChart 
        {...props}
        formData={planData}
        userData={userData}
        projectData={projectData}
        onClose={() => setaction("")}
        />
      )
      }
      {(action == "createform" || action === 2) && (
        <CreateForm
          {...props}
          formData={planData}
          isEdit={action === 2}
          projectData={projectData}
          onClose={() => setaction("")}
          onFormCreation={() => onFormCreation()}
        />
      )}
      {(action == "createtemplate") && (
        <CreateForm
          {...props}
          formData={planData}
          isEdit={false}
          isTemplate = {true}
          projectData={projectData}
          onClose={() => setaction("")}
          onFormCreation={() => onFormCreation()}
        />
      )}
      {(action === 1) && (
        <CreateForm
          {...props}
          formData={planData}
          isEdit={action === 1}
          isView={action === 1}
          projectData={projectData}
          onClose={() => setaction("")}
          onFormCreation={() => onFormCreation()}
        />
      )}
      {action === 4 && (
        <ModalComponent
          width={"350px"}
          isOpen={true}
          onClose={() => setaction("")}
          header={"Create Plan"}
          centered={true}
          customClass="plan-template-modal"
          body={
            <>
              <div className="plan-template">
                <FieldItem
                  {...FormElements.formTypes}
                  value={values.formType}
                  onChange={(...e) =>
                    handleChange(FormElements.formTypes.name, ...e)
                  }
                  touched={fields.formTypes && fields.formTypes.hasError}
                  error={fields.formTypes && fields.formTypes.errorMsg}
                />
              </div>
            </>
          }
          footer={<></>}
        />
      )}
      {action === 3 && (
        <ModalPopup
          modalType="delete"
          modalText="Are you sure, you want to delete?"
          actionText="This action cannot be undone"
          closeModal={() => {
            setaction("");
            setplanData(null);
          }}
          onAction={() => onDelete()}
        />
      )}
      {action !== 5 &&
        <>
          <Row className='page-header m-0'>
            <Col className={window.innerWidth > 750 ? "project-title" : "project-title-mob"} md={12}>
              <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => window.innerWidth > 750 ? navigate("/project") : navigate("/projectmobile")} />
              {projectData?.TopicDesc || projectData?.topicdesc}
            </Col>
            <Col className='p-0'></Col>
          </Row>
          <Tabs data={tabData} selectedTab={5} projectData={projectData} />

          <Row className="m-0 planner-page-height">
            <Col
              className="p-0 col-md-9"
              style={{
                minWidth: "100%",
              }}
            >
              <div className="p-3 d-flex justify-content-between ">
                <span className="font-weight-bold">Planner</span>
              </div>
              <div className="p-3">
                {plans.length > 0 ? (
                  <CustomTable
                    header="Plans"
                    data={plans}
                    tableProps={columns}
                    isSearch={false}
                    isFilter={false}
                    isSelect={false}
                    isSort={false}
                    isView={false}
                    isDraggable={true}
                    isRearranged={isRearranged}
                    isCreate={true}
                    onAction={(type, data = null) => onAction(type, data)}
                  ></CustomTable>
                ) : (
                  <div className="group-center">
                    <div>
                      No Plans
                    </div>
                    {plans.length === 0 && (
                      <Row>
                        <CustomButton
                          className="mt-2"
                          type="alert-primary"
                          text="Create Plan"
                          onClick={() => onAction(4)}
                        ></CustomButton>
                      </Row>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </>
      }
    </div>
  );
}
